angular.module("UI8.components").component("orderItem", {
  template: `<div class="purchases__item">
    <a class="purchases__preview" href="/product/{{$ctrl.item.product.slug}}">
      <img ng-src="{{ $ctrl.item.product.card_image }}" alt="{{ $ctrl.item.product.name }}">
    </a>
    <div class="purchases__inner">
      <div class="purchases__head">
        <a class="purchases__title" href="/product/{{$ctrl.item.product.slug}}">{{ $ctrl.item.product.name }}</a>
        <div class="purchases__price" ng-if="$ctrl.item.tendered !== $ctrl.item.product.price">
          <div class="purchases__full">{{ $ctrl.item.product.price | ui8Currency }}</div>
          {{ $ctrl.item.tendered | ui8Currency }}
        </div>
        <div class="purchases__price" ng-if="$ctrl.item.tendered === $ctrl.item.product.price">
          {{ $ctrl.item.product.price | ui8Currency }}
        </div>
      </div>
      <div class="purchases__description">{{ $ctrl.item.license | ui8Capitalize }} license</div>
      <div class="purchases__actions">
        <button class="btn btn--stroke btn--icon-only" ng-if="$ctrl.item.product.type === \'item\'" ng-click="$ctrl.toggleDownloadModal()">
          <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"></path></svg>
        </button>
        <a class="btn btn--stroke btn--icon-only" ng-href="/account/invoice/{{ $ctrl.item.order }}" target="_blank">
          <svg><path fill-rule="evenodd" d="M15.241 1H8.759l-2.011.044c-.562.046-1.079.144-1.564.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.289 3 5.954 3 6.759v10.483l.044 2.011c.046.562.144 1.079.392 1.564a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C7.289 23 7.954 23 8.759 23h6.483l2.01-.044c.562-.046 1.079-.145 1.564-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.011V6.759l-.044-2.011c-.046-.562-.145-1.079-.392-1.564a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C16.711 1 16.046 1 15.241 1zM8 6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z"/></svg>
        </a>
      </div>
    </div>
  </div>
  <download-modal product="$ctrl.item.product" display="$ctrl.showDownloadModal"></download-modal>`,

  controller: function () {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.showDownloadModal = false;
    };

    ctrl.toggleDownloadModal = function () {
      ctrl.showDownloadModal = !ctrl.showDownloadModal;
    };
  },

  bindings: {
    item: "<",
    user: "<",
  },
});
