angular.module("UI8.components").component("listUser", {
  template: `<div class="user">
      <div class="user__cell">
        <a class="user__avatar" href="/users/{{::$ctrl.profile.display_name_slug || $ctrl.profile._id}}" ui8-avatar="::$ctrl.profile"></a>
      </div>
      <div class="user__cell">
        <a class="user__title" href="/users/{{::$ctrl.profile.display_name_slug || $ctrl.profile._id}}">{{::$ctrl.profile.display_name || ($ctrl.profile.first_name + " " + $ctrl.profile.last_name)}}</a>
        <div class="user__content" ng-if="$ctrl.profile.biography">{{ $ctrl.profile.biography || "" }}</div>
      </div>
      <div class="user__cell">
        <div class="user__title">{{ ::$ctrl.profile.followers_sum }}</div>
        <div class="user__content">Followers</div>
      </div>
      <div class="user__cell">
        <div class="user__title">{{ ::$ctrl.profile.following_sum }}</div>
        <div class="user__content">Following</div>
      </div>
      <div class="user__cell">
        <button class="btn" ng-if="::(($ctrl.user && ($ctrl.user._id !== $ctrl.profile._id)))" ui8-follow-button="{{$ctrl.profile._id}}" ui8-positive-class="btn--stroke">Follow</button>
        <a class="btn" ng-if="::!$ctrl.user" href="/account/signup">Follow</a>
      </div>
    </div>
  `,

  bindings: {
    profile: "<",
    user: "<",
  },
});
