/**
 * File/Image Uploader
 * @author Matt Hoiland <matt@creativeda.sh>
 *
 * Usage:
 *
 * div(ng-model="upload",
 *     ui8-upload,
 *     ui8-upload-max-size="1000",
 *     accept="image/*")
 * p {{ upload[0].progress }}
 * p {{ upload[0].processing }}
 * p {{ upload[0].completed }}
 * p {{ upload[0].url }}
 *
 */

angular.module("UI8.directives").directive("ui8Upload", [
  "Upload",
  "$http",
  "$compile",
  function (Upload, $http, $compile) {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        var videoPattern = /\.mp4$/i;
        var maxSize = attrs.ui8UploadMaxSize || 10000;

        /**
         * Append ngUpload directive to elem
         */
        elem.attr("ngf-select", "");
        elem.attr("ngf-change", "uploadSelected($files, $event)");
        elem.attr("nfg-max-size", maxSize); // not a typo, really nfg
        elem.removeAttr("ui8-upload");
        elem.removeAttr("data-ui8-upload");
        $compile(elem)(scope);

        /**
         * Fired when files selected
         */
        scope.uploadSelected = function ($files) {
          uploadImages($files);
        };

        function s3Policy(options, callback) {
          options._csrf = $('input[name="_csrf"]').val();
          $http
            .post("/api/policy/s3", options)
            .then(function (res) {
              callback(res.data);
            })
            .catch(function (err) {
              callback(err);
            });
        }

        /**
         * Upload File(s) to Cloudinary
         * @param  {Array}  files   FileReader (from ng-file-upload)
         * @return {Array}          Files on the angular model
         */
        function uploadImages(files) {
          if (!files.length) return;

          files.forEach(function (file, index) {
            files[index].processing = true;
            files[index].progress = 1;
            files[index].error = false;

            var opts = { file: files[index].name };
            opts.image = !videoPattern.test(files[index].name);
            opts.video = videoPattern.test(files[index].name);
            s3Policy(opts, function (policy) {
              if (!policy || Object.keys(policy).length === 0) {
                console.log("Policy error");
                files[index].error = true;
                return;
              }

              var headers = {
                "Content-Type": "multipart/form-data",
                Authorization: "",
              };

              if (opts.csrf) headers["X-CSRF-Token"] = opts.csrf;

              var upload = Upload.upload({
                url: policy.bucket_url,
                method: "POST",
                headers: headers,
                fields: {
                  key: policy.folder + policy.filename,
                  AWSAccessKeyId: policy.s3key,
                  acl: policy.acl,
                  policy: policy.policy,
                  signature: policy.signature,
                  "Content-Type": policy.mimetype,
                },
                file: files[index],
              });

              upload.then(
                function () {
                  files[index].processing = false;
                  // Send back image url
                  files[index].url =
                    "https://images.ui8.net/" + policy.folder + policy.filename;
                  files[index].completed = true;

                  // Fire complete function (if present)
                  if (attrs.ui8UploadComplete) {
                    var executable = scope;
                    attrs.ui8UploadComplete.split(".").forEach(function (a) {
                      executable = executable[a];
                    });
                    executable();
                  }
                },
                function () {
                  files[index].processing = false;
                  files[index].error = true;
                },
                function (e) {
                  // Update progress on model
                  var percent = parseInt((100.0 * e.loaded) / e.total);
                  if (percent < 100) {
                    // Show uploading
                    files[index].progress = percent;
                  } else {
                    files[index].progress = 100;
                  }
                }
              );
            });
          });
        }
      },
    };
  },
]);
