angular.module("UI8.services").service("AccountService", [
  "$http",
  function ($http) {
    this.settings = function () {
      return $http.get("/api/account/settings").then(
        function successCallback(res) {
          return res.data;
        },
        function errorCallback() {
          return null;
        }
      );
    };

    this.listCards = function (callback) {
      return $http.get("/api/account/cards").then(
        function successCallback(res) {
          if (callback) return callback(null, res.data);
          return res.data;
        },
        function errorCallback() {
          if (callback)
            return callback("Unable to retrieve payment cards", null);
          return new Error("Unable to retrieve payment cards");
        }
      );
    };
  },
]);
