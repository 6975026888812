angular.module("UI8.services").service("ProductService", [
  "$http",
  function ($http) {
    this.homeDigest = function () {
      return $http.get("/api/home").then(
        function (response) {
          if (response.error) {
            return Promise.resolve({});
          }

          return Promise.resolve(response.data);
        },
        function () {
          return Promise.resolve({});
        }
      );
    };

    this.passPage = function () {
      return $http.get("/api/pass").then(
        function (response) {
          // console.log("passPage - response:");
          // console.log(response);
          if (response.error) {
            return Promise.resolve({});
          }

          // console.log(`pass page returned:`);
          // console.log(response.data);

          return Promise.resolve(response.data);
        },
        function () {
          return Promise.resolve({});
        }
      );
    };

    this.list = function (query) {
      return $http.get("/api/products", { params: query }).then(
        function (response) {
          if (response.error) {
            return Promise.resolve({});
          }

          return Promise.resolve(response.data);
        },
        function () {
          return Promise.resolve({});
        }
      );
    };
  },
]);
