angular.module("UI8.directives").directive("ui8Select", [
  function () {
    return {
      require: "ngModel",
      restrict: "A",
      link: function (scope, elem, attrs, ngModel) {
        var option = elem.find(".filters__btn");
        var selected = elem.find(".active");

        scope.$on("$destroy", function () {
          option.off("click");
        });

        option.on("click", function (e) {
          setActive($(e.currentTarget));
        });

        if (selected) setActive(selected);

        function setActive($this) {
          var value = $this.attr("value") || $this.text();

          $this.addClass("active");
          $this.siblings().removeClass("active");

          // Apply the change to the view
          ngModel.$setViewValue(value);
        }
      },
    };
  },
]);
