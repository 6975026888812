angular.module("UI8.services").service("WhoLikesService", [
  "$timeout",
  function ($timeout) {
    var open, users, change;

    return {
      open: function () {
        return open;
      },
      users: function () {
        return users;
      },
      change: function () {
        return change;
      },
      toggle: function (o) {
        open = o || !open;
        change = +new Date();
        $timeout(function () {}, 2);
      },
      setUsers: function (u) {
        users = u;
        change = +new Date();
        $timeout(function () {}, 2);
      },
    };
  },
]);
