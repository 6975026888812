angular.module("UI8.controllers").controller("FlashController", [
  "$scope",
  "$timeout",
  "FlashService",
  function ($scope, $timeout, FlashService) {
    window.FS = FlashService;

    // Locals
    $scope.clear = clear;
    $scope.initialize = init;
    $scope.type = $scope.message = "";

    // Listen for messages
    $scope.$watch(
      FlashService.get,
      function (msg) {
        $scope.type = msg.type;
        $scope.message = msg.message;
        $scope.showFlash = msg.showFlash;
      },
      true
    );

    /**
     * Initialize flash message
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {object} options {type: String, message: String}
     * @return {undefined}
     */
    function init(json) {
      var flash = JSON.parse(json);
      var type = flash["error"] ? "error" : "message";
      var message = flash["error"] ? flash["error"] : flash["message"];
      var output = angular.element("<span>" + message + "</span>").html();

      if (!message) return;

      // Assign message to view
      $timeout(function () {
        FlashService.set(type, output);
      }, 500);
    }

    /**
     * Clear the  current message
     * @author Matt Goucher <matt@mattgoucher.com>
     * @return {undefined}
     */
    function clear() {
      $scope.showFlash = false;
    }
  },
]);
