angular.module("UI8.services").service("DiscussionService", [
  "$http",
  function ($http) {
    this.create = function (discussionId, msg) {
      return $http.post("/discussion/" + discussionId, { message: msg });
    };

    this.update = function (discussionId, commentId, msg) {
      return $http.post(
        "/discussion/" + discussionId + "/" + commentId + "/update",
        { message: msg }
      );
    };

    this.listComments = function (discussionId, query) {
      return $http.get("/discussion/" + discussionId, { params: query });
    };

    this.delete = function (discussionId, commentId) {
      return $http.delete("/discussion/" + discussionId + "/" + commentId);
    };

    this.adminDelete = function (commentId) {
      return $http.delete("/admin/comments/" + commentId);
    };

    this.toggleLike = function (action, discussionId, commentId) {
      return $http[action](
        "/discussion/" + discussionId + "/" + commentId + "/likes"
      );
    };

    this.whoLikesThis = function (discussionId, commentId) {
      return $http.get("/discussion/" + discussionId + "/" + commentId).then(
        function (response) {
          return Promise.resolve(response.likes);
        },
        function (error) {
          return Promise.resolve({ error: error });
        }
      );
    };
  },
]);
