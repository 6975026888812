angular.module("UI8.directives").directive("ui8InjectCsrf", [
  function () {
    function link(scope, elem) {
      var token = elem.attr("value"),
        input = angular.element(
          '<input type="hidden" name="_csrf" value="' + token + '">'
        );

      angular.element("form").append(input);
    }

    return {
      link: link,
      restrict: "A",
    };
  },
]);
