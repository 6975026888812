angular.module("UI8.services").service("PaymentService", [
  "$http",
  function ($http) {
    function submitStripeForm(err, formData, callback) {
      if (err) return callback(err, null);

      var submission = {
        email: formData.email,
        name: formData.name,
        password: formData.password,
        save_card: formData.saveCard,
        stripeType: formData.stripeType,
        stripeSource: formData.stripeSource,
        stripeToken: formData.stripeToken,
        rcToken: formData.rcToken,
        idempotencyKey: formData.idempotencyKey,
      };

      return $http.post("/api/checkout/process/stripe", submission).then(
        function successCallback(res) {
          callback(null, res.data);
        },
        function errorCallback() {
          callback("Unable to process payment", null);
        }
      );
    }

    this.stripe = function (formData, callback) {
      if (!formData.stripeToken && !formData.stripeSource) {
        var card = {
          name: formData.name,
          number: formData.card_number,
          exp_month: formData.exp_month,
          exp_year: formData.exp_year,
          cvc: formData.cvc,
        };
        return Stripe.card.createToken(card, function (status, res) {
          if (status !== 200 || res.error || !res) {
            if (window.bugsnagClient)
              window.bugsnagClient.notify(
                res.error || "Stripe createToken failure"
              );
            return submitStripeForm(
              "Unable to process Stripe payment",
              null,
              callback
            );
          }

          formData.stripeToken = res.id;
          return submitStripeForm(null, formData, callback);
        });
      }

      submitStripeForm(null, formData, callback);
    };

    this.paypal = function (formData, callback) {
      var submission = {
        email: formData.email,
        name: formData.name,
        password: formData.password,
        rcToken: formData.rcToken,
      };

      return $http.post("/api/checkout/process/paypal", submission).then(
        function successCallback(res) {
          callback(null, res.data);
        },
        function errorCallback(e) {
          console.log(e);
          callback("Unable to process payment", null);
        }
      );
    };

    this.stripeCardRemove = function (customer, card) {
      return $http.post("/account/payment/remove", { customer, card });
    };
  },
]);
