angular.module("UI8.services").service("FlashService", [
  "$timeout",
  "$rootScope",
  function ($timeout, $rootScope) {
    var delay = 4000;
    var type = "";
    var showFlash = false;
    var message = "";

    return {
      get: function () {
        return {
          type: type,
          message: message,
          showFlash: showFlash,
        };
      },
      set: function (newType, newMessage) {
        return $timeout(function () {
          type = newType;
          message = newMessage;
          $rootScope.flashMessage = message;
          showFlash = newMessage ? true : false;

          // Auto-close
          return $timeout(function () {
            showFlash = false;
          }, delay);
        }, 1);
      },
    };
  },
]);
