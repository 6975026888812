angular.module("UI8.components").component("productFilter", {
  template:
    '<div class="filters__select" on-load="$ctrl.changeHandler()">' +
    "  <div class='filters__head'>" +
    "    <div class='filters__arrow'>" +
    '      <svg><path d="M7.414 10c-.891 0-1.337-1.077-.707-1.707l4.586-4.586a1 1 0 0 1 1.414 0l4.586 4.586c.63.63.184 1.707-.707 1.707H7.414zm8.882 5c.928 0 1.356 1.155.651 1.759l-4.297 3.683a1 1 0 0 1-1.302 0l-4.297-3.683C6.347 16.155 6.775 15 7.703 15h8.593z"/></svg>' +
    "    </div>" +
    "  </div>" +
    "  <div class='filters__dropdown'>" +
    '    <button class="filters__btn" ng-class="{ \'active\': $ctrl.sort === \'recent\' }" value="recent" ng-click="$ctrl.setActive($event)">Release date</button>' +
    '    <button class="filters__btn" ng-class="{ \'active\': $ctrl.sort === \'popular\' }" value="popular" ng-click="$ctrl.setActive($event)">Popularity</button>' +
    '    <button class="filters__btn" ng-if="$ctrl.priceFilter" ng-class="{ \'active\': $ctrl.sort === \'high\' }" value="high" ng-click="$ctrl.setActive($event)">Highest price</button>' +
    '    <button class="filters__btn" ng-if="$ctrl.priceFilter" ng-class="{ \'active\': $ctrl.sort === \'low\' }" value="low" ng-click="$ctrl.setActive($event)">Lowest price</button>' +
    '    <featured-filter on-update="$ctrl.applyFeatured(value)"></featured-filter>' +
    "  </div>" +
    "</div>",

  controller: function () {
    var ctrl = this;
    var slug;

    ctrl.changeHandler = function () {
      slug = location.pathname.split("/").pop();
      ctrl.sort = sessionStorage.getItem(slug + ":category-sort");
      if (!ctrl.sort) ctrl.sort = "recent";
    };

    // Set Active Selection
    ctrl.setActive = function ($event) {
      var $clicked = $($event.target);
      var value = $clicked.attr("value") || $clicked.text();
      ctrl.sort = value;
      sessionStorage.setItem(slug + ":category-sort", ctrl.sort);
      ctrl.updateSort({ value: value });

      // Scroll top after updating filters
      var header = $(".header"),
        trigger = $(".page__trigger"),
        filters = $(".page__filters--sticky");

      if (filters.hasClass("scrolled"))
        window.scroll(0, trigger.offset().top - header.outerHeight());
    };

    ctrl.applyFeatured = function (val) {
      return ctrl.updateFeatured({ value: val });
    };
  },

  bindings: {
    priceFilter: "<?",
    updateSort: "&",
    updateFeatured: "&",
    hasFeatured: "<",
  },
});
