/* global ga: false */
/**
 * Track analytics event
 * @author Matt Goucher <matt@mattgoucher.com>
 * Usage: ui8-track-event="Category,Action,Label,Value"
 * Usage: ui8-track-event="Category,Action,,Value" (ignore label)
 */
angular.module("UI8.directives").directive("ui8TrackEvent", [
  "$window",
  function ($window) {
    return {
      restrict: "A",
      link: function (scope, elem) {
        scope.$on("$destroy", function () {
          elem.off("click touchstart");
        });

        elem.on("click touchstart", function () {
          if (!$window.ga) return;

          return ga.apply(
            $window,
            ["send", "event"].concat(
              $(this)
                .attr("ui8-track-event")
                .split(",")
                .map(function (el) {
                  return el || undefined;
                })
            )
          );
        });
      },
    };
  },
]);
