angular.module("UI8.controllers").controller("ProductController", [
  "$http",
  "$scope",
  "$window",
  "$timeout",
  "$sce",
  "$document",
  "LikeService",
  "CartService",
  "FlashService",
  function (
    $http,
    $scope,
    $window,
    $timeout,
    $sce,
    $document,
    LikeService,
    CartService,
    FlashService
  ) {
    var pushLink;
    // Initialize fixed stuff
    $scope.getFreebie = getFreebie;

    $scope.like = LikeService.toggleLike;
    $scope.isLiked = LikeService.isLiked;
    $scope.getCountModifier = LikeService.getCountModifier;

    $scope.browsable = false;
    $scope.browseStart = true;
    $scope.browseEnd = false;

    $scope.isMp4 = function (i) {
      return /\.mp4$/i.test(i.url);
    };

    $scope.addToCart = function (e) {
      // $("body").removeClass("product-select-modal");
      return CartService.addToCart(e).then(function () {
        setTimeout(function () {
          window.scrollLock.disablePageScroll();

          var groupCart = $(".header__cell--cart"),
            groupNext = groupCart.find(".payup__sidebar .payup__btn"),
            groupLayout = groupCart.find(".payup__layout"),
            groupBack = groupCart.find(".payup__back");

          groupNext.on("click", function () {
            groupLayout.addClass("step-checkout");
          });

          groupBack.on("click", function () {
            groupLayout.removeClass("step-checkout");
          });
        }, 10);
      });
    };

    $scope.formData = {};

    $scope.showDownloadModal = false;

    // Initialize dynamic content
    $scope.init = function (data, user, pass) {
      // google tracking
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          value: data.product.price / 100,
          currency: "USD",
          items: [
            {
              item_name: data.product.name,
              price: data.product.price / 100,
            },
          ],
        },
      });

      if (user) $scope.user = user;
      if (user && pass) $scope.user.pass_holder = pass;

      $scope.product = data.product;
      $scope.secondaryProduct = data.secondaryProduct;
      $scope.author = data.author;
      $scope.products = data.products;
      $scope.previews = data.previews;
      $scope.user_owns = data.user_owns;
      $scope.valid_pass = data.valid_pass;
      $scope.videoEmbed = $sce.trustAsHtml(unescape(data.videoEmbed));

      var composedSlug = "";
      if ($scope.author && $scope.author.display_name_slug) {
        composedSlug =
          $scope.author.display_name_slug + "/products/" + $scope.product.slug;
      } else {
        composedSlug = "/products/" + $scope.product.slug;
      }

      if (
        !$scope.browsable &&
        ((data.browse && data.browse.length) ||
          (data.products && data.products.length))
      ) {
        $scope.browsable =
          data.browse ||
          data.products
            .map(function (p) {
              if (!p || !p.slug || p.slug === "undefined") return null;
              if (p.author && p.author.display_name_slug) {
                return p.author.display_name_slug + "/products/" + p.slug;
              } else {
                return "products/" + p.slug;
              }
            })
            .filter(function (p) {
              return !!p;
            });
        currentProductIndex = $scope.browsable.indexOf(composedSlug);
        if (currentProductIndex === -1) {
          $scope.browsable.unshift(composedSlug);
          currentProductIndex = 0;
          $scope.browseStart = true;
        } else if (currentProductIndex !== 0) {
          $scope.browseStart = false;

          if (currentProductIndex === $scope.browsable.length - 1) {
            $scope.browseEnd = true;
          }
        }
      }

      var title = data.subtitle ? data.subtitle : "";
      title += data.title ? (data.subtitle ? " — " : "") + data.title : "";
      title += " on UI8";

      document.title = title;

      if (window.location.search.length) {
        popHistory(composedSlug);
      }

      if (window.ga) window.ga("author.remove");

      if (window.ga && data.author_ga_code) {
        window.ga("create", data.author_ga_code, "auto", { name: "author" });
        window.ga("author.send", "pageview");
      }

      $scope.isVideo = data.product.type === "video";
      $scope.hasCategory =
        data.product.category && data.product.category.length;
      $scope.hasCompatibility =
        data.product.compatibility_string &&
        data.product.compatibility_string.length;
      $scope.hasDetailImages =
        data.product.detail_images && data.product.detail_images.length;
      $scope.hasFullPreview =
        data.product.full_preview_images &&
        data.product.full_preview_images.length;
      $scope.hasFeatures =
        data.product.features && data.product.features.length;
      $scope.hasRelated = data.products && data.products.length < 4;
      $scope.freeFilesData = {
        name: data.product.name + " (demo)",
        card_image: data.product.card_image,
        slug: data.product.slug,
        files: data.product.free_files,
        _id: data.product._id,
      };
      $scope.freebieData = {
        name: data.product.name,
        card_image: data.product.card_image,
        slug: data.product.slug,
        files: data.product.rel ? data.product.files : data.product.free_files,
        _id: data.product._id,
      };

      $scope.heroBackground = getHeroBackground(data.product);
      $scope.vimeoEmbed = getVimeoEmbed(
        data.product.video_source || data.product.video_intro
      );
      $scope.formattedDescription = $sce.trustAsHtml(
        data.product.description
          .replace(/\n/g, "<br/>")
          .replace(
            /\[([0-9A-Za-z ]+)\]\(([0-9a-z\.\-\/\:]+)\)/gi,
            "<a target='_blank' href='$2'>$1</a>"
          )
      );

      if (data.author) {
        $scope.authorAvatar = data.author.avatar_url;
        $scope.authorName = getAuthorName(data.author);
        $scope.authorId = data.author.display_name_slug || data.author._id;
      }

      window.UI8 = window.UI8 || {};
      UI8.product = data.product;
      if (data.author) UI8.author = data.author;

      $(".product-full-preview").on("click", clickHandler);
      $document.on("keydown", keydownHandler);
      $document.on("show-modal", showModalHandler);

      $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
      };

      // sticky nav
      const $header = $(".header"),
        $nav = $(".pnav"),
        $window = $(window);

      $window.scroll(function () {
        const scrollTop = $window.scrollTop();

        if (
          Math.round(scrollTop + $header.outerHeight()) >=
          Math.round($nav.offset().top)
        ) {
          $nav.addClass("scrolled");
        } else {
          $nav.removeClass("scrolled");
        }

        // hide intercom button
        if ($window.width() < 1024) {
          var discussion = $(".discussion"),
            intercomContainer = $(".intercom-lightweight-app");

          if (discussion && intercomContainer) {
            var discussionTop = discussion.offset().top,
              winHeight = $window.height();

            if (scrollTop + winHeight > discussionTop) {
              intercomContainer.fadeOut();
            } else {
              intercomContainer.fadeIn();
            }
          }
        }
      });
    };

    $scope.toggleDownloadModal = function () {
      if (!$scope.product && window.UI8) {
        $scope.product = window.UI8.product || null;
        $scope.secondaryProduct = window.UI8.secondaryProduct || null;
        $scope.userOwns = window.UI8.user_owns || false;
        $scope.userOwnsSecondary = window.UI8.user_owns_secondary || false;
      }

      $scope.dlProduct =
        $scope.userOwnsSecondary || $scope.valid_pass
          ? $scope.secondaryProduct
          : $scope.product;

      $scope.showDownloadModal = !$scope.showDownloadModal;
    };

    // Set scroll watcher for embedded videos
    $timeout(function () {
      var $win = $(window);
      var videos = $("video");

      if (!videos.length) return;

      var playPromises = new Array(videos.length);

      function videoToggle() {
        $.each(videos, function (i, v) {
          if ($(v).isInViewport()) {
            if (videos[i] && videos[i].paused) {
              if (!videos[i].ended) {
                try {
                  playPromises[i] = videos[i].play();
                  playPromises[i].catch(function () {});
                } catch (e) {
                  // No action
                }
              }
            }
          } else {
            if (playPromises[i] !== undefined) {
              playPromises[i]
                .then(function () {
                  videos[i].pause();
                })
                .catch(function () {});
            }
          }
        });
      }

      $win.scroll(videoToggle);

      videoToggle();
    }, 50);

    function containerClick(e) {
      if ($(e.target).hasClass("product-full-preview")) {
        $("body").removeClass("show-modal");
      }
    }

    function showModalHandler(e, v) {
      if (v) {
        $(".product-full-preview").on("click", containerClick);
      } else {
        $(".product-full-preview").off();
      }
      $scope.full_preview = v;
      $scope.$apply();
    }

    $scope.$on("$destroy", function () {
      $document.on("keydown", keydownHandler);
      $document.off("show-modal", showModalHandler);
      $(".product-full-preview").on("click", clickHandler);
    });

    $scope.scrollTo = function (target) {
      $("html, body").animate(
        { scrollTop: $("[name='" + target + "']").offset().top - 156 },
        1000
      );
    };

    $scope.toggleNewsletter = function () {
      $scope.newsletter = !$scope.newsletter;
    };

    $scope.toggleVideo = function () {
      $scope.showVideo = !$scope.showVideo;
    };

    /**
     * Request a freebie download link
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {object} e    Javascript event
     * @param  {object} form Angular Form
     * @return {undefined}
     */
    function getFreebie(e, form) {
      e.preventDefault();

      form = form || {};

      // Require Valid Form
      if (form.$invalid) {
        return;
      }

      // We're sending it bro
      form.sending = true;

      // Delays, for daysz
      return $timeout(function () {
        return $http
          .post($window.location.pathname + "/freebie", {
            email: $scope.email,
          })
          .then(function () {
            form.sent = true;
            form.errored = false;
            form.$setPristine();
            $timeout(function () {
              $scope.newsletter = false;
            }, 500);
          })
          .catch(function () {
            form.errored = true;

            $timeout(function () {
              form.sending = false;
              form.$setPristine();
            }, 350);
          });
      }, 300);
    }

    $scope.subscribeNewsletter = function () {
      $scope.pending = true;

      return $http
        .post("/api/newsletter", { email: $scope.email })
        .then(function () {
          $scope.success = true;
          $scope.error = false;
          $scope.pending = false;
        })
        .catch(function (response) {
          if (response.code === 214 || response.error.match(/already/i)) {
            $scope.success = true;
            $scope.error = false;
            $scope.pending = false;
            $scope.exists = true;
          } else {
            $scope.error = true;
            $scope.success = false;
            $scope.pending = false;
          }
        });
    };

    $scope.clearError = function () {
      if ($scope.error) {
        $scope.error = false;
      }
    };

    $scope.confirmPush = function (event) {
      event.preventDefault();
      pushLink = event.currentTarget.getAttribute("href");
      $("body").addClass("push-modal");
    };

    $scope.sendPush = function () {
      $("body").removeClass("push-modal");
      window.location = window.location.origin + pushLink;
    };

    function getHeroBackground(product) {
      var out;

      if (product.hide_hero) return false;

      if (product.hero_image) {
        out = product.hero_image;
      } else if (product.detail_images.length) {
        out = product.detail_images[0].url;
      } else {
        out = product.card_image;
      }

      return "background-image: url(" + out + ");";
    }

    function getVimeoEmbed(source) {
      var url;
      if (source && typeof source === "string")
        url = source.match(/https\:\/\/vimeo\.com\/[A-z0-9]+/);

      // Invalid Video Url
      if (!url) return "";

      // Use player domain
      url = (url[0] || "").replace(
        "://vimeo.com/",
        "://player.vimeo.com/video/"
      );

      return (
        '<iframe src="' +
        url +
        '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>'
      );
    }

    function getAuthorName(author) {
      if (author.display_name) return author.display_name;

      if (author.first_name) {
        var authorName = author.firstName;

        if (author.last_name) {
          authorName += " " + author.last_name;
        }

        return authorName;
      }

      return null;
    }

    /**
     * Product browsing
     */

    var host = window.location.origin;
    var currentProductIndex = 0;
    var productDataLoading = false;

    function handleResponseError(error) {
      console.error(error);
      FlashService.set("error", "Unable to navigate with keyboard controls.");
    }

    function updateProductData(response, slug, navForward) {
      $scope.init(response.data);
      $scope.$apply();

      if (navForward) {
        pushHistory(slug);
      } else {
        popHistory(slug);
      }
    }

    function pushHistory(slug) {
      var _query = window.location.search;
      var params = new URLSearchParams(_query);
      params.delete("browse");
      params = params.toString();
      var queryString = params.length ? "?" + params : "";
      history.pushState(null, "", host + "/" + slug + queryString);
    }

    function popHistory(slug) {
      var _query = window.location.search;
      var params = new URLSearchParams(_query);
      params.delete("browse");
      params = params.toString();
      var queryString = params.length ? "?" + params : "";
      history.replaceState(null, "", host + "/" + slug + queryString);
    }

    function loadProduct(productId, navForward) {
      if (typeof productId === "undefined" || productId === "undefined") return;

      productDataLoading = true;

      $.ajax(host + "/api/" + productId)
        .done(function (res) {
          if (res.error) return handleResponseError(res.error);
          updateProductData(res, productId, navForward);
          productDataLoading = false;
        })
        .fail(function (_, status, error) {
          productDataLoading = false;
          return handleResponseError(error);
        });
    }

    function previousProduct() {
      if (productDataLoading) return;
      if (currentProductIndex <= 0) return;

      if (currentProductIndex === 1) {
        $scope.browseStart = true;
      } else {
        $scope.browseStart = false;
      }

      $scope.browseEnd = false;

      return loadProduct($scope.browsable[--currentProductIndex]);
    }

    function nextProduct() {
      if (productDataLoading) return;
      if (currentProductIndex >= $scope.browsable.length - 1) return;

      if (currentProductIndex === $scope.browsable.length - 2) {
        $scope.browseEnd = true;
      } else {
        $scope.browseEnd = false;
      }

      $scope.browseStart = false;

      return loadProduct($scope.browsable[++currentProductIndex], true);
    }

    function keydownHandler(e) {
      if (e.key === "Escape" || e.keyCode === 27) {
        $("body").removeClass("full-preview-modal");
        $("body").removeClass("full-preview-modal");
        $("body").trigger("show-video", [false]);
        $("body").trigger("show-video", [false]);
        return;
      }

      let ignore = false;

      switch (e.target.tagName.toLowerCase()) {
        case "input":
        case "textarea":
        case "select":
        case "button":
          ignore = true; // Ignore cursor left/right while we are in text editor or on button/dropdown
          return;
      }

      if (!ignore && e.which === 37) {
        // cursor left
        previousProduct();
      } else if (!ignore && e.which === 39) {
        // cursor right
        nextProduct();
      }
    }

    function clickHandler(e) {
      if ($(e.target).hasClass("product-full-preview")) {
        $("body").removeClass("show-modal");
      }
    }

    $scope.previousProduct = previousProduct;
    $scope.nextProduct = nextProduct;
  },
]);
