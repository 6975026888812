angular.module("UI8.components").component("exclusiveFilter", {
  template:
    "<section class='exclusive-filter' on-load='$ctrl.changeHandler()'>" +
    " <span ng-class='{ active: $ctrl.exclusive }'>Exclusives</span>" +
    " <div class='checkbox-toggle' ng-click='$ctrl.toggleExclusive()' ng-class='{ active: $ctrl.exclusive }'>" +
    "   <div class='toggle-switch'></div>" +
    " </div>" +
    "</section>",

  controller: function () {
    var ctrl = this;
    var slug;

    ctrl.changeHandler = function () {
      slug = location.pathname.split("/").pop();
      var storedFilter = sessionStorage.getItem(
        slug + ":category-exclusive-filter"
      );
      setExclusive(storedFilter === "true");

      document.body.addEventListener("toggle-featured", toggleListener);
      document.body.addEventListener("reset-category-filters", resetListener);
    };

    ctrl.$onDestroy = function () {
      document.body.removeEventListener("toggle-featured", toggleListener);
      document.body.removeEventListener(
        "reset-category-filters",
        resetListener
      );
    };

    ctrl.toggleExclusive = function () {
      ctrl.exclusive = !ctrl.exclusive;
      applyUpdate();
    };

    function setExclusive(exclusive) {
      ctrl.exclusive = exclusive;
      applyUpdate();
    }

    function applyUpdate() {
      var event = new CustomEvent("toggle-exclusive", {
        detail: ctrl.exclusive,
      });
      document.body.dispatchEvent(event);
      sessionStorage.setItem(
        slug + ":category-exclusive-filter",
        ctrl.exclusive
      );
      ctrl.onUpdate({ value: ctrl.exclusive });
    }

    function toggleListener(e) {
      if (e.detail && ctrl.exclusive) ctrl.toggleExclusive();
    }

    function resetListener() {
      if (ctrl.exclusive) ctrl.toggleExclusive();
    }
  },

  bindings: {
    onUpdate: "&",
  },
});
