angular.module("UI8.services").service("CartService", [
  "$rootScope",
  "$http",
  "FlashService",
  function ($rootScope, $http, FlashService) {
    /**
     * Get the cart
     * @author Bill Broughton <bill.broughton@toptal.com>
     * @return undefined
     */
    this.getCart = function (callback) {
      $http.get("/api/cart").then(
        function (res) {
          // Success callback
          return callback(null, res.data.cart);
        },
        function () {
          // Error callback
          FlashService.set("error", "Unable to retrieve cart");
        }
      );
    };

    /**
     * Add product to cart, now with AJAX
     * @author Bill Broughton <bill.broughton@toptal.com>
     * @return undefined
     */
    this.addToCart = function ($event, callback) {
      var _id = $event
        ? $($event.currentTarget).find("input[name='product']").val() ||
          $($event.currentTarget).data("id") ||
          angular.element("#product_id").val() ||
          $event.currentTarget.dataset.id
        : angular.element("#product_id").val() ||
          $event.currentTarget.dataset.id;

      if (!_id) return FlashService.set("error", "Unable to add item to cart");

      return $http.post("/api/cart/add", { product: _id }).then(
        function successCallback(res) {
          if (res.data.error) console.log(res.data.error);
          $rootScope.cart = res.data.cart;

          // google tracking
          window.dataLayer.push({
            event: "add_to_cart",
            ecommerce: {
              value: $rootScope.cart.items[0].subtotal / 100,
              currency: "USD",
              items: [
                {
                  item_name: $rootScope.cart.items[0].product.name,
                  price: $rootScope.cart.items[0].subtotal / 100,
                },
              ],
            },
          });

          if (callback) return callback(null, res.data.cart);
          return res.data.cart;
        },
        function errorCallback() {
          FlashService.set("error", "Unable to add item to cart");
          if (callback) return callback("Unable to add item to cart", null);
        }
      );
    };

    this.addPassToCart = function (
      id,
      seats,
      callback,
      addSeats,
      originalOrder
    ) {
      if (!id) return FlashService.set("error", "Unable to add item to cart");

      if (!seats) seats = 1;

      var body = { product: id, seats: seats };
      if (addSeats) {
        body.addSeats = true;
        body.originalOrder = originalOrder;
      }

      return $http.post("/api/cart/add", body).then(
        function successCallback(res) {
          if (res.data.error) console.log(res.data.error);
          $rootScope.cart = res.data.cart;
          if (callback) return callback(null, res.data.cart);
          return res.data.cart;
        },
        function errorCallback() {
          FlashService.set("error", "Unable to add item to cart");
          if (callback) return callback("Unable to add item to cart", null);
        }
      );
    };

    this.passUpsellToCart = function (product, keepProducts, callback) {
      return $http
        .post("/api/cart/add", {
          product: product ? product : "5accb700555e9baf1531d411",
          seats: 1,
          upsell: true,
          keepProducts: !!keepProducts,
        })
        .then(
          function successCallback(res) {
            if (res.data.error) console.log(res.data.error);
            $rootScope.cart = res.data.cart;
            if (callback) return callback(null, res.data.cart);
            return res.data.cart;
          },
          function errorCallback() {
            FlashService.set("error", "Unable to add item to cart");
            if (callback) return callback("Unable to add item to cart", null);
          }
        );
    };

    this.removeFromCart = function (itemId, callback) {
      $http.post("/api/cart/rem", { item: itemId }).then(
        function successCallback(res) {
          if (!callback) return res.data;

          return callback(null, res.data);
        },
        function errorCallback() {
          FlashService.set("error", "Unable to remove item from cart");
        }
      );
    };
  },
]);
