angular.module("UI8.services").service("UserService", [
  "$http",
  function ($http) {
    this.show = function (identifier) {
      return $http.get("/api/users/" + identifier + "?json=1").then(
        function (response) {
          return response.data;
        },
        function () {
          return null;
        }
      );
    };

    this.sendHireMessage = function (identifier, data) {
      return $http.post("/api/users/" + identifier + "/hire", data).then(
        function (response) {
          return response.data;
        },
        function () {
          return null;
        }
      );
    };

    this.followers = function (identifier, page) {
      var toPage = page || 1;
      return $http
        .get("/api/users/" + identifier + "/followers?count=40&page=" + toPage)
        .then(
          function (response) {
            return response.data;
          },
          function () {
            return null;
          }
        );
    };

    this.following = function (identifier, page) {
      var toPage = page || 1;
      return $http
        .get("/api/users/" + identifier + "/following?count=40&page=" + toPage)
        .then(
          function (response) {
            return response.data;
          },
          function () {
            return null;
          }
        );
    };

    this.favorites = function (identifier, page) {
      var toPage = page || 1;
      return $http
        .get("/api/users/" + identifier + "/favorites/page/" + toPage)
        .then(
          function (response) {
            return response.data.products;
          },
          function () {
            return null;
          }
        );
    };

    this.userExists = function (email, callback) {
      if (!email) return callback(null, false);

      $http.get(`/api/users/${email}/exists`).then(
        function successCallback(res) {
          if (!callback) return !!res.data.userExists;
          return callback(null, !!res.data.userExists);
        },
        function errorCallback() {
          if (!callback) return false;
          return callback(null, false);
        }
      );
    };
  },
]);
