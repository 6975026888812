angular.module("UI8.controllers").controller("DiscussionController", [
  "$scope",
  "DiscussionService",
  function ($scope, DiscussionService) {
    var submitting = false;
    var discussionComponent = angular.element(
      document.querySelector("#discussion")
    );

    // Local Scope
    $scope.editing = false;
    $scope.submit = submit;
    $scope.prev = prev;
    $scope.next = next;
    $scope.whoLikesThis = whoLikesThis;
    $scope.comments = [];
    $scope.initialize = init;
    $scope.delete = deleteComment;
    $scope.toggleLike = toggleLike;
    $scope.userLikesComment = userLikesComment;
    $scope.paginationDisabled = paginationDisabled;
    $scope.getHandle = getFullName;
    $scope.sort = "newest";
    $scope.disabled = true;
    $scope.loading = false;
    $scope.loadingMore = false;
    $scope.messageEditing = "";

    // Get likers of a comment
    $scope.getLikers = function (comment) {
      var likes = (comment.likes || []).filter(function (l) {
        return !l.hide;
      });

      return {
        list: likes.slice(0, 4),
        more: likes.length - 4 > 0 ? likes.length - 4 : false,
      };
    };

    $scope.setEditing = function (index, value) {
      if (value) {
        $scope.messageEditing = value;
      }
      $scope.error = false;
      $scope.editing = isNaN(index) ? false : index;
    };

    $scope.saveEdit = function (updated) {
      var index = angular.copy($scope.editing),
        comment = $scope.comments[index];

      $scope.error = false;

      DiscussionService.update(
        $scope.$parent.product.discussion._id,
        comment._id,
        replaceUserEntities(updated)
      )
        .then(function (response) {
          response = response.data;
          $scope.editing = false;
          $scope.comments[index].message = updated;
          $scope.comments[index].message = response.message;
        })
        .catch(function (response) {
          $scope.error = response.error || "Unable to save comment.";
        });
    };

    // Cache the current discussion
    $scope.discussion = {};

    // Watch for l33t hackers injecting HTML
    $scope.$watch("message", function (n) {
      $scope.error = false;
      $scope.htmlerror = !!String(n).match(/(<([^>]+)>)/gi);
      if ((n || "").length > 500) {
        $scope.error =
          "Your comment is " + (n.length - 500) + " characters too many.";
      }
    });

    // Watch for changes to the sort key
    $scope.$watch(
      function () {
        return $scope.sort;
      },
      function (n, o) {
        if (n === o) return;

        $scope.paginating = false;
        $scope.disabled = false;

        $scope.page = 1;
        return getCommentsPage();
      }
    );

    // Watch for new product/discussion
    $scope.$watch(
      function () {
        return $scope.$parent.product._id;
      },
      function (n, o) {
        if (n === o) return;

        $scope.page = 1;
        return getCommentsPage();
      }
    );

    function init(user) {
      $scope.user = user ? JSON.parse(user) : false;
      $scope.sort = "newest";
      const hash = window.location.hash;
      if (hash) {
        window.scrollTo(0, discussionComponent.offset().top - 156);
      }
      return getCommentsPage();
    }

    // Does a user like a comment
    function userLikesComment(c) {
      if (!$scope.user) return false;

      return (c.likes || []).filter(function (l) {
        return l._id === $scope.user._id;
      }).length;
    }

    // Toggle the like on a comment
    function toggleLike(c) {
      var liked = userLikesComment(c);

      // Ensure likes exists
      c.likes = c.likes || [];
      c.likes_sum = c.likes_sum || 0;

      DiscussionService.toggleLike(
        liked ? "delete" : "post",
        $scope.$parent.product.discussion._id,
        c._id
      )
        .then(function () {
          if (liked) {
            c.likes_sum = Math.max(c.likes_sum - 1, 0);
            c.likes = (c.likes || []).filter(function (l) {
              return l._id !== $scope.user._id;
            });
            return;
          }

          c.likes_sum = c.likes_sum + 1;
          return c.likes.push({
            _id: $scope.user._id,
            hide: true,
          });
        })
        .catch(function () {});
    }

    // Get next page of comments
    function next() {
      if (paginationDisabled("next")) return;

      return getCommentsPage($scope.page + 1, true);
    }

    // Get prev page of comments
    function prev() {
      if (paginationDisabled("prev")) return;

      return getCommentsPage($scope.page - 1);
    }

    // Determine if pagination is enabled or disabled
    function paginationDisabled(direction) {
      return direction === "next"
        ? $scope.page + 1 > $scope.pages
        : $scope.page - 1 < 1;
    }

    // Grab a page of comments
    function getCommentsPage(page, loadMore) {
      if ($scope.loading || $scope.loadingMore) return;

      if (loadMore) {
        $scope.loadingMore = true;
      } else {
        $scope.loading = true;
      }

      var seller = $scope.$parent.product.author
        ? $scope.$parent.product.author._id
        : null;

      var query = { page: page || 1, order_by: $scope.sort, count: 30 };
      if (seller) query.seller = seller;

      DiscussionService.listComments(
        $scope.$parent.product.discussion._id,
        query
      )
        .then(function (response) {
          response = response.data;
          $scope.qd = true;
          $scope.page = response.page;
          $scope.pages = response.pages;
          $scope.autocomplete = response.commenters;
          $scope.discussion = response;
          $scope.loading = false;
          $scope.loadingMore = false;
          $scope.disabled = true;

          if (!loadMore) {
            $scope.comments = response.comments;
          } else {
            $scope.comments = $scope.comments.concat(response.comments);
          }

          $scope.$parent.comments = $scope.discussion.total_comments;
        })
        .catch(function (response) {
          $scope.discussion = false;
          $scope.loading = false;
          $scope.loadingMore = false;
          $scope.disabled = true;
          console.error(response);
        });
    }

    // Return a user full name
    function getFullName(user, respectCase) {
      var name =
        user.display_name || (user.first_name || "") + " " + user.last_name;

      name = name === " " ? "Anon" : name;

      return respectCase ? name : name.toLowerCase();
    }

    // Replace usernames with [USER:<_id>]
    function replaceUserEntities(message) {
      angular.forEach($scope.autocomplete, function (el) {
        var handle = "@" + getFullName(el, true);

        if (message.split(handle).length > 1) {
          message = message.split(handle).join("[USER:" + el._id + "]");
        }
      });

      return message;
    }

    // Handle Form Submissions
    function submit(message) {
      if (submitting) return;

      submitting = true;
      $scope.submitting = true;
      $scope.accountError = false;
      $scope.error = false;
      $scope.errorLink = null;

      if (
        !$scope.user ||
        (!$scope.user.display_name &&
          !$scope.user.first_name &&
          !$scope.user.last_name)
      ) {
        submitting = false;
        $scope.$apply(function () {
          $scope.error = true;
          $scope.accountError = true;
          $scope.submitting = false;
        });
        return;
      }

      DiscussionService.create(
        $scope.$parent.product.discussion._id,
        replaceUserEntities(message)
      )
        .then(function () {
          submitting = false;
          $scope.message = "";
          $scope.submitting = false;
          $scope.discussion.total_comments =
            ($scope.discussion.total_comments || 0) + 1;
          $scope.$parent.comments = $scope.discussion.total_comments;

          window.scrollTo(0, discussionComponent.offset().top - 156);
          if ($scope.sort === "newest") {
            $scope.page = 1;
            getCommentsPage();
          } else {
            $scope.sort = "newest";
          }
        })
        .catch(function (response) {
          submitting = false;
          $scope.submitting = false;
          $scope.error = response.error || "Unable to save comment.";
        });
    }

    // Delete a comment
    function deleteComment(c, index) {
      DiscussionService.delete(
        $scope.$parent.product.discussion._id,
        c._id
      ).then(function () {
        $scope.comments.splice(index, 1);
        $scope.discussion.total_comments = Math.max(
          ($scope.discussion.total_comments || 0) - 1,
          0
        );
        $scope.$parent.comments = $scope.discussion.total_comments;
      });
    }

    $scope.adminDeleteComment = function (commentId, index) {
      DiscussionService.adminDelete(commentId).then(function () {
        $scope.comments.splice(index, 1);
        $scope.discussion.total_comments = Math.max(
          ($scope.discussion.total_comments || 0) - 1,
          0
        );
        $scope.$parent.comments = $scope.discussion.total_comments;
      });
    };

    // Who likes this discussion
    function whoLikesThis(discussion, comment) {
      DiscussionService.whoLikesThis(discussion, comment)
        .then(function (response) {
          $scope.users = response.data.likes;
        })
        .catch(function (response) {
          console.error("There was an error", response.error);
        });
    }
  },
]);
