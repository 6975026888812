angular.module("UI8.services").service("CategoryService", [
  "$http",
  function ($http) {
    this.list = function () {
      return $http.get("/api/categories").then(
        function (response) {
          if (response.error) return [];
          return response.data;
        },
        function () {
          return [];
        }
      );
    };

    this.findBySlug = function (slug, options) {
      return new Promise(function (resolve) {
        var queryString = "";
        var sessionSoftware = sessionStorage.getItem(
          slug + ":category-software-filter"
        );
        var sessionFeatured = sessionStorage.getItem(
          slug + ":category-featured-filter"
        );
        var sessionSort = sessionStorage.getItem(slug + ":category-sort");

        if (!options && (sessionSoftware || sessionFeatured || sessionSort)) {
          options = {};
        }

        if (
          !options.compatibility &&
          sessionSoftware &&
          sessionSoftware !== "Any Platform"
        )
          options.compatibility = sessionSoftware;
        if (!options.featured && sessionFeatured)
          options.featured =
            options.featured === false ? null : sessionFeatured;
        if (!options.sort && sessionSort) options.sort = sessionSort;

        if (options) {
          var params = new URLSearchParams(options).toString();
          queryString = "?" + params;
        }

        return $http.get("/api/categories/" + slug + queryString).then(
          function (response) {
            if (response.error) return resolve({});
            return resolve(response.data.data);
          },
          function () {
            return resolve({});
          }
        );
      });
    };
  },
]);
