/**
 * UI8 - Limit form inputs to numbers
 * @author Bill Broughton <bill@ui8.net>
 */
angular.module("UI8.directives").directive("ui8NumbersOnly", [
  function () {
    return {
      restrict: "A",
      link: function (scope, element) {
        element.bind("input", function () {
          var position = this.selectionStart - 1;

          //remove all but number and .
          var fixed = this.value.replace(/[^0-9]/g, "");

          if (this.value !== fixed) {
            this.value = fixed;
            this.selectionStart = position;
            this.selectionEnd = position;
          }
        });
      },
    };
  },
]);
