angular.module("UI8.components").component("categoryFilter", {
  template:
    '<div class="filters__container" ng-class=\'{ fonts: $ctrl.categoryName === "Fonts", "no-sort": !$ctrl.showSort || $ctrl.currentSale }\'>' +
    '  <tag-filter ng-if="::$ctrl.showTags" filters="$ctrl.filterTags" on-update="$ctrl.applyTagFilter(value)" category-name="::$ctrl.categoryName"></tag-filter>' +
    '  <software-filter ng-if="::$ctrl.showApps" show-apps="$ctrl.filterApps" on-update="$ctrl.applySoftwareFilter(value)" limit-apps="$ctrl.showTags"></software-filter>' +
    '  <div class="filters__sorting" ng-if="::($ctrl.showSort && !$ctrl.currentSale)">' +
    '    <product-filter update-sort="$ctrl.applySort(value)" update-featured="$ctrl.applyFeatured(value)" update-exclusive="$ctrl.applyExclusive(value)" price-filter="::$ctrl.priceFilter" has-exclusives="$ctrl.hasExclusives" has-featured="$ctrl.hasFeatured"></product-filter>' +
    "  </div>" +
    "</div>",

  controller: function () {
    var ctrl = this;

    ctrl.applyTagFilter = function (val) {
      return ctrl.updateTag({ value: val });
    };

    ctrl.applySoftwareFilter = function (val) {
      return ctrl.updateSoftware({ value: val });
    };

    ctrl.applySort = function (val) {
      return ctrl.updateSort({ value: val });
    };

    ctrl.applyFeatured = function (val) {
      return ctrl.updateFeatured({ value: val });
    };

    ctrl.applyExclusive = function (val) {
      return ctrl.updateExclusive({ value: val });
    };
  },

  bindings: {
    categoryName: "<",
    currentSale: "<",
    priceFilter: "<",
    showTags: "<",
    filterTags: "<",
    updateTag: "&",
    showApps: "<",
    filterApps: "<",
    updateSoftware: "&",
    showSort: "<",
    updateSort: "&",
    updateFeatured: "&",
    updateExclusive: "&",
    hasExclusives: "<",
    hasFeatured: "<",
  },
});
