/*
 * Only applies to All Access Pass, no other products use this template
 */
angular.module("UI8.components").component("productPage", {
  template:
    '<pass-details data="$ctrl.data" user="$ctrl.user" comments="$ctrl.comments"></pass-details>',

  controller: function ($scope) {
    var ctrl = this;
    // console.log("productPage");
    // console.log(ctrl.data);
    ctrl.$onInit = function () {
      // console.log("productPage init");
      // console.log(ctrl.data);
      // HACK: ensures promo is applied when navigating within SPA
      if (/passrenew/.test(window.location.search) && !ctrl.data.promo) {
        // console.log("logation reload");
        window.location.reload();
      }
      ctrl.path = location.pathname.replace("/", "_");
      document.body.scrollTop = 0;
    };

    $scope.$on("UPDATE_COMMENT_COUNT", function (event, comments) {
      ctrl.comments = comments;
    });

    $scope.$on("UPDATE_TAB", function (event, tab) {
      $scope.$broadcast("NEW_TAB", tab);
    });
  },

  bindings: {
    data: "<",
    user: "<",
  },
});
