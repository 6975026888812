angular.module("UI8.controllers").controller("SessionController", [
  "$scope",
  function ($scope) {
    $scope.account = {};

    var parseLocation = function (location) {
      var pairs = location.substring(1).split("&");
      var obj = {};
      var pair;
      var i;

      for (i in pairs) {
        if (pairs[i] === "") continue;

        pair = pairs[i].split("=");
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }

      return obj;
    };

    var queryEmail = parseLocation(window.location.search)["email"];
    if (queryEmail) {
      $scope.account.email = queryEmail;
    }
  },
]);
