/**
 * Dynamically serve retina/non-retina images from S3
 * @author Matt Hoiland <matt@creativeda.sh>
 */
angular.module("UI8.directives").directive("ui8ProductVideo", [
  "$timeout",
  function ($timeout) {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        $timeout(function () {
          // Wait for angular scope
          var video = attrs.ui8ProductVideo;
          var poster = attrs.ui8VideoPoster;

          function cacheValue(name, fn) {
            if (window[name] != null) {
              return window[name];
            }

            window[name] = fn();
            return window[name];
          }

          const isWebpSupported = cacheValue("isWebpSupported", () => {
            const elem = document.createElement("canvas");

            if (elem.getContext && elem.getContext("2d")) {
              // was able or not to get WebP representation
              return (
                elem.toDataURL("image/webp").indexOf("data:image/webp") == 0
              );
            }

            // old browser, canvas not supported
            return false;
          });

          const S3_IMAGE_SERVER = "https://images.ui8.net";
          const posterDetails = JSON.parse(attrs.ui8PosterDetails || "{}");
          const productId = attrs.ui8ProductId;
          const extension = isWebpSupported ? "webp" : "png";

          if (poster) {
            elem.attr("poster", poster);
          } else if (posterDetails.has_video_poster) {
            elem.attr(
              "poster",
              `${S3_IMAGE_SERVER}/product/${productId}/poster-${posterDetails._id}_${posterDetails.video_poster_version}.${extension}`
            );
          } else {
            elem.attr("poster", video.replace(/\.mp4$/, ".jpg"));
          }

          elem.append('<source src="' + video + '" type="video/mp4">');
          /* elem.append(
            '<source src="' +
              video.replace(/\.mp4$/, ".webm") +
              '" type="video/webm">'
          ); */

          elem.attr("loop", ""); // by default now
        }, 0);
      },
    };
  },
]);
