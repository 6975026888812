angular.module("UI8.components").component("newsletterSignup", {
  template: `<div class="footer__subscribe">
      <div ng-if="!$ctrl.success && !$ctrl.discount20" class="footer__title">Join our newsletter, get discounts 🔥</div>
      <div ng-if="!$ctrl.success && $ctrl.discount20" class="footer__title">Sign up for our newsletter!</div>
      <div ng-if="$ctrl.success" class="footer__title">Thanks for joining, check your inbox.</div>
      <div class="field" ng-if="!$ctrl.success">
        <div class="field__label">Email</div>
        <input class="field__input" ng-class="{ error: $ctrl.errorServer || $ctrl.errorEmail || $ctrl.errorExists }" ng-keyup="$ctrl.pressEnter($event)" type="email" name="email" ng-model="$ctrl.email" placeholder="designer@example.com">
        <button class="field__action" ng-click="$ctrl.subscribeNewsletter()" ng-if="!$ctrl.pending">
          <svg width="24" height="24"><path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm.613 4.81l.094.083 4.4 4.4c.028.028.055.059.08.09l-.08-.09a1.01 1.01 0 0 1 .097.112 1.03 1.03 0 0 1 .05.075.87.87 0 0 1 .021.037c.011.02.021.04.031.061a.96.96 0 0 1 .022.052.99.99 0 0 1 .022.06 1.07 1.07 0 0 1 .014.046c.006.022.011.043.016.065s.007.035.009.052.005.041.007.061a1.05 1.05 0 0 1 .003.055c0 .01.001.021.001.032l-.001.033c-.001.017-.002.035-.003.052L17.4 12a1.01 1.01 0 0 1-.011.149c-.003.017-.006.034-.009.052a.94.94 0 0 1-.015.065 1.11 1.11 0 0 1-.014.046.95.95 0 0 1-.021.059c-.007.018-.015.035-.023.052a1.02 1.02 0 0 1-.031.061.88.88 0 0 1-.021.036c-.016.026-.032.05-.05.074a.7.7 0 0 1-.013.018l-.083.094-4.4 4.4a1 1 0 0 1-1.497-1.32l.083-.094L13.985 13H7.6a1 1 0 0 1-.117-1.993L7.6 11h6.385l-2.692-2.693a1 1 0 0 1 1.32-1.497z"/></svg>
        </button>
        <div class="loader" ng-if="$ctrl.pending"></div>
        <div class="field__error" ng-if="$ctrl.errorServer">Server error. Please try again later.</div>
        <div class="field__error" ng-if="$ctrl.errorEmail">Invalid email address.</div>
        <div class="field__error" ng-if="$ctrl.errorExists">Email address already subscribed.</div>
      </div>
      <div class="footer__message" ng-if="$ctrl.success">Be sure to double opt-in to claim your exclusive 20% discount.</div>
    </div>`,

  controller: function ($http) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.errorServer = false;
      ctrl.errorEmail = false;
      ctrl.errorExists = false;
      ctrl.pending = false;
      ctrl.success = false;

      ctrl.discount20 = UI8.promo && UI8.promo.discount === 20;
    };

    ctrl.subscribeNewsletter = function () {
      ctrl.pending = true;

      if (!validateEmail(ctrl.email)) {
        ctrl.errorEmail = true;
        ctrl.pending = false;
        return;
      }

      ctrl.errorEmail = false;

      return $http
        .post("/api/newsletter", { email: ctrl.email })
        .then(function () {
          ctrl.errorServer = false;
          ctrl.errorEmail = false;
          ctrl.errorExists = false;
          ctrl.pending = false;
          ctrl.success = true;
        })
        .catch(function (res) {
          ctrl.pending = false;

          if (res.data.code === 214 || res.data.error.match(/already/i)) {
            ctrl.errorExists = true;
          } else {
            ctrl.errorServer = true;
          }
        });
    };

    ctrl.pressEnter = function (e) {
      if (e.keyCode === 13) {
        ctrl.subscribeNewsletter();
      }
    };

    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
});
