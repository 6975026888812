angular.module("UI8.services").service("DownloadService", [
  "$http",
  "$q",
  function ($http, $q) {
    this.get = function (product, fileIndex, dropbox) {
      var file = product.files[fileIndex];
      if (!file) return Promise.reject("Invalid file");

      var url =
        window.location.origin +
        "/account/download/" +
        product._id +
        "/" +
        file._id;

      if (window.ga)
        window.ga("send", "pageview", "/virtual/download/" + product.slug);

      if (dropbox) return transferToDropbox(url, product.name);

      return directDownload(url);
    };

    function dispatchEvent(remainingCount) {
      document.body.dispatchEvent(
        new CustomEvent("download-success", {
          detail: { downloadsRemaining: remainingCount },
        })
      );
    }

    function transferToDropbox(url, productName) {
      var DBOX = window.Dropbox;
      var deferred = $q.defer();

      if (!DBOX) {
        return deferred.reject(
          "Dropbox not available. Please try again later."
        );
      } else {
        url = url.replace("/account", "");
        $.ajax({
          url: url,
          dataType: "json",
          async: false,
          success: function (res) {
            if (res.error) {
              return deferred.reject(res.error);
            } else {
              DBOX.save(res.signedLink, productName + ".zip", {
                success: function () {
                  dispatchEvent(res.downloadsRemaining);
                  deferred.resolve("btn-dropbox-success");
                },
                progress: function () {
                  deferred.resolve("btn-dropbox-loading");
                },
                cancel: function () {
                  deferred.resolve("");
                },
                error: function () {
                  deferred.resolve("btn-dropbox-error");
                },
              });
            }
          },
          error: function (xhr, e, msg) {
            console.error(e);
            console.error(msg);
            deferred.reject(msg);
          },
        });
      }

      return deferred.promise;
    }

    function directDownload(url) {
      var deferred = $q.defer();

      $http.get(url, { async: true }).then(
        function (res) {
          if (res.data.error) {
            deferred.reject(res.data.error);
          } else {
            dispatchEvent(res.data.downloadsRemaining);
            window.location = res.data.signedLink;
            deferred.resolve();
          }
        },
        function (e) {
          console.log(e);
          deferred.reject(e.message);
        }
      );

      return deferred.promise;
    }
  },
]);
