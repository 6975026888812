angular.module("UI8.components").component("topProducts", {
  template: `<div class="page page--featured">
      <div class="page__background">
         <img src="https://images.ui8.net/redesign/hero-featured.jpg" alt="Hero" />
      </div>
      <div class="page__container container">
        <div class="page__hero">
          <h1 class="page__title">Featured products</h1>
          <div class="page__description">Recently hand-picked resources with our seal of <span class="page__featured">approval</span></div>
        </div>
        <div class="layout">
          <div class="layout__sidebar">
            <div class="layout__nav">
              <button data-section='#{{ ::category.slug }}' class='layout__link' ng-repeat='category in ::$ctrl.data track by category.slug'>{{ ::category.name }}</button>
            </div>
          </div>
          <div class="layout__inner">
            <div class='featured' ng-repeat='category in ::$ctrl.data track by category.slug'>
              <div id='{{ ::category.slug }}' class='featured__anchor'></div>
              <div class='featured__head'>
                <h2 class='featured__subtitle'>{{ ::category.name }}</h2>
                <div class='featured__group'>
                   <div class='featured__content'>{{ $ctrl.content[category.slug] }}</div>
                   <a class='featured__btn btn' ng-href='/category/{{ ::category.slug }}'>View all</a>
                </div>
              </div>
              <div class="featured__catalog catalog catalog--three">
                <product-card ng-repeat="product in category.products track by product._id" product="::product" user="::$ctrl.user"></product-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`,

  controller: function ($scope, HistoryService) {
    var ctrl = this;

    this.$onInit = function () {
      document.title = "Top UX & UI Design Resources — UI8";

      // content
      ctrl.content = {
        "ui-kits":
          "Your ultimate design time-saver. With pre-made UI elements at your fingertips, you can focus on the creative side of your design work and not spend hours on the basics.",
        "coded-templates":
          "Streamline your development process by providing a pre-existing structure that can be easily modified and reused, saving time and effort while promoting consistency, leading to more efficient development workflows.",
        illustrations:
          "The cherry on top of any boring project. Thoughtful illustrations add some much-needed personality and zing to your message, making your projects more memorable and way more fun.",
        "3d-assets":
          "Need depth and spatial awareness in your designs? Our growing library of 3D assets can convey a sense of realism and tangibility that engages and immerses users in a whole new way.",
        mockups:
          "By showcasing your product or website in a tangible form, you can effectively communicate your vision to clients or stakeholders, making it easier for them to understand and appreciate your work.",
        icons:
          "Effective user interface design relies on a consistent visual language that can convey meaning quickly and clearly. Our extensive collection of icon sets can help you nail down this critical aspect of your design process.",
        "wireframe-kits":
          "The superhero sidekick of design, that gives you the essential building blocks needed to quickly prototype and iterate, all while maintaining a consistent and professional visual aesthetic.",
        themes:
          "Stuck on a creative block? Get inspired by a wide selection of themes to help you effortlessly create polished digital experiences for a wide array of use cases, it’s like a Swiss army knife in your arsenal.",
        fonts:
          "Good design cannot exist without great typography. Our curated font collection provides a structure that saves you time and effort while promoting consistency, resulting in more efficient and cohesive flow in your designs.",
      };

      // sticky filters
      const $header = $(".header"),
        $sidebar = $(".layout__sidebar"),
        $layout = $(".layout");

      $(window).scroll(function () {
        const posScroll = $(window).scrollTop();

        if (posScroll + $header.outerHeight() >= $layout.offset().top) {
          $sidebar.addClass("scrolled");
        } else {
          $sidebar.removeClass("scrolled");
        }
      });

      const top = getTop();
      $(document).ready(function () {
        window.scroll(0, top);

        // Active class for navigation
        const page = $(".page--featured"),
          link = page.find(".layout__link"),
          anchor = page.find(".featured__anchor");

        link.eq(0).addClass("active");

        link.on("click", function (e) {
          e.preventDefault();

          const id = $(this).data("section");
          window.scroll(0, $(id).offset().top);
        });

        $(window).scroll(function () {
          const posScroll = $(window).scrollTop();

          anchor.each(function (i) {
            const posTop = $(this).offset().top,
              height = $(this).parent().outerHeight(),
              windowHeight = $(window).height();

            if (
              posScroll + windowHeight / 2 > posTop &&
              posScroll + windowHeight / 2 < posTop + height
            ) {
              link.removeClass("active").eq(i).addClass("active");
            }
          });
        });
      });

      function isGoingToProduct(oldPath, oldUrl) {
        return (
          oldPath.startsWith("/category/featured-products") &&
          !oldUrl.includes("?search=1")
        );
      }

      HistoryService.rememberScrollPosition(isGoingToProduct, "featured");
    };

    function getTop() {
      const top = sessionStorage.getItem("top:featured") || 0;
      sessionStorage.removeItem("top:featured");
      return parseInt(top, 10);
    }
  },

  bindings: {
    data: "<",
    user: "<",
  },
});
