angular.module("UI8.directives").directive("ui8GoTab", [
  function () {
    return {
      restrict: "A",
      scope: true,
      link: function (scope, elem, attrs) {
        elem.focus(function () {
          $(attrs.ui8GoTab).focus();
        });

        scope.$on("$destroy", function () {
          elem.off("focus");
        });
      },
    };
  },
]);
