angular.module("UI8.services").service("PushService", [
  function () {
    function urlBase64ToUint8Array(base64String) {
      var padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      var base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      var rawData = window.atob(base64);
      var outputArray = new Uint8Array(rawData.length);

      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    function registerServiceWorker() {
      return navigator.serviceWorker.register("/cached/push-worker.js");
    }

    function askPermission() {
      return new Promise(function (resolve, reject) {
        var permissionResult = Notification.requestPermission(function (
          result
        ) {
          resolve(result);
        });

        if (permissionResult) {
          permissionResult.then(resolve, reject);
        }
      }).then(function (permissionResult) {
        if (permissionResult !== "granted") {
          throw new Error("We weren't granted permission.");
        }
      });
    }

    function getNotificationPermissionState() {
      if (navigator.permissions) {
        return navigator.permissions
          .query({ name: "notifications" })
          .then(function (result) {
            return result.state;
          });
      }

      return new Promise(function (resolve) {
        resolve(Notification.permission);
      });
    }

    function subscribeUserToPush() {
      return navigator.serviceWorker
        .register("/cached/push-worker.js")
        .then(function (reg) {
          var subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
              "BAwk96YHLBpOTZ_b4Q3i7h8V5IIepV_9JWVKGx_BRpOkmSbHNdmLhHVESGBBhkgx3xO-7tJzX19_vOnJj4OgjWQ"
            ),
          };

          return reg.pushManager.subscribe(subscribeOptions);
        })
        .then(function (pushSubscription) {
          return pushSubscription;
        });
    }

    function sendSubscriptionToBackEnd(subscription, action) {
      action = action ? action.toLowerCase() : "subscribe";
      return fetch("/api/push-" + action, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify(subscription.toJSON()),
      });
    }

    /**
     * Set up for push framework
     * @author Bill Broughton <bill.broughton@toptal.com>
     * @return undefined
     */
    this.setUpPush = function () {
      return Promise.all([
        registerServiceWorker(),
        getNotificationPermissionState(),
      ])
        .then(function (results) {
          var registration = results[0];
          var currentPermissionState = results[1];

          if (currentPermissionState === "denied") return;

          return registration.pushManager
            .getSubscription()
            .then(function (subscription) {
              return new Promise(function (resolve) {
                resolve(subscription);
              });
            });
        })
        .catch(function (err) {
          console.error("Unable to register the service worker: " + err);
        });
    };

    this.subscribe = function () {
      return new Promise(function (res) {
        var promiseChain;
        var sub;
        getNotificationPermissionState().then(function (
          currentPermissionState
        ) {
          if (currentPermissionState !== "granted") {
            promiseChain = askPermission().then(subscribeUserToPush);
          } else {
            promiseChain = subscribeUserToPush();
          }

          return promiseChain
            .then(function (subscription) {
              sub = subscription;
              if (subscription) {
                return sendSubscriptionToBackEnd(subscription);
              } else {
                return new Promise(function (resolve) {
                  resolve(subscription);
                });
              }
            })
            .then(function (subscription) {
              res(sub || subscription);
            })
            .catch(function (err) {
              console.error("Failed to subscribe the user.", err);
              return res(null);
            });
        });
      });
    };

    this.unsubscribe = function () {
      return new Promise(function (res) {
        var subscription;
        return navigator.serviceWorker
          .register("/cached/push-worker.js")
          .then(function (reg) {
            return reg.pushManager.getSubscription();
          })
          .then(function (sub) {
            subscription = sub;
            return sub.unsubscribe();
          })
          .then(function (success) {
            if (success) {
              return sendSubscriptionToBackEnd(subscription, "unsubscribe");
            } else {
              return new Promise(function (resolve) {
                resolve(subscription);
              });
            }
          })
          .then(function () {
            res({ success: true });
          })
          .catch(function (err) {
            console.error("Failed to unsubscribe the user.", err);
            getNotificationPermissionState().then(function (permissionState) {
              res(permissionState);
            });
          });
      });
    };
  },
]);
