/**
 * Extract mentions from comment object
 * @author Matt Goucher <matt@mattgoucher.com>
 */
angular.module("UI8.filters").filter("ui8GetMentions", [
  "$sce",
  function ($sce) {
    var mentions = {};

    // Get user handle
    function getUserHandle(user) {
      if (!user) return "";

      return (
        "@" +
        (user.display_name || (user.first_name || "") + " " + user.last_name)
      );
    }

    // Generate the HTML for a mention
    function getMentionMarkup(user, handleOnly) {
      return handleOnly
        ? getUserHandle(user)
        : '<a href="/users/' +
            (user.display_name_slug || user._id) +
            '" class="mention">' +
            getUserHandle(user) +
            "</a>";
    }

    // Find a user by _id in array of users
    function findUserInArray(id, users) {
      if (users.length) {
        users.forEach(function (user) {
          if (mentions[user._id] || !user._id) return;

          mentions[user._id] = user;
        });
      }

      return mentions[id];
    }

    return function (comment, handleOnly, commenters) {
      return $sce.trustAsHtml(
        comment.message
          .replace(/\[USER\:([0-9A-z]+)\]/gi, function (match, _id) {
            return (
              getMentionMarkup(findUserInArray(_id, commenters), handleOnly) ||
              ""
            );
          })
          .substr(0, 1000)
      );
    };
  },
]);
