angular.module("UI8.services").service("SessionService", [
  "$http",
  "$rootScope",
  function ($http, $rootScope) {
    function getRandomString() {
      return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "");
    }

    this.user = function () {
      if (!UI8.loggedIn) return null;
      return $http.get("/api/me?" + getRandomString()).then(
        function (response) {
          return response.data.user;
        },
        function () {
          return null;
        }
      );
    };

    this.setCurrentUser = function () {
      if (!UI8.loggedIn) return null;
      return $http.get("/api/me?" + getRandomString()).then(
        function (response) {
          if (!response.data || !response.data.user) {
            $rootScope.user = null;
            return;
          }
          $rootScope.user = response.data.user;
        },
        function () {
          $rootScope.user = null;
        }
      );
    };

    this.purchases = function () {
      if (!UI8.loggedIn) return null;
      return $http.get("/api/purchases").then(
        function (response) {
          return response.data;
        },
        function () {
          return null;
        }
      );
    };
  },
]);
