angular.module("UI8.components").component("hireModal", {
  template: `<div ng-class="{success: $ctrl.isSubmitted}" class="modal modal--hire">
      <button class="modal__close" ui8-toggle-class="hire-modal" ui8-track-event="Close Hire Modal"></button>
      <div class="modal__container">
        <div class="hire">

          <div class="hire__step" ng-if="!$ctrl.isSubmitted">
            <div class="hire__title">Hire {{ $ctrl.authorName }}</div>
            <div class="hire__form">
              <div class="field">
                <div class="field__label">Subject</div>
                <input class="field__input tab-subject" ng-model="$ctrl.hireSubject" type="text" name="hire-subject" placeholder="ie. Need help with an app design" />
              </div>
              <div class="field">
                <div class="field__label">Project details</div>
                <textarea class="field__textarea" ng-model="$ctrl.hireMessage" name="hire-message" placeholder="Tell us what your project is about, provide as many details as possible..."></textarea>
                <input type="text" ui8-go-tab=".tab-subject" />
              </div>
              <button class="btn btn--submit" ng-if="!$ctrl.isSubmitting" ng-click="$ctrl.submitMessage()">Send inquiry</button>
              <div class="loader" ng-if="$ctrl.isSubmitting"></div>
            </div>
          </div>

          <div class="hire__step" ng-if="$ctrl.isSubmitted && !$ctrl.confirmed">
            <div class="hire__title">Check your inbox</div>
            <div class="hire__content">
              <p>Kindly confirm your inquiry by checking your inbox and completing the steps to finish delivering your message.</p>
              <p>Confirmation should arrive promptly, but in case of any delay, reach out to our support team for assistance.</p>
            </div>
            <button class="btn btn--submit" ng-click="$ctrl.resetForm()">Send new inquiry</button>
          </div>
          
          <div class="hire__step" ng-if="$ctrl.success === 1">
            <div class="hire__title">Message sent</div>
            <div class="hire__content">
              <p>Your email has been confirmed and your message has been delivered to {{ $ctrl.authorName }}.</p>
            </div>
            <button class="btn btn--submit" ng-click="$ctrl.resetForm()">Send new inquiry</button>
          </div>

          <div class="hire__step" ng-if="$ctrl.success === 0">
            <div class="hire__title">Unable to deliver message</div>
            <div class="hire__content">
              <p>We were unable to deliver your message to {{ $ctrl.authorName }}.</p>
              <p>Please contact support.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal__backdrop" ui8-toggle-class="hire-modal" ui8-track-event="Close Hire Modal"></div>
    </div>`,

  controller: function (UserService, FlashService) {
    var ctrl = this;

    ctrl.hireSubject = "";
    ctrl.hireMessage = "";
    ctrl.isSubmitting = false;
    ctrl.isSubmitted = false;

    ctrl.$onInit = function () {
      ctrl.authorName =
        ctrl.author.display_name ||
        ctrl.author.first_name + " " + ctrl.author.last_name;

      if (ctrl.success === 0 || ctrl.success === 1) {
        ctrl.isSubmitted = true;
        ctrl.confirmed = true;
      }
    };

    ctrl.submitMessage = function () {
      ctrl.isSubmitting = true;

      UserService.sendHireMessage(ctrl.author._id, {
        subject: ctrl.hireSubject,
        message: ctrl.hireMessage,
      }).then(function (response) {
        ctrl.isSubmitting = false;

        if (response.error) {
          return FlashService.set("error", "Unable to send message");
        }

        ctrl.isSubmitted = true;
      });
    };

    ctrl.resetForm = function () {
      ctrl.hireSubject = "";
      ctrl.hireMessage = "";
      ctrl.isSubmitted = false;
      ctrl.success = false;
      ctrl.confirmed = false;
    };
  },

  bindings: {
    author: "<",
    success: "<",
  },
});
