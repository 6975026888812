angular.module("UI8.components").component("featuredFilter", {
  template:
    "<button class='filters__toggle' ng-class='{ active: $ctrl.featured }' ng-click='$ctrl.toggleFeatured()' on-load='$ctrl.changeHandler()'>Featured</button>",

  controller: function () {
    var ctrl = this;
    var slug;

    ctrl.changeHandler = function () {
      slug = location.pathname.split("/").pop();
      var storedFilter = sessionStorage.getItem(
        slug + ":category-featured-filter"
      );
      setFeatured(storedFilter === "true");

      document.body.addEventListener("toggle-exclusive", toggleListener);
      document.body.addEventListener("reset-category-filters", resetListener);
    };

    ctrl.$onDestroy = function () {
      document.body.removeEventListener("toggle-exclusive", toggleListener);
      document.body.removeEventListener(
        "reset-category-filters",
        resetListener
      );
    };

    ctrl.toggleFeatured = function () {
      ctrl.featured = !ctrl.featured;
      applyUpdate();
    };

    function setFeatured(featured) {
      ctrl.featured = featured;
      applyUpdate();
    }

    function applyUpdate() {
      var event = new CustomEvent("toggle-featured", { detail: ctrl.featured });
      document.body.dispatchEvent(event);
      sessionStorage.setItem(slug + ":category-featured-filter", ctrl.featured);
      ctrl.onUpdate({ value: ctrl.featured });

      // Scroll top after updating filters
      var header = $(".header"),
        trigger = $(".page__trigger"),
        filters = $(".page__filters--sticky");

      if (filters.hasClass("scrolled"))
        window.scroll(0, trigger.offset().top - header.outerHeight());
    }

    function toggleListener(e) {
      if (e.detail && ctrl.featured) ctrl.toggleFeatured();
    }

    function resetListener() {
      if (ctrl.featured) ctrl.toggleFeatured();
    }
  },

  bindings: {
    onUpdate: "&",
  },
});
