angular.module("UI8.controllers").controller("ProductsController", [
  "$http",
  "$scope",
  "$window",
  "$timeout",
  "LikeService",
  "CartService",
  function ($http, $scope, $window, $timeout, LikeService, CartService) {
    var PRODUCTS_COUNT = 24;

    /* Included in product-card component */
    $scope.addToCart = CartService.addToCart;
    /* /include */
    $scope.loading = true;
    $scope.init = init;
    $scope.homeInit = homeInit;
    $scope.next = nextPage;
    $scope.disabled = false;
    $scope.likes = [];
    $scope.products = [];
    $scope.selectTag = selectTag;
    $scope.productGroup = {
      featured: [],
      trending: [],
      new_releases: [],
      freebies: [],
    };
    $scope.freebieProduct = {
      name: "",
    };
    $scope.getFreebie = getFreebie;
    $scope.setFreebie = setFreebie;

    /* Included in product-card component */
    // Likes
    $scope.isLiked = LikeService.isLiked;
    $scope.unLiked = LikeService.unLiked;
    $scope.likeProduct = LikeService.toggleLike;
    $scope.getCountModifier = LikeService.getCountModifier;

    $scope.cardHover = function () {
      this.hoverTarget = true;
    };

    $scope.cardLeave = function () {
      this.hoverTarget = false;
      this.signInMessage = false;
    };

    $scope.likeHover = function () {
      this.signInMessage = true;
    };
    /* /include */

    // Watch for changes to the sort key
    $scope.$watch(
      function () {
        return $scope.sort;
      },
      function (n, o) {
        if (n === o) {
          return;
        }

        $scope.paginating = false;
        return rebuild();
      }
    );

    angular.element($window).bind("beforeunload", function () {
      if (location.pathname === "/") {
        sessionStorage.setItem("homeTab", $scope.filter);
      }
      return setTop(document.body.scrollTop);
    });

    $("document").ready(function () {
      var top = getTop();
      if (top) document.body.scrollTop = parseInt(top, 10);
    });

    $scope.updateFilter = function (filter) {
      $scope.filter = filter;
      for (var id = 0; id < 24; id++) {
        if ($scope.productGroup[filter][id]) {
          $scope.products[id] = $scope.productGroup[filter][id];
        } else {
          if (id === 0) {
            $scope.products = [];
          } else {
            $scope.products = $scope.products.slice(0, id);
          }
          break;
        }
      }
    };

    function init(query, products, loggedIn, favorites) {
      $scope.author = query.author;
      $scope.sort = query.sort;
      $scope.type = query.type || false;
      $scope.page = query.page === 0 ? 0 : query.page || 1;
      $scope.category = query.category;
      $scope.tag = query.tags;
      $scope.favorites = favorites || false;
      $scope.exclude = query.exclude || false;
      $scope.products = products || [];
      $scope.sale = query.sale;

      // Hide the load more button
      $scope.hideButton = query.count < PRODUCTS_COUNT;
      $scope.loading = false;
    }

    function homeInit(filter, products) {
      filter = sessionStorage.getItem("homeTab") || filter;
      angular.copy(products, $scope.productGroup);
      $scope.updateFilter(filter);
      $scope.loading = false;
    }

    /**
     * Destroy and reload the products
     * @author Matt Goucher <matt@mattgoucher.com>
     * @return {undefined}
     */
    function rebuild() {
      $scope.page = 1;
      $scope.products = [];
      $scope.loading = false;
      $scope.disabled = false;
      $scope.rebuilding = true;

      // Delay the fetch
      setTimeout(
        function () {
          $scope.destroy = true;

          return getPage(1);
        },
        $scope.destroy ? 0 : 200
      );
    }

    /**
     * Get the next page of results
     * @author Matt Goucher <matt@mattgoucher.com>
     * @return {undefined}
     */
    function nextPage() {
      // bail if on the home page
      if ($scope.productGroup.featured.length > 0) {
        return;
      }

      $scope.paginating = true;
      return getPage($scope.page + 1);
    }

    /**
     * Get page of products
     * @author Matt Goucher <matt@mattgoucher.com>
     * @return {undefined}
     */
    function getPage(pageNumber) {
      if ($scope.loading) {
        return;
      }

      $scope.loading = true;
      return getProducts(pageNumber);
    }

    /**
     * Get products from backend
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {int} page  Requested page
     * @return {undefined}
     */
    function getProducts(page, callback) {
      var url = "/products/api/page/" + page,
        wait_at_least,
        then;

      if ($scope.favorites && !!$scope.favorites.match(/users\//)) {
        url = $scope.favorites + "/favorites/page/" + page;
      } else if ($scope.favorites) {
        url = "/account/favorites/page/" + page;
      }

      $scope.page = page;
      $scope.disabled = true;

      // JSON PLEASE
      url = url + "?json=1";

      // Freebie URL
      url = window.location.pathname.match(/freebies/)
        ? url + "&freebie=true"
        : url;

      // SORT
      url = $scope.sort ? url + "&sort=" + $scope.sort : url;

      // Tack on author parameter
      url = $scope.author ? url + "&author=" + $scope.author : url;

      // Exclude a product
      url = $scope.exclude ? url + "&exclude=" + $scope.exclude : url;

      // Tack on tag parameter
      url = $scope.tag ? url + "&tag=" + $scope.tag : url;

      // Filter by tag
      url = $scope.activeTag ? url + "&tag=" + $scope.activeTag : url;

      // Allow for a type param
      url = $scope.type ? url + "&type=" + $scope.type : url;

      // Search by category
      url = $scope.category ? url + "&category=" + $scope.category : url;

      // Search on sale
      url = $scope.sale ? url + "&sale=true" : url;

      // Replace double-forward-slashes with single
      url = url.replace(/\/\//g, "/");

      angular.element($window).resize();

      // Execute
      if ($scope.paginating) {
        wait_at_least = 500;
        then = new Date().getTime();
      }

      $http.get(url, { cache: true }).then(function (response) {
        var data = response.data;
        if (callback) return callback(data);

        if (!$scope.paginating) {
          renderProducts(data);
        } else {
          var now = new Date().getTime();
          var time = then - now;
          if (time < wait_at_least) {
            $timeout(function () {
              updateQueryStringParam("page", page);
              renderProducts(data);
            }, wait_at_least - time);
          } else {
            updateQueryStringParam("page", page);
            renderProducts(data);
          }
        }
      });
    }

    /**
     * Render products from fetched object
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {object} fetch {products, query}
     * @return {undefined}
     */
    function renderProducts(fetch) {
      $scope.disabled = false;
      $scope.rebuilding = false;

      // Hide the load more button
      $scope.hideButton = fetch.query.count < PRODUCTS_COUNT;

      $scope.no_results = !(fetch.products || []).length;

      if (!fetch.products || fetch.products.length < PRODUCTS_COUNT) {
        $scope.disabled = true;
      }

      // Hide filter and sort
      $scope.hideFilters = false;

      // Push products to scope
      angular.forEach(fetch.products, function (p) {
        $scope.products.push(p);
      });

      $scope.loading = false;
    }

    /**
     * Change the selected tag
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {string} tag The mongo ID of the tag (from view)
     * @return {undefined}
     */
    function selectTag(tag) {
      $scope.activeTag = tag ? tag : false;
      return rebuild();
    }

    /**
     * Request a freebie download link
     * @author Matt Goucher <matt@mattgoucher.com>
     * @param  {object} e    Javascript event
     * @param  {object} form Angular Form
     * @return {undefined}
     */
    function getFreebie(e, form) {
      e.preventDefault();

      form = form || {};

      // Require Valid Form
      if (form.$invalid) {
        return;
      }

      // We're sending it bro
      form.sending = true;

      // Delays, for daysz
      return $timeout(function () {
        return $http
          .post("/products/" + $scope.freebieProduct.slug + "/freebie", {
            email: $scope.email,
          })
          .then(function () {
            form.sent = true;
            form.errored = false;
            form.$setPristine();
            $timeout(function () {
              $scope.newsletter = false;
            }, 500);
          })
          .catch(function () {
            form.errored = true;

            $timeout(function () {
              form.sending = false;
              form.$setPristine();
            }, 350);
          });
      }, 300);
    }

    function setFreebie(product) {
      $scope.freebieProduct = product;
    }

    function setTop(top) {
      sessionStorage.setItem("top:" + location.pathname, top);
    }

    function getTop() {
      return sessionStorage.getItem("top:" + location.pathname) || 0;
    }

    function updateQueryStringParam(key, value, callback) {
      var baseUrl = [
          location.protocol,
          "//",
          location.host,
          location.pathname,
        ].join(""),
        urlQueryString = document.location.search,
        newParam = value !== "" ? key + "=" + value : "",
        params = "?" + newParam;

      // If the "search" string exists, then build params from it
      if (urlQueryString) {
        var keyRegex = new RegExp("([?&])" + key + "[^&]*");

        // If param exists already, update it
        if (urlQueryString.match(keyRegex) !== null) {
          params = urlQueryString.replace(keyRegex, "$1" + newParam);
        } else {
          // Otherwise, add it to end of query string
          params = urlQueryString + "&" + newParam;
        }
        window.history.replaceState({}, "", baseUrl + params);
        if (callback) callback();
      } else {
        window.history.pushState(
          {},
          "",
          [location.protocol, "//", location.host, location.pathname].join("") +
            params
        );
        if (callback) callback();
      }
    }
  },
]);
