angular.module("UI8.components").component("progressBar", {
  template: '<div class="progress-bar"><div class="fill-bar"></div></div>',

  controller: function ($element) {
    var ctrl = this;

    ctrl.$onInit = function () {
      if (ctrl.color) $element.find(".progress-bar").addClass(ctrl.color);
      $element
        .find(".fill-bar")
        .css("width", String((ctrl.count / ctrl.max) * 100) + "%");
    };

    ctrl.$onChanges = function (changes) {
      $element
        .find(".fill-bar")
        .css(
          "width",
          String((changes.count.currentValue / ctrl.max) * 100) + "%"
        );
    };
  },

  bindings: {
    count: "<",
    max: "<",
    color: "@",
  },
});
