angular.module("UI8.filters").filter("ui8Currency", [
  "$filter",
  function ($filter) {
    return function (value, symbol) {
      return $filter("currency")(
        Math.abs(parseInt(value, 10) / 100),
        symbol
      ).replace(".00", "");
    };
  },
]);
