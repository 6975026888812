/**
 * Capitalize first letter in each word
 */
angular.module("UI8.filters").filter("ui8CapitalizeAll", [
  function () {
    return function (string) {
      var words = (string || "").split(" ");
      var _string = "";
      words.forEach(function (word) {
        _string += word.charAt(0).toUpperCase() + word.slice(1) + " ";
      });
      return _string;
    };
  },
]);
