angular.module("UI8.directives").directive("ui8VideoOverlay", [
  "$document",
  function ($document) {
    function link(scope, elem) {
      var source = elem.find("pre").text();

      function showVideoHandler(e, enabled) {
        if (!enabled) {
          return elem.removeClass("active").find(".video-wrapper").html("");
        }

        source = elem.find("pre").text() || elem.find("pre").html() || source;

        return elem.addClass("active").find(".video-wrapper").html(source);
      }

      $document.on("show-video", showVideoHandler);

      scope.$on("$destroy", function () {
        $document.off("show-video", showVideoHandler);
      });
    }

    return {
      link: link,
      restrict: "A",
      scope: { ui8VideoOverlay: "=" },
    };
  },
]);
