angular.module("UI8.services").service("PromoService", [
  "$http",
  function ($http) {
    this.applyPromo = function (promoCode) {
      return $http.post("/api/promo", { code: promoCode }).then(
        function (response) {
          if (response.error) {
            return Promise.resolve();
          }

          return Promise.resolve(response.data);
        },
        function () {
          return Promise.resolve();
        }
      );
    };

    this.disablePromo = function () {
      return $http.post("/api/promo/disable").then(
        function () {
          return Promise.resolve(true);
        },
        function () {
          return Promise.resolve(true);
        }
      );
    };
  },
]);
