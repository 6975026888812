angular.module("UI8.components").component("home", {
  template:
    '<div class="page">' +
    '  <div class="page__background">' +
    '     <img src="https://images.ui8.net/redesign/hero-home.jpg" alt="Hero" />' +
    "  </div>" +
    '  <div class="page__container container">' +
    '    <div class="page__hero">' +
    "      <h1 class=\"page__title\">{{ ::$ctrl.data.settings.home.title.replace('[count]', $ctrl.data.count) }}</h1>" +
    "      <div class=\"page__description\">{{ ::$ctrl.data.settings.home.subtitle.replace('[users]', $ctrl.data.users) }}</div>" +
    "    </div>" +
    "  </div>" +
    '  <div class="page__filters">' +
    '    <div class="page__container container">' +
    '      <div class="filters filters--home">' +
    '        <div class="filters__btns">' +
    "          <button class=\"filters__btn\" ng-class=\"{'active': $ctrl.filter === 'featured'}\" ng-click=\"$ctrl.updateFilter('featured')\">Featured</button>" +
    "          <button class=\"filters__btn\" ng-class=\"{'active': $ctrl.filter === 'trending'}\" ng-click=\"$ctrl.updateFilter('trending')\">Trending</button>" +
    "          <button class=\"filters__btn\" ng-class=\"{'active': $ctrl.filter === 'new_releases'}\" ng-click=\"$ctrl.updateFilter('new_releases')\">Latest</button>" +
    "        </div>" +
    "      </div>" +
    "    </div>" +
    "  </div>" +
    '  <div class="page__container container">' +
    '    <div class="page__catalog catalog">' +
    '      <product-card ng-repeat="product in $ctrl.products track by product._id" product="product" user="$ctrl.user" link-query="\'?browse=\' + $ctrl.filter"></product-card>' +
    "    </div>" +
    '    <div class="page__foot ng-cloak" ng-if="!$ctrl.loading && $ctrl.filter === \'featured\'">' +
    '      <a class="btn" ui-sref="topProducts">View more</a>' +
    "    </div>" +
    '    <div class="page__foot ng-cloak" ng-if="!$ctrl.loading && $ctrl.filter === \'seasonal_sale\'">' +
    '      <a class="btn" href="/sale">View more</a>' +
    "    </div>" +
    '    <div class="page__foot ng-cloak" ng-if="!$ctrl.loading && $ctrl.filter === \'trending\'">' +
    '      <a class="btn" href="/trending">View more</a>' +
    "    </div>" +
    "  </div>" +
    "</div>",

  controller: function ($scope, $rootScope, $location, HistoryService) {
    var ctrl = this;
    ctrl.products = [];
    ctrl.productGroup = {
      featured: [],
      trending: [],
      new_releases: [],
      freebies: [],
      seasonal_sale: [],
    };

    ctrl.seasonalSaleTitle = false;

    ctrl.attention = false;
    ctrl.attentionText =
      "End Of Year Sale - Save 40% on all products until the end of the month during checkout";

    ctrl.$onInit = function () {
      ctrl.loading = true;
      ctrl.productGroup.featured = ctrl.data.products.featured.products;
      ctrl.productGroup.trending = ctrl.data.products.trending.products;
      ctrl.productGroup.new_releases = ctrl.data.products.new_releases.products;
      ctrl.productGroup.freebies = ctrl.data.products.freebies.products;
      ctrl.productGroup.seasonal_sale =
        ctrl.data.products.seasonal_sale.products;
      ctrl.seasonalSaleTitle =
        ctrl.data.current_sale && ctrl.data.current_sale.promo_title;
      ctrl.updateFilter(getFilter());
      ctrl.loading = false;

      // Try to keep stats in sync
      ctrl.data.count = formatNumber(UI8.stats.products) || ctrl.data.count;
      ctrl.data.users = formatNumber(UI8.stats.members) || ctrl.data.users;

      var top = getTop();
      $(document).ready(function () {
        window.scroll(0, top);
      });

      window.addEventListener(
        "beforeunload",
        function () {
          setFilter(ctrl.filter);
        },
        false
      );

      $scope.$on("$destroy", function () {
        sessionStorage.setItem("homeTab", ctrl.filter);
      });

      function isGoingToProduct(oldPath, oldUrl) {
        return oldPath === "/" && !oldUrl.includes("?search=1");
      }

      HistoryService.rememberScrollPosition(isGoingToProduct, "home");
    };

    ctrl.updateFilter = function (filter) {
      ctrl.filter = filter;
      var max = 48;
      for (var id = 0; id < max; id++) {
        if (ctrl.productGroup[filter][id]) {
          ctrl.products[id] = ctrl.productGroup[filter][id];
        } else {
          if (id === 0) {
            ctrl.products = [];
          } else {
            ctrl.products = ctrl.products.slice(0, id);
          }
          break;
        }
      }
      if (ctrl.products.length > max) {
        ctrl.products = ctrl.products.slice(0, max);
      }
    };

    function formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function getTop() {
      var top = sessionStorage.getItem("top:home") || 0;
      sessionStorage.removeItem("top:home");
      return parseInt(top, 10);
    }

    function setFilter(filter) {
      sessionStorage.setItem("homeTab", filter);
    }

    function getFilter() {
      var filter = sessionStorage.getItem("homeTab") || "featured";
      sessionStorage.removeItem("homeTab");
      return filter;
    }
  },

  bindings: {
    data: "<",
    user: "<",
  },
});
