angular.module("UI8.directives").directive("ui8Compare", [
  function () {
    function link(scope, elem, attrs, ngModel) {
      ngModel.$validators.ui8Compare = function (modelValue) {
        return modelValue === scope.ui8Compare;
      };

      scope.$watch("ui8Compare", function () {
        ngModel.$validate();
      });
    }

    return {
      link: link,
      restrict: "A",
      require: "ngModel",
      scope: {
        ui8Compare: "=ui8Compare",
      },
    };
  },
]);
