/**
 * Convert Bytes into KB, MB, or GB
 *
 * In:  350000
 * Out: '350 KB'
 *
 * Use it like this:
 * {{ 350000 | filesize }}
 *
 */
angular.module("UI8.filters").filter("ui8Filesize", [
  function () {
    return function (bytes) {
      if (!bytes) return;

      // http://stackoverflow.com/a/14919494
      var thresh = 1000;
      if (Math.abs(bytes) < thresh) {
        return bytes + " B";
      }
      var units = ["kB", "MB", "GB", "TB"];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);

      return bytes.toFixed(1) + " " + units[u];
    };
  },
]);
