angular.module("UI8.directives").directive("onLoad", [
  function () {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        elem.ready(function () {
          scope.$eval(attrs.onLoad);
        });
      },
    };
  },
]);
