angular.module("UI8.components").component("passTile", {
  template: `<div class="purchases__item">
    <a class="purchases__preview" ng-href="/product/{{ $ctrl.subscription.product.slug }}">
      <img ng-src="{{ $ctrl.subscription.product.card_image }}" alt="{{ $ctrl.subscription.product.cart_name || $ctrl.subscription.product.name }}">
    </a>
    <div class="purchases__inner">
      <div class="purchases__head">
        <a class="purchases__title" ng-href="/product/{{ $ctrl.subscription.product.slug }}">{{ $ctrl.subscription.product.cart_name || $ctrl.subscription.product.name }}</a>
        <div class="purchases__price" ng-if="$ctrl.subscription.tendered !== $ctrl.subscription.product.price">
          <div class="purchases__full">{{ $ctrl.subscription.product.price | ui8Currency }}</div>
          {{ $ctrl.subscription.tendered | ui8Currency }}
        </div>
        <div class="purchases__price" ng-if="$ctrl.subscription.tendered === $ctrl.subscription.product.price">
          {{ $ctrl.subscription.product.price | ui8Currency }}
        </div>
        </div>
        <div class="purchases__description">
          <span ng-if="$ctrl.isOwner">{{ !$ctrl.singleLicense ? ($ctrl.seats + 1) + ' Seat License' : 'Single-user license' }}</span>
          <span ng-if="!$ctrl.isOwner">1-Seat Team License</span>
          <span ng-if="$ctrl.showCancelAutoRenew"> / </span>
          <span ng-if="$ctrl.showCancelAutoRenew && !$ctrl.subscription.expired && !$ctrl.index">{{ $ctrl.subscription.pass_expires }} days remaining</span>
          <span ng-if="$ctrl.subscription.expired || $ctrl.index">Pass expired</span>
        </div>
      <div class="purchases__actions">
        <a class="btn btn--stroke btn--icon-only" ng-if="$ctrl.isOwner && !$ctrl.multipleInvoices" ng-href="/account/invoice/{{ $ctrl.subscription.order }}" target="_blank">
          <svg><path fill-rule="evenodd" d="M15.241 1H8.759l-2.011.044c-.562.046-1.079.144-1.564.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.289 3 5.954 3 6.759v10.483l.044 2.011c.046.562.144 1.079.392 1.564a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C7.289 23 7.954 23 8.759 23h6.483l2.01-.044c.562-.046 1.079-.145 1.564-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.011V6.759l-.044-2.011c-.046-.562-.145-1.079-.392-1.564a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C16.711 1 16.046 1 15.241 1zM8 6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z"/></svg>
        </a>
        <a class="btn btn--stroke btn--icon-only" ng-if="$ctrl.isOwner && $ctrl.multipleInvoices" ui8-toggle-class='invoices-modal' ui8-track-event="Open Invoices Modal">
          <svg><path fill-rule="evenodd" d="M15.241 1H8.759l-2.011.044c-.562.046-1.079.144-1.564.392a4 4 0 0 0-1.748 1.748c-.247.485-.346 1.002-.392 1.564C3 5.289 3 5.954 3 6.759v10.483l.044 2.011c.046.562.144 1.079.392 1.564a4 4 0 0 0 1.748 1.748c.485.247 1.002.346 1.564.392C7.289 23 7.954 23 8.759 23h6.483l2.01-.044c.562-.046 1.079-.145 1.564-.392a4 4 0 0 0 1.748-1.748c.247-.485.346-1.002.392-1.564.044-.541.044-1.206.044-2.011V6.759l-.044-2.011c-.046-.562-.145-1.079-.392-1.564a4 4 0 0 0-1.748-1.748c-.485-.247-1.002-.346-1.564-.392C16.711 1 16.046 1 15.241 1zM8 6a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H8zm-1 5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm0 4a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1z"/></svg>
        </a>
        <div ng-if="$ctrl.showCancelAutoRenew && !$ctrl.subscription.expired && !$ctrl.index">
          <div ng-if="!$ctrl.isPaypalSubscription && $ctrl.settings.defaultCard.id">
            <button class="btn btn--stroke" ng-click="$ctrl.onCardRemove()">Cancel subscription</button>
          </div>
          <div ng-if="$ctrl.isPaypalSubscription && $ctrl.activePaypalSubscription">
            <button class="btn btn--stroke" ng-click="$ctrl.onCancelPaypalSubscription()">Cancel subscription</button>
          </div>
        </div>
      </div>
    </div>
  </div>`,

  controller: function () {
    var ctrl = this;

    ctrl.$onInit = function () {
      var subscription = ctrl.subscription;
      var isOwner = !!subscription.owner;
      ctrl.showCancelAutoRenew = !subscription.product.subscription_lifetime;

      if (isOwner) {
        ctrl.isOwner = true;
        ctrl.seats = subscription.licenses || 1;
        ctrl.hasTeam = !!subscription.licenses;
        ctrl.singleLicense = !subscription.licenses;
        ctrl.availableInvites = subscription.invitesRemaining;
        ctrl.multipleInvoices =
          subscription.additionalOrders && subscription.additionalOrders.length;

        if (ctrl.multipleInvoices) {
          ctrl.allOrders = [ctrl.subscription]
            .concat(subscription.additionalOrders)
            .reverse();
        }

        ctrl.users = [ctrl.subscription.owner]
          .concat(subscription.teamMembers || [])
          .concat(subscription.outstandingInvites || []);
      }
    };

    ctrl.openModalForUser = function (user, modalName) {
      ctrl.modalUser = user;
      $("body").addClass(modalName + "-modal");
    };
  },

  bindings: {
    subscription: "<",
    settings: "<",
    index: "<",
    onCardRemove: "&",
    isPaypalSubscription: "<",
    activePaypalSubscription: "<",
    onCancelPaypalSubscription: "&",
  },
});
