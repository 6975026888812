/**
 * Slugify an input string
 * @author Matt Goucher <matt@mattgoucher.com>
 * @param  {[type]} 'UI8.filters' [description]
 * @return {[type]}               [description]
 */
angular.module("UI8.filters").filter("ui8Slugify", [
  function () {
    return function (input) {
      return (input || "")
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w\-]+/g, "") // Remove all non-word chars
        .replace(/\-\-+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    };
  },
]);
