angular.module("UI8.directives").directive("ui8LazyLoad", [
  function () {
    var $win = $(window);

    /**
     * Called on directive init
     * @param  {object} scope   Private scope
     * @param  {object} element jQ wrapped element
     * @return {undefined}
     */
    function linker(scope, element) {
      var el = scope.container ? scope.container : $win;

      function scrollHandler() {
        // Lazy is disabled
        if (scope.disable) return;

        // If element is visible
        if (elementIsVisible()) return scope.whenVisible();
      }

      scope.$on("$destroy", function () {
        el.off("scroll.cdlazyload");
      });

      // Listen for window scroll
      el.on("scroll.cdlazyload", scrollHandler);

      /**
       * Determine if element is visible on screen
       * @return {Boolean}        If element is visible
       */
      function elementIsVisible() {
        var containerTop, containerBottom, elemTop, elemBottom;

        if (scope.container) {
          containerTop = scope.container.offset().top;
          containerBottom = containerTop + scope.container.height();
          // Trigger lazy load when loader element is 50px lower than bottom of container
          elemTop = element.offset().top - 50;
          elemBottom = elemTop + element.height();
        } else {
          containerTop = $win.scrollTop();
          containerBottom = containerTop + $win.height();
          elemTop = element.offset().top;
          elemBottom = elemTop + element.height();
        }

        return elemBottom <= containerBottom && elemTop >= containerTop;
      }
    }

    // Public API
    return {
      link: linker,
      scope: {
        disable: "=",
        container: "=",
        whenVisible: "&",
      },
      restrict: "A",
    };
  },
]);
