/**
 * Capitalize the first letter
 */
angular.module("UI8.filters").filter("ui8Capitalize", [
  function () {
    return function (input) {
      return input
        ? input.replace(/([^\W_]+[^\s-]*) */g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        : "";
    };
  },
]);
