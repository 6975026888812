angular.module("UI8.components").component("verificationModal", {
  template: `<div class="modal modal--verify open" ng-if="$ctrl.display">
      <button class="modal__close" ng-click="$ctrl.display = false" ui8-track-event="Close Download Verfication Modal"></button>
      <div class="modal__container">
        <div class="verify">
          <div class="verify__title">Verify your account</div>
          <div class="verify__content">We have detected that you have logged in from an new location or device. Check your inbox and enter the 6-digit code in the field below to proceed with your download.</div>
          <div class="field">
            <input class="field__input" ng-class="{ error: $ctrl.error }" type="number" name="verification_code" ng-model="$ctrl.verificationCode" ng-keypress="$ctrl.error = false" ng-pattern="/^[0-9]{6}$/i" autocomplete="off" placeholder="Enter 6-digit code" required>
          </div>
          <button class="btn btn--submit" ng-click="$ctrl.verify()" ng-if="!$ctrl.pending">Submit</button>
          <div class="loader" ng-if="$ctrl.pending"></div>
          <div class="verify__foot">Need help? <a onclick="Intercom('show')">Contact support</a></div>
        </div>
      </div>
      <div class="modal__backdrop" ng-click="$ctrl.display = false" ui8-track-event="Close Download Verfication Modal"></div>
    </div>`,

  controller: function ($http, $element, FlashService) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.error = false;
      ctrl.pending = false;

      $element.on("click", function (e) {
        e.stopPropagation();
      });

      $element
        .find("input[name='verification_code']")
        .on("keydown", function (e) {
          if (e.key === "Enter") ctrl.verify();
        });
    };

    ctrl.$destroy = function () {
      $element.find("input[name='verification_code']").off();
      $element.off();
    };

    ctrl.verify = function () {
      ctrl.pending = true;
      $http
        .post("/api/device/verify", { verificationCode: ctrl.verificationCode })
        .then(
          function (res) {
            if (res.data.error) {
              ctrl.error = true;
            } else {
              ctrl.display = false;
            }
            ctrl.pending = false;
          },
          function () {
            FlashService.set("error", "Server error. Please contact support.");
            ctrl.error = true;
            ctrl.pending = false;
          }
        );
    };
  },

  bindings: {
    display: "=?",
  },
});
