angular.module("UI8.controllers").controller("HeaderController", [
  "$scope",
  "$rootScope",
  "$location",
  "$timeout",
  function ($scope, $rootScope) {
    const browse = document.querySelector(".header__cell--browse"),
      menuInner = document.querySelector(".menu__inner");

    const applyOverlayMask = (e) => {
      if (browse.classList.contains("active")) {
        const overlayEl = e.currentTarget;
        const x = e.clientX - menuInner.offsetLeft;
        const y = e.clientY - menuInner.offsetTop;

        overlayEl.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
      }
    };

    document.body.addEventListener("pointermove", applyOverlayMask);

    $scope.openSearchWithAppFilter = function (val) {
      val = val || sessionStorage.getItem("search:app-filter");

      $rootScope.showSearch = true;
      $rootScope.appFilter = val || "Any Platform";
      sessionStorage.setItem("search:app-filter", $rootScope.appFilter);
    };
  },
]);
