angular.module("UI8.components").component("designContext", {
  template: `<div class="context" ng-if="$ctrl.isVisible">
      <div class="context__caption">UI8 DESIGN CONTEST</div>
      <div class="context__title">SEASON 01</div>
      <div class="context__text">Submit Framer templates for a chance to win up to <span>$10,000</span> in cash prizes.</div>
      <a class="btn" href="https://ui8.notion.site/UI8-Design-contest-Season-1-b873fcef95dc4042867eed8eba91569a" target="_blank">
        Learn more
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff"><path d="M6.465 1.667h1.868c.46 0 .833.373.833.833s-.373.833-.833.833H6.5l-1.574.031c-.365.03-.552.084-.683.15-.314.16-.569.415-.728.728-.066.13-.121.317-.15.683-.031.375-.031.86-.031 1.574v7l.031 1.574c.03.365.084.552.15.683.16.314.415.569.728.728.13.066.317.12.683.15.375.031.86.031 1.574.031h7l1.574-.031c.365-.03.552-.084.683-.15.314-.16.569-.415.728-.728.067-.13.121-.317.15-.683.031-.375.031-.86.031-1.574v-1.833c0-.46.373-.833.833-.833s.833.373.833.833v1.868l-.037 1.675c-.038.469-.12.899-.326 1.304a3.34 3.34 0 0 1-1.457 1.457c-.404.206-.835.288-1.303.327-.451.037-1.005.037-1.675.037H6.465c-.671 0-1.224 0-1.675-.037-.468-.038-.899-.12-1.303-.327-.627-.32-1.137-.829-1.457-1.457-.206-.405-.288-.835-.326-1.304-.037-.451-.037-1.005-.037-1.675V6.466l.037-1.675c.038-.468.12-.899.326-1.303a3.34 3.34 0 0 1 1.457-1.457c.404-.206.835-.288 1.303-.326.451-.037 1.005-.037 1.675-.037zm5.202.833c0-.46.373-.833.833-.833h5c.46 0 .833.373.833.833v5c0 .46-.373.833-.833.833s-.833-.373-.833-.833V4.512l-6.077 6.077c-.325.325-.853.325-1.179 0s-.325-.853 0-1.178l6.077-6.077H12.5c-.46 0-.833-.373-.833-.833z"/></svg>
      </a>
      <button class="context__close" ng-click="$ctrl.close()"></button>
    </div>`,

  controller: function ($element) {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.isVisible = !localStorage.getItem("visible-design-context");
    };

    ctrl.close = function () {
      ctrl.isVisible = false;
      localStorage.setItem("visible-design-context", false);
    };

    ctrl.$destroy = function () {
      $element.find(".context__close").off();
    };
  },

  bindings: {},
});
