angular.module("UI8.directives").directive("ui8DownloadButton", [
  "$window",
  "$document",
  "PushService",
  function (PushService) {
    // Linker
    function link(scope, elem, attrs) {
      var num = 0,
        modal = angular.element("<div class='modal modal--download'></div>"),
        container = angular.element("<div class='modal__container'></div>"),
        download = angular.element("<div class='download'></div>"),
        downloadList = angular.element("<ul class='download__buttons'></ul>"),
        product = scope.ui8DownloadButton,
        user;

      scope.$on("$destroy", function () {
        elem.off();
        modal.remove();
      });

      // Set up for handling push notification subscription
      function initPushNotificationHandler() {
        // Set up for handling push notification subscription
        if (!("serviceWorker" in navigator)) {
          // Service Worker isn't supported on this browser, disable or hide UI.
          return;
        }

        if (!("PushManager" in window)) {
          // Push isn't supported on this browser, disable or hide UI.
          return;
        }

        // Push is supported.
        PushService.setUpPush()
          .then(function (subscription) {
            if (!subscription) {
              return PushService.subscribe();
            }
          })
          .then(function () {})
          .catch(function () {});
      }

      // Open / Close the whole thing
      function open() {
        window.scrollLock.disablePageScroll();
        modal.addClass("open");
      }

      function close(e) {
        if (e) {
          e.preventDefault();
        }

        window.scrollLock.enablePageScroll();
        modal.removeClass("open");
        initPushNotificationHandler();
      }

      // Wrap Element in container
      elem.on("click", function (e) {
        e.preventDefault();
        return open();
      });

      // Support for free files
      product.files = scope.free ? product.free_files : product.files;

      if (!product || !product.files) {
        return elem.addClass("ng-hide");
      }

      container.append(download);
      download.append(
        `<div class="download__preview">
            <img src="${product.card_image}" alt="Preview" />
          </div>`
      );
      download.append(downloadList);

      // Create a list item for each download option
      product.files.forEach(function (i) {
        var link = "";

        if (!i.name || !i._id) return;

        // Tack on bundle reference
        // bundle = scope.bundle ? scope.bundle + '/' : '';

        // Massage the user
        user = scope.user ? "?t=" + scope.user : "";
        if (!user) {
          user = attrs.theUser ? "?t=" + attrs.theUser : "";
        }

        // Increment the number created
        num = num + 1;

        // Compute link
        var queryString = user ? user + "&redirect=1" : "?redirect=1";
        link = "/account/download/" + product._id + "/" + i._id + queryString;

        // Append to list
        downloadList.append(
          angular.element("<li></li>").append(
            angular.element(
              `<a
                class="btn btn--submit"
                target="_blank"
                filename="${i.name}"
                href="${link}"
                ui8-track-event="Product,Direct Download"
                onClick="if(typeof ga !== 'undefined'){ga('send', 'pageview', '/virtual/download/${product.slug}')}">
                  ${i.name}
                  <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"></path></svg>
              </a>`
            )
          )
        );
      });

      // No downloads
      if (!num) {
        return elem.addClass("ng-hide");
      }

      return modal
        .append(
          container,
          angular
            .element('<button class="modal__close"></button>')
            .on("click touchstart", close),
          angular
            .element('<div class="modal__backdrop"></div>')
            .on("click", close)
        )
        .appendTo(document.body);
    }

    return {
      link: link,
      restrict: "A",
      scope: {
        user: "=",
        free: "=",
        bundle: "=",
        ui8DownloadButton: "=",
      },
    };
  },
]);
