angular.module("UI8.directives").directive("ui8Avatar", [
  function () {
    // Linkers
    function link(scope, elem, attrs) {
      // In case we get a null object
      scope.user = scope.user || {};
      const S3_IMAGE_SERVER = "https://images.ui8.net";

      function cacheValue(name, fn) {
        if (window[name] != null) {
          return window[name];
        }

        window[name] = fn();
        return window[name];
      }

      const isHighDensity = cacheValue("isHighDensity", () => {
        if (window.matchMedia) {
          if (
            window.matchMedia(
              "only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)"
            ).matches
          ) {
            return true;
          }

          if (
            window.matchMedia(
              "only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)"
            ).matches
          ) {
            return true;
          }
        }

        return window.devicePixelRatio && window.devicePixelRatio > 1.3;
      });
      const isRetina = cacheValue("isRetina", () => {
        if (window.matchMedia) {
          if (
            window.matchMedia(
              "only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)"
            ).matches
          ) {
            return true;
          }

          if (
            window.matchMedia(
              "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)"
            ).matches
          ) {
            return true;
          }
        }

        if (window.devicePixelRatio && window.devicePixelRatio >= 2) {
          return true;
        }

        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
      });
      const isWebpSupported = cacheValue("isWebpSupported", () => {
        const elem = document.createElement("canvas");

        if (elem.getContext && elem.getContext("2d")) {
          // was able or not to get WebP representation
          return elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
        }

        // old browser, canvas not supported
        return false;
      });

      // User has an avatar
      if (scope.user.avatar_url) {
        let avatarUrl = scope.user.avatar_url;
        let avatarUrlVersion = scope.user.avatar_url_version;

        const resizeTo = attrs.resizeTo || "width=96,height=96";
        if (!scope.user.has_resized_avatar) {
          const match = avatarUrl.match(
            /(http(?:s?):\/\/images\.ui8\.net)(.*)/
          );

          if (match) {
            avatarUrl = match[1] + "/cdn-cgi/image/" + resizeTo + match[2];
          }
        } else {
          const dim = resizeTo.match(/width=(\d+),height=(\d+)/);
          let resolution = { width: 96, height: 96 };
          if (dim) {
            resolution.width = parseInt(dim[1]);
            resolution.height = parseInt(dim[2]);
          }

          if (isHighDensity || isRetina) {
            resolution.width = resolution.width * 2;
            resolution.height = resolution.height * 2;
          }

          const postfix = avatarUrlVersion ? `_${avatarUrlVersion}` : "";
          const extension = isWebpSupported ? "webp" : "png";

          avatarUrl = `${S3_IMAGE_SERVER}/avatar/${scope.user._id}/${resolution.width}_${resolution.height}${postfix}.${extension}`;
        }

        return elem
          .addClass("ui8-avatar")
          .css("background", "url(" + avatarUrl + ") center center/contain");
      }
    }

    return {
      link: link,
      restrict: "A",
      scope: {
        user: "=ui8Avatar",
      },
    };
  },
]);
