angular.module("UI8.services").service("LikeService", [
  "$http",
  "$window",
  function ($http, $window) {
    var likes = [],
      unlikes = [],
      loggedIn = false,
      newLikes = [],
      ogLikes = [];

    function init() {
      if (!$window.UI8.loggedIn) return;

      // User logged in
      loggedIn = true;

      // Fetch
      return $http
        .get("/account/favorites?json=1", { cache: true })
        .then(function (res) {
          likes = res.data.favorites || [];
          ogLikes = angular.copy(res.data.favorites) || [];
        })
        .catch(function () {
          likes = [];
        });
    }

    /**
     * Determine if this product was recently liked
     */
    function getCountModifier(id) {
      id = String(id);

      var newLike = newLikes.filter(function (el) {
          return id === el;
        }).length,
        ogLike = ogLikes.filter(function (el) {
          return id === el;
        }).length,
        like = likes.filter(function (el) {
          return id === el;
        }).length;

      if (!like && ogLike) {
        return -1;
      } else if (newLike && !ogLike) {
        return 1;
      } else {
        return 0;
      }
    }

    /**
     * Determine if user likes Product
     * @param  {String} _id Unique mongo key of product to check
     * @return {Number} The amount of likes per that _id
     */
    function isLiked(_id) {
      if (!loggedIn) {
        return;
      }

      return likes.filter(function (el) {
        return el === _id;
      }).length;
    }

    /**
     * Determine if user has unliked Product (Favorites page only)
     * @param  {String} _id Unique mongo key of product to check
     * @return {Number} The amount of likes per that _id
     */
    function unLiked(_id) {
      if (!loggedIn) {
        return;
      }

      return unlikes.filter(function (el) {
        if (!likes.length) {
          likes = ["null"];
          return $window.location.reload();
        }

        return el === _id;
      }).length;
    }

    /**
     * Like a product
     * @param  {String} _id Unique mongo key of product to check
     */
    function likeProduct(_id, isFavoritePage) {
      if (!loggedIn) {
        return;
      }

      if (isLiked(_id) > 0) {
        if (isFavoritePage) {
          unlikes.push(_id);
        }

        likes = likes.filter(function (el) {
          return el !== _id;
        });

        newLikes = newLikes.filter(function (el) {
          return el !== _id;
        });

        $http.delete("/account/favorite/" + _id + "?json=1");

        return;
      }

      // Push the like to the new likes array
      newLikes.push(_id);
      likes.push(_id);

      $http.put("/account/favorite/" + _id + "?json=1");
    }

    init();

    // Public API
    return {
      isLiked: isLiked,
      unLiked: unLiked,
      toggleLike: likeProduct,
      getCountModifier: getCountModifier,
    };
  },
]);
