/**
 * UI8 - Provide events for header dropdown interactions
 * @author Bill Broughton <bill@ui8.net>
 */
angular.module("UI8.directives").directive("ui8HeaderDropdown", [
  "$injector",
  function () {
    return {
      restrict: "A",
      scope: true,
      link: function (scope, elem) {
        const isTouchDevice = () =>
          "ontouchstart" in window ||
          navigator.maxTouchPoints > 0 ||
          navigator.msMaxTouchPoints > 0;

        scope.$on("$destroy", function () {
          elem.find("a").off();
          elem.off();
        });

        var $body = $("body"),
          $head = elem.find(".header__head"),
          $dropdown = elem.find(".header__dropdown"),
          $close = elem.find(".header__logo, .header__close");

        $head.on("click", function (e) {
          e.stopPropagation();

          if ($body.hasClass("cart")) {
            $body.removeClass("cart");
            window.scrollLock.enablePageScroll();
            $body.find(".header__cell--cart.active").removeClass("active");
          }

          if (elem.hasClass("active-touch")) {
            elem.removeClass("active-touch");
            isTouchDevice() && window.scrollLock.enablePageScroll();
          } else {
            $body
              .find(".header__cell.active-touch")
              .removeClass("active-touch");
            elem.toggleClass("active-touch");
            isTouchDevice() && window.scrollLock.disablePageScroll();
          }
        });

        $(document).on("click", function () {
          elem.removeClass("active-touch");
          isTouchDevice() && window.scrollLock.enablePageScroll();
        });

        $dropdown.on("click", function (e) {
          e.stopPropagation();
        });

        $dropdown.on("scroll", function () {
          if ($dropdown.scrollTop() > 0) {
            $dropdown.addClass("active");
          } else {
            $dropdown.removeClass("active");
          }
        });

        $close.on("click", function () {
          elem.removeClass("active-touch");
          isTouchDevice() && window.scrollLock.enablePageScroll();
        });

        elem.on("mouseleave", function () {
          elem.removeClass("active");
        });

        elem.on("mouseenter", function () {
          if (!$body.hasClass("cart")) {
            $body.find(".header__cell.active").removeClass("active");
            elem.addClass("active");
          }
        });
      },
    };
  },
]);
