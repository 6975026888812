angular.module("UI8.controllers").controller("UpsellController", [
  "CartService",
  "$scope",
  function (CartService, $scope) {
    $scope.addPassUpsellToCart = function () {
      $("body").removeClass("upsell-modal");
      CartService.passUpsellToCart($scope.pass, false);
    };

    $scope.learnMore = function () {
      $("body").removeClass("upsell-modal");
      window.location = "/products/all-access-pass";
    };
  },
]);
