/**
 * Pluralize a non plural or single an already plural string
 * @author Matt Goucher <matt@mattgoucher.com>
 */
angular.module("UI8.filters").filter("ui8Pluralize", [
  function () {
    return function (input, count) {
      var regex = /s$/gi,
        startedPlural = !!input.match(regex, ""),
        shouldBePlural = count !== 1;

      if (startedPlural && shouldBePlural) {
        return input;
      } else if (startedPlural && !shouldBePlural) {
        return input.replace(regex, "");
      } else if (shouldBePlural) {
        return input + "s";
      }

      return input;
    };
  },
]);
