/**
 * UI8 - Scroller (animted scrolltop)
 * @author Matt Goucher <matt@mattgoucher.com>
 */
angular.module("UI8.directives").directive("ui8ToggleClass", [
  function () {
    var $doc = $(document),
      elements = {};

    function link(scope, elem, attrs) {
      scope.$on("$destroy", function () {
        elem.off("click");
      });

      elem.on("click", function (e) {
        if (!attrs.allowDefault) e.preventDefault();

        if (!attrs.element) attrs.element = "body";

        if (/modal/.test(attrs.ui8ToggleClass)) {
          $doc.find("body").trigger("open-modal", [attrs.ui8ToggleClass]);
        }

        if ($(this).data("action") && window.ga) {
          window.ga(
            "send",
            "event",
            $(this).data("category") || undefined,
            $(this).data("action") || undefined,
            $(this).data("label") || undefined
          );
        }

        if (elements[attrs.element]) {
          elements[attrs.element].toggleClass(attrs.ui8ToggleClass);
        } else {
          elements[attrs.element] = angular
            .element(attrs.element)
            .toggleClass(attrs.ui8ToggleClass);
        }

        // Trigger the respective class name
        $doc.trigger(
          attrs.ui8ToggleClass,
          elements[attrs.element].hasClass(attrs.ui8ToggleClass)
        );
      });
    }

    return {
      link: link,
      restrict: "A",
    };
  },
]);
