angular.module("UI8.components").component("openShop", {
  template: `
    <div class="seller">
      <img class="seller__background" src="/img/seller.jpg" alt="Background" />
      <div class="seller__container container">
        <div class="seller__head">
          <h1 class="seller__title">Become an author</h1>
          <div class="seller__description">Authors can join UI8 by invitation or by applying using the form below.</div>
        </div>
        <div class="seller__auth auth auth--author">
          <div class="auth__background">
            <img src="/img/auth-seller.jpg" alt="Sign in" />
          </div>
          <div class="auth__container" ng-if="!$ctrl.isSuccess">
            <div class="auth__title">Apply to open a shop</div>
            <div class="field">
              <div class="field__label">Email</div>
              <input class="field__input tab-email" ng-class="{error: $ctrl.emailError}" placeholder="designer@example.com" ng-keydown="$ctrl.emailError = false" type="email" name="email" ng-model="$ctrl.seller.email" ng-pattern="/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$/i" required>
            </div>
            <div class="field">
              <div class="field__label">Full name</div>
              <input class="field__input" ng-class="{error: $ctrl.fullNameError}" placeholder="Jamie Davis" ng-keydown="$ctrl.fullNameError = false" type="text" name="full_name" ng-model="$ctrl.seller.full_name" required>
            </div>
            <div class="field">
              <div class="field__label">Link to portfolio or product example</div>
              <input class="field__input" ng-class="{error: $ctrl.portfolioLinkError}" placeholder="https://mywebsite.com" ng-keydown="$ctrl.portfolioLinkError = false" type="text" name="portfolio_link" ng-model="$ctrl.seller.portfolio_link" required>
            </div>
            <div class="field">
              <div class="field__label">Additional information</div>
              <textarea class="field__textarea" placeholder="Write a short message (optional)..." name="additional_info" ng-model="$ctrl.seller.additional_info"></textarea>
              <input type="text" ui8-go-tab='.tab-email' />
            </div>
            <button class="btn btn--submit" ng-if="!$ctrl.isSubmitting" ng-click="$ctrl.submit()">Apply</button>
            <div class="loader" ng-if="$ctrl.isSubmitting"></div>
          </div>
          <div class="auth__container" ng-if="$ctrl.isSuccess">
            <div class="auth__title">Application under review</div>
            <div class="auth__content">
              <p>Thank you for submitting your application to become a part of the UI8 family.</p>
              <p>Your application is undergoing a thorough review, and we will be in touch with you via email within the next 1-3 business days with our decision and any subsequent steps.</p>
            </div>
          </div>
        </div>
        <div class="faq">
          <h2 class="faq__title">Frequently asked questions</h2>
          <div class="faq__list">
            <div class="faq__item">
              <h3 class="faq__head">What is the acceptance criteria?</h3>
              <div class="faq__body">New product submissions are accepted based on the overall level of quality, polish, usability and value. Please browse some of our featured products to get a better sense of the type of products we typically approved to be released on our platform.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">How much do I earn for sales?</h3>
              <div class="faq__body">Your products will be available to customers through two options: by purchasing it individually or with an all-access pass. For individual purchases, you earn 70% on each sale and 2% of the value of your product for each unique download. Typically, individual purchases generate 85-95% of your revenue.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">When and how do I get paid?</h3>
              <div class="faq__body">Payments are fully automated via PayPal, taking place at the end of each month as long as you have a minimum balance of $100.00 in your account. Please be sure to update your payment settings with a valid PayPal email address to avoid delays.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">How long will it take to review my application?</h3>
              <div class="faq__body">Our review process could take 1-2 business days upon submission. After the review process we will reach out to you via email with our decision and subsequent steps to open your author account.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">How long does it take to review new product submissions?</h3>
              <div class="faq__body">New product submission reviews are typically done within 24 hours. You will receive a notification in case the product is approved or denied. The same applies to existing product updates.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">Can my product be excluded from the All-Access Pass?</h3>
              <div class="faq__body">All products on our platform are accessible with the All-Access Pass and cannot be excluded. If you wish to permanently remove your products please contact support.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">Can I sell on other marketplaces?</h3>
              <div class="faq__body">We do not require products to be exclusively available on our platform. You’re welcome and encouraged to release products across multiple marketplaces to maximize your exposure and earning potential.</div>
            </div>
            <div class="faq__item">
              <h3 class="faq__head">How do I get my product featured?</h3>
              <div class="faq__body">Featured products are hand-picked by our internal design team. Our selection is based on the general level of quality, polish, usability and value. You may not submit requests to get your product featured.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,

  controller: function ($timeout) {
    var ctrl = this;
    ctrl.isSubmitting = false;

    ctrl.$onInit = function () {
      if (ctrl.user) {
        ctrl.seller = ctrl.user;
        ctrl.seller.full_name =
          ctrl.user.first_name && ctrl.user.last_name
            ? `${ctrl.user.first_name} ${ctrl.user.last_name}`
            : "";
      } else {
        ctrl.seller = {};
      }

      window.scrollTo(0, 0);

      // faq
      $(".faq__head").on("click", function () {
        $(this).toggleClass("active");
        $(this).next().slideToggle();
      });
    };

    ctrl.$destroy = function () {
      $(".faq__head").off();
    };

    // submit the form
    ctrl.submit = function () {
      if (!validateEmail(ctrl.seller.email)) return (ctrl.emailError = true);
      if (!ctrl.seller.full_name) return (ctrl.fullNameError = true);
      if (!ctrl.seller.portfolio_link) return (ctrl.portfolioLinkError = true);

      ctrl.isSubmitting = true;

      // google tracking
      window.dataLayer.push({
        event: "formSubmission",
        formAction: "form submit - become an author",
      });

      var config = {
        emailjs_service_id: "service_pfngzjf",
        emailjs_template_id: "template_cg0ace9",
        emailjs_public_key: "QW-V0faGeGNDpoc_p",
      };

      var params = {
        full_name: ctrl.seller.full_name,
        email: ctrl.seller.email,
        portfolio_link: ctrl.seller.portfolio_link,
        additional_info: ctrl.seller.additional_info,
      };

      return window.emailjs
        .send(
          config.emailjs_service_id,
          config.emailjs_template_id,
          params,
          config.emailjs_public_key
        )
        .then(
          function () {
            $timeout(function () {
              ctrl.isSubmitting = false;
              ctrl.isSuccess = true;
            });
          },
          function () {
            $timeout(function () {
              ctrl.isError = true;
            });
          }
        );
    };

    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },

  bindings: {
    user: "<",
  },
});
