angular.module("UI8.components").component("softwareFilter", {
  template:
    "<div class='filters__select'>" +
    "  <div class='filters__head'>" +
    "    <div class='filters__label'>Format</div>" +
    "    <div class='filters__arrow'>" +
    '      <img ng-src="/img/app-icons/{{$ctrl.isActiveSlug()}}.svg" alt="Select" />' +
    "    </div>" +
    "  </div>" +
    "  <div class='filters__dropdown' ng-class='{isWide: $ctrl.softwareFilters.length > 4, isWider: $ctrl.softwareFilters.length > 9}'>" +
    "    <button class='filters__btn' ng-repeat='app in $ctrl.softwareFilters track by app.slug' ng-click='$ctrl.updateFilter(app.name)' ng-class='$ctrl.isActive(app.name)'>" +
    "      <img ng-src='/img/app-icons/{{::app.slug}}.svg' ng-alt='{{ ::app.name }}' />" +
    "      <span>{{ app.new_name || app.name }}</span>" +
    "    </button>" +
    "  </div>" +
    "</div>",

  controller: function () {
    var ctrl = this;
    var slug;

    var filters = {
      "Any Platform": {
        name: "Any Platform",
        slug: "any-prog",
        new_name: "Any format",
        alt_name: "Any Software",
      },
      Sketch: {
        name: "Sketch",
        slug: "sketch-prog",
      },
      XD: {
        name: "XD",
        slug: "xd-prog",
      },
      InVision: {
        name: "InVision",
        slug: "invision-prog",
      },
      Figma: {
        name: "Figma",
        slug: "figma-prog",
      },
      React: {
        name: "React",
        slug: "react-prog",
      },
      Xcode: {
        name: "Xcode",
        slug: "xcode-prog",
      },
      Html: {
        name: "HTML",
        slug: "html-prog",
        new_name: "HTML/CSS",
      },
      Swift: {
        name: "Swift",
        slug: "swift-prog",
      },
      Framer: {
        name: "Framer",
        slug: "framer-prog",
      },
      Photoshop: {
        name: "Photoshop",
        slug: "ps-prog",
      },
      Illustrator: {
        name: "Illustrator",
        slug: "ai-prog",
      },
      "After Effects": {
        name: "After Effects",
        slug: "ae-prog",
      },
      Keynote: {
        name: "Keynote",
        slug: "keynote-prog",
      },
      PowerPoint: {
        name: "PowerPoint",
        slug: "powerpoint-prog",
      },
      Blender: {
        name: "Blender",
        slug: "blender-prog",
      },
      "Cinema 4D": {
        name: "Cinema 4D",
        slug: "c4d-prog",
      },
      "3D Studio Max": {
        name: "3D Studio Max",
        slug: "3ds-prog",
      },
      Maya: {
        name: "Maya",
        slug: "maya-prog",
      },
      Spline: {
        name: "Spline",
        slug: "spline-prog",
      },
    };

    function getCurrentFilter() {
      slug = location.pathname.split("/").pop();
      return (
        sessionStorage.getItem(slug + ":category-software-filter") ||
        "Any Platform"
      );
    }

    function filterApps(compatibleApps) {
      var apps = compatibleApps.map(function (name) {
        return filters[name];
      });

      apps.unshift(filters["Any Platform"]);

      apps = apps.filter(function (app) {
        return !!app;
      });

      if (apps.find((app) => app.name === "Sketch")) {
        apps.push({
          name: "Lunacy",
          slug: "lunacy-prog",
        });
      }

      // if (ctrl.limitApps) apps = apps.slice(0, 6);

      return apps;
    }

    ctrl.$onInit = function () {
      if (
        ctrl.categoryName === "UI Kits" ||
        ctrl.categoryName === "Wireframe Kits" ||
        ctrl.categoryName === "Themes & Templates"
      ) {
        delete filters.Photoshop;
      }

      if (
        ctrl.categoryName === "UI Kits" ||
        ctrl.categoryName === "Themes & Templates"
      ) {
        delete filters.Illustrator;
      }

      ctrl.currentFilter = getCurrentFilter();

      ctrl.updateFilter(ctrl.currentFilter);
    };

    ctrl.$onChanges = function () {
      if (ctrl.showApps && ctrl.showApps.length) {
        ctrl.softwareFilters = filterApps(ctrl.showApps);
      } else {
        var apps = Object.values(filters);
        ctrl.softwareFilters = ctrl.limitApps ? apps.slice(0, 6) : apps;
      }

      var validFilter = !!ctrl.softwareFilters.filter(function (f) {
        return (
          f.name === ctrl.currentFilter || f.alt_name === ctrl.currentFilter
        );
      }).length;

      if (!validFilter) ctrl.updateFilter("Any Platform");
    };

    // Set Active Selection
    ctrl.updateFilter = function (app) {
      sessionStorage.setItem(slug + ":category-software-filter", app);
      ctrl.currentFilter = app;
      ctrl.onUpdate({ value: app });

      // Scroll top after updating filters
      var header = $(".header"),
        trigger = $(".page__trigger"),
        filters = $(".page__filters--sticky");

      if (filters.hasClass("scrolled"))
        window.scroll(0, trigger.offset().top - header.outerHeight());
    };

    // Set class name for active selection
    ctrl.isActive = function (app) {
      return app === ctrl.currentFilter ? "active" : "";
    };

    // Get active slug
    ctrl.isActiveSlug = function () {
      return ctrl.softwareFilters.find(
        (filter) => filter.name === ctrl.currentFilter
      ).slug;
    };
  },

  bindings: {
    categoryName: "<",
    showApps: "<?",
    limitApps: "<?",
    onUpdate: "&",
  },
});
