angular.module("UI8.directives").directive("ui8NoSpecialChars", [
  function () {
    return {
      restrict: "A",
      link: function (scope, element) {
        element.bind("input", function () {
          var regex = /[^a-zA-Z0-9 ]/g;
          var value = element.val();
          if (regex.test(value)) {
            element.val(value.replace(regex, ""));
          }
        });
      },
    };
  },
]);
