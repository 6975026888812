angular.module("UI8.directives").directive("ui8FollowButton", [
  "$http",
  function ($http) {
    // Executed Per Each Instance
    function link(scope, elem, attrs) {
      var user = attrs.ui8FollowButton,
        defaultClass = attrs.ui8DefaultClass || "btn",
        positiveClass = attrs.ui8PositiveClass || "btn--stroke",
        isFavorited,
        requesting,
        initialState = 0;

      scope.$on("$destroy", function () {
        elem.off();
      });

      var positiveIcon =
        '<svg><path d="M17.113 15.21l.094.083L19 17.085l1.793-1.792a1 1 0 0 1 1.497 1.32l-.083.094-1.792 1.793 1.792 1.793a1 1 0 0 1-1.32 1.497l-.094-.083L19 19.915l-1.793 1.792a1 1 0 0 1-1.497-1.32l.083-.094 1.792-1.793-1.792-1.793a1 1 0 0 1 1.32-1.497zM12 14.5a1 1 0 0 1 .117 1.993L12 16.5l-5.174.002-.557.007-.414.014-.213.015-.175.019-.147.024-.129.03-.061.018a3 3 0 0 0-2 2l-.034.124-.027.137-.012.076-.019.175-.02.338-.012.458-.005.613L3 21a1 1 0 0 1-1.993.117L1 21l.004-.971.012-.575.014-.309.02-.264.013-.118.032-.217.041-.201.024-.098.056-.198a5 5 0 0 1 3.333-3.333l.1-.029.196-.05.201-.041.217-.032.245-.024.285-.017.336-.011.626-.009L12 14.5zM10 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm0 2a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z"/></svg>';
      var negativeIcon =
        '<svg><path d="M12 14.5a1 1 0 0 1 .117 1.993L12 16.5l-5.174.002-.557.007-.414.014-.213.015-.175.019-.147.024-.129.03-.061.018a3 3 0 0 0-2 2l-.034.124-.027.137-.012.076-.019.175-.02.338-.012.458-.005.613L3 21a1 1 0 0 1-1.993.117L1 21l.004-.971.012-.575.014-.309.02-.264.013-.118.032-.217.041-.201.024-.098.056-.198a5 5 0 0 1 3.333-3.333l.1-.029.196-.05.201-.041.217-.032.245-.024.285-.017.336-.011.626-.009L12 14.5zm7-.5a1 1 0 0 1 .993.883L20 15v2h2a1 1 0 0 1 .117 1.993L22 19h-2v2a1 1 0 0 1-1.993.117L18 21v-2h-2a1 1 0 0 1-.117-1.993L16 17h2v-2a1 1 0 0 1 1-1zM10 2a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm0 2a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z"/></svg>';

      function getButtonText(isFavorited, positive) {
        if (!positive) {
          return attrs.negativeText || "Unfollow" + positiveIcon;
        }

        return (
          (isFavorited
            ? attrs.positiveText || "Following"
            : attrs.defaultText || "Follow") +
          (isFavorited
            ? attrs.positiveText || positiveIcon
            : attrs.defaultText || negativeIcon)
        );
      }

      // Update button state
      function updateButton(isFavorited) {
        isFavorited
          ? elem.addClass(positiveClass)
          : defaultClass !== positiveClass && elem.removeClass(positiveClass);
        return elem.html(getButtonText(isFavorited, true));
      }

      // Initial State
      $http
        .get("/account/favorite/" + user, {
          params: {
            json: "true",
            type: "user",
          },
          cache: true,
        })
        .then(function (res) {
          initialState = !!res.data.favorited;
          isFavorited = !!res.data.favorited;
          return updateButton(isFavorited, true);
        });

      // Click Bindings
      elem
        .addClass("btn--follow")
        .on("click", function (e) {
          // Prevent triple-double-clicks
          if (requesting || isFavorited === undefined) return;

          e.preventDefault();

          requesting = true;

          // Toggle Favorite Value
          $http[isFavorited ? "delete" : "put"](
            "/account/favorite/" + user + "?json=1&type=user"
          )
            .then(function () {
              requesting = false;

              isFavorited = !isFavorited;

              scope.offset =
                initialState > 0 ? (isFavorited ? 0 : -1) : isFavorited ? 1 : 0;

              return updateButton(isFavorited);
            })
            .catch(function () {
              requesting = false;
            });
        })
        .on("mouseover", function () {
          if (!isFavorited || elem.hovered) return;

          elem.hovered = true;

          return elem.html(getButtonText(isFavorited, false));
        })
        .on("mouseout", function () {
          elem.hovered = false;
          return updateButton(isFavorited);
        });
    }

    return {
      link: link,
      restrict: "A",
    };
  },
]);
