angular.module("UI8.components").component("tagFilter", {
  template:
    "<div class='filters__btns' on-load='$ctrl.changeHandler()'>" +
    "  <button class='filters__btn' ng-class='$ctrl.isActive(tag.value)' ng-click='$ctrl.updateFilter()'>All products</button>" +
    "  <button class='filters__btn' ng-class='$ctrl.isActive(tag.value)' ng-if='tag' ng-click='$ctrl.updateFilter(tag.value)' ng-repeat='tag in ::$ctrl.filters track by tag.value'>" +
    "    <span data-tag-id='{{ ::tag.value }}'>{{ ::$ctrl.capitalize(tag.text) }}</span>" +
    "  </button>" +
    "</div>",

  controller: function () {
    var ctrl = this;
    var slug;

    ctrl.changeHandler = function () {
      slug = location.pathname.split("/").pop();
      var filter = sessionStorage.getItem(slug + ":tag-filter");
      ctrl.updateFilter(filter ? filter : null);
      document.body.addEventListener("reset-category-filters", resetListener);
    };

    ctrl.$onInit = function () {
      if (
        /sale/i.test(ctrl.categoryName) ||
        /trending/i.test(ctrl.categoryName)
      ) {
        ctrl.categoryName = "Products";
      }
    };

    ctrl.$onDestroy = function () {
      document.body.removeEventListener(
        "reset-category-filters",
        resetListener
      );
    };

    // Set Active Selection
    ctrl.updateFilter = function (tagId) {
      tagId = tagId || null;
      if (tagId) sessionStorage.setItem(slug + ":tag-filter", tagId);
      else sessionStorage.removeItem(slug + ":tag-filter");
      ctrl.currentFilter = tagId;
      ctrl.onUpdate({ value: tagId });

      // Scroll top after updating filters
      var header = $(".header"),
        trigger = $(".page__trigger"),
        filters = $(".page__filters--sticky");

      if (filters.hasClass("scrolled"))
        window.scroll(0, trigger.offset().top - header.outerHeight());
    };

    // Set class name for active selection
    ctrl.isActive = function (tagId) {
      if (!ctrl.currentFilter && !tagId) return "active";
      return tagId === ctrl.currentFilter ? "active" : "";
    };

    // Capitalize tag names
    ctrl.capitalize = function (text) {
      return text
        .split(" ")
        .map(function (word) {
          if (/ios/i.test(word)) return "iOS";
          if (/iphone/i.test(word)) return "iPhone";
          if (/ipad/i.test(word)) return "iPad";
          if (/imac/i.test(word)) return "iMac";
          if (/macbook/i.test(word)) return "MacBook";
          if (/html/i.test(word)) return "HTML";
          if (/ai/i.test(word)) return "AI";
          return word.substring(0, 1).toUpperCase() + word.substring(1);
        })
        .join(" ");
    };

    function resetListener() {
      if (ctrl.currentFilter) ctrl.updateFilter();
    }
  },

  bindings: {
    filters: "<",
    categoryName: "<",
    onUpdate: "&",
  },
});
