/**
 * UI8 - Control header interactions
 */
angular.module("UI8.directives").directive("ui8SmartHeader", [
  "$window",
  function ($window) {
    return {
      restrict: "A",
      link: function (scope) {
        var $win = angular.element($window),
          $header = $(".header");

        function scrollHandler() {
          var winTop = $win.scrollTop();

          if (winTop >= 24) {
            $header.addClass("scrolled");
          } else {
            $header.removeClass("scrolled");
          }
        }

        // Listen for scroll events
        $win.on("scroll resize", scrollHandler);

        scope.$on("$destroy", function () {
          $win.off("scroll resize", scrollHandler);
        });
      },
    };
  },
]);
