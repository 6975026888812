angular.module("UI8.controllers").controller("NotificationsController", [
  "$http",
  "$scope",
  function ($http, $scope) {
    var unread, read, currentPage;

    $scope.read = markRead;
    $scope.notifications = [];
    $scope.disabled = false;
    // $scope.container = $(".header__dropdown--notifications");
    $scope.next = loadMore;
    $scope.loading = false;

    currentPage = 1;

    unread = function (notification) {
      return !!notification.unread;
    };

    function fetchNotifications(page) {
      page = page || 1;

      return $http
        .get("/account/notifications?json=1&limit=50&page=" + page)
        .then(function (res) {
          res = res.data || [];
          $scope.notifications = $scope.notifications.concat(res);
          $scope.unread = res.filter(unread).length;
          $scope.loading = false;
          $scope.disabled = false;
        })
        .catch(function () {
          $scope.loading = false;
          $scope.disabled = false;
        });
    }

    function loadMore() {
      if ($scope.notifications.length >= 50 && !$scope.disabled) {
        $scope.loading = true;
        $scope.disabled = true;
        currentPage += 1;
        fetchNotifications(currentPage);
      }
    }

    function markRead() {
      if (read) return;

      read = true;

      return $http.get("/account/notifications/read?json=1").then(function () {
        $scope.unread = false;
      });
    }

    fetchNotifications(currentPage);
  },
]);
