angular.module("UI8.components").component("passModal", {
  template: `<div class="modal modal--pass">
      <button class="modal__close" ui8-toggle-class="pass-pricing-modal" ui8-track-event="Close Pass Modal"></button>
      <div class="modal__container">
        <div class="modal__head">
          <div class="modal__title">All-Access Pass</div>
          <div class="modal__description">Unlock instant access to all existing products and daily new releases.</div>
        </div>
        <pass-pricing subscriptions="::$ctrl.subscriptions" lifetime="::$ctrl.lifetime" promo="::$ctrl.promo" close-modal="true"></pass-pricing>
      </div>
      <div class="modal__backdrop" ui8-toggle-class="pass-pricing-modal" ui8-track-event="Close Pass Modal"></div>
    </div>`,

  controller: function () {
    // console.log("passModal");
    var ctrl = this;

    ctrl.$onInit = function () {
      // console.log("passModal init");
      if (!ctrl.subscriptions) ctrl.subscriptions = UI8.subscriptions;
      if (!ctrl.lifetime) ctrl.lifetime = UI8.lifetime;
      if (!ctrl.promo) ctrl.promo = UI8.promo;
      if (!ctrl.renew) ctrl.renew = UI8.renew;
    };
  },

  bindings: {
    subscriptions: "<?",
    lifetime: "<?",
    promo: "<?",
  },
});
