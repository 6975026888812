angular.module("UI8.services").service("HistoryService", [
  "$rootScope",
  function ($rootScope) {
    this.rememberScrollPosition = function (checkFn, pageKey, getTopPosition) {
      $rootScope.$on("$locationChangeStart", function (event, newUrl, oldUrl) {
        var newPath = new URL(newUrl).pathname;
        var oldPath = new URL(oldUrl).pathname;

        if (checkFn(oldPath, oldUrl) && newPath.includes("/products/")) {
          const topPosition = getTopPosition
            ? getTopPosition()
            : window.scrollY;
          sessionStorage.setItem("top:" + pageKey, topPosition);
        }
      });
    };
  },
]);
