angular.module("UI8.components").component("downloadModal", {
  template: `<div class="modal modal--download open" ng-if="$ctrl.display" ng-click="$ctrl.closeModal()">
      <button class="modal__close" ng-click="$ctrl.closeModal()"></button>
      <div class="modal__container">
        <div class="download">
          <div class="download__preview">
            <img ng-src="{{ $ctrl.product.card_image }}" alt="Preview" />
          </div>
          <ul class="download__buttons" ng-if="$ctrl.product.files.length && !$ctrl.showVerification" onclick="event.stopPropagation()">
            <li ng-repeat='file in $ctrl.product.files track by $index'>
              <button class="btn btn--submit" ng-click="$ctrl.initDownload($index)">
                <span>{{ file.name }}</span>
                <span ng-if="file.size">{{ $ctrl.convertToMb(file.size) }}</span>
                <svg><path fill-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 6a1 1 0 0 1 1 1v5.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L11 13.586V8a1 1 0 0 1 1-1z"/></svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal__backdrop"></div>
    </div>
    <verification-modal display='$ctrl.showVerification'></verification-modal>`,

  controller: function (DownloadService, FlashService, PushService) {
    var ctrl = this;

    ctrl.closeModal = closeModal;
    ctrl.showVerification = false;

    ctrl.convertToMb = function (bytes) {
      return `(${(bytes / (1024 * 1024)).toFixed(2)}mb)`;
    };

    ctrl.initDownload = function (fileIndex, dropbox) {
      DownloadService.get(ctrl.product, fileIndex, dropbox).then(
        function (result) {
          if (dropbox && result) ctrl.dropboxClass = result;
        },
        function (err) {
          console.log("error:", err);
          if (/New device/.test(err)) {
            ctrl.display = true;
            ctrl.showVerification = true;
          } else {
            return FlashService.set("error", err);
          }
        }
      );
    };

    function closeModal() {
      ctrl.display = false;
      initPushNotificationHandler();
    }

    function initPushNotificationHandler() {
      if (!("serviceWorker" in navigator)) return;
      if (!("PushManager" in window)) return;

      PushService.setUpPush()
        .then(function (subscription) {
          if (!subscription) {
            return PushService.subscribe();
          }
        })
        .catch(function () {});
    }
  },

  bindings: {
    product: "<?",
    display: "=?",
  },
});
