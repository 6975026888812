/**
 * Dynamically serve retina/non-retina images from imagekit
 * @author Bill Broughton <bill@ui8.net>
 */
angular.module("UI8.directives").directive("ui8Image", [
  "$timeout",
  "$window",
  function ($timeout) {
    return {
      restrict: "A",
      link: function (_, elem, attrs) {
        attrs.$observe("ui8Image", function (value) {
          buildImage(value);
        });

        $timeout(function () {
          buildImage();
        }, 0);

        function buildImage(img) {
          // Wait for angular scope
          // Directive attributes
          var image = img || attrs.ui8Image;
          var isCSS = attrs.ui8ImageSrc ? false : true;
          var srcSet = false;

          if (attrs.ui8ResizeTo) {
            const match = image.match(/(http(?:s?):\/\/images\.ui8\.net)(.*)/);

            if (match) {
              const resizeTo = attrs.ui8ResizeTo.split(";");
              srcSet = resizeTo.length > 1;
              image = resizeTo.map((resizeTo) => {
                return match[1] + "/cdn-cgi/image/" + resizeTo + match[2];
              });
            }
          } else {
            image = [image];
          }

          if (srcSet) {
            if (isCSS) {
              const imageSet = image
                .map((url, idx) => {
                  return "url(" + url + ") " + (idx + 1) + "x";
                })
                .join(", ");
              // Update css
              elem.css({ "background-image": "image-set(" + imageSet + ")" });
            } else {
              // Update src
              elem.attr(
                "srcset",
                image
                  .map((url, idx) => {
                    return url + (idx + 1) + "x";
                  })
                  .join(", ")
              );
            }
          } else {
            image = image[0];
            if (isCSS) {
              // Update css
              elem.css({ "background-image": "url('" + image + "')" });
            } else {
              // Update src
              elem.attr("src", image);
            }
          }
        }
      },
    };
  },
]);
