angular.module("UI8.components").component("passDetails", {
  template:
    `<div class="aap">
      <div class="aap__container container">
        <div class="aap__head">
          <div class="aap__cell">
              <h1 class="aap__title">All-Access Pass</h1>
              <p class="aap__text">Boost your productivity with instant access to all {{ ::$ctrl.data.total_products | ui8FormatNumber }} existing products and daily new releases.</p>
              <img class="aap-image aap-image--right" srcset="/img/aap/hero-left@2x.png 2x" src="/img/aap/hero-left.png" alt="Hero Left">
          </div>
          <div class="aap__cell aap__cell--preview">
            <img srcset="/img/aap/hero@2x.jpg 2x" src="/img/aap/hero.jpg" alt="Hero">
          </div>
        </div>
        <pass-pricing subscriptions='::$ctrl.data.subscriptions' lifetime='::$ctrl.data.lifetime' promo='::$ctrl.data.promo'></pass-pricing>
        <div class="feat">
          <div class="feat__item">
            <div class="feat__preview">
              <img srcset="/img/aap/feature-1@2x.jpg 2x" src="/img/aap/feature-1.jpg" loading="lazy" alt="Feature 1">
            </div>
            <div class="feat__inner">
              <div class="feat__label">Maximum value</div>
              <div class="feat__title">Unlock {{ ::$ctrl.data.total_savings | ui8Currency }} worth of assets</div>
              <div class="feat__text">Save time, skip the search, and get instant access to everything you need. No more compromises on quality or budget. This is your fast track to creating more, better, and faster.</div>
              <img class="aap-image aap-image--left" srcset="/img/aap/feature-1-right@2x.png 2x" src="/img/aap/feature-1-right.png" loading="lazy" alt="Feature 1 Right">
              <img class="aap-image aap-image--right-bottom-large" srcset="/img/aap/feature-2-top@2x.png 2x" src="/img/aap/feature-2-top.png" loading="lazy" alt="Feature 2 Top">
            </div>
          </div>
          <div class="feat__item">
            <div class="feat__preview">
              <img srcset="/img/aap/feature-2@2x.jpg 2x" src="/img/aap/feature-2.jpg" loading="lazy" alt="Feature 2">
            </div>
            <div class="feat__inner">
              <div class="feat__label">What’s included</div>
              <div class="feat__title">{{ ::$ctrl.data.total_products | ui8FormatNumber }} products and counting</div>
              <div class="feat__text">Your search for the ultimate resource library ends here. With our vast collection of premium resources, you'll have everything you need to super-charge your projects and stay ahead of the game.</div>
              <img class="aap-image aap-image--top-left-large" srcset="/img/aap/feature-1-bottom@2x.png 2x" src="/img/aap/feature-1-bottom.png" loading="lazy" alt="Feature 1 Bottom">
              <img class="aap-image aap-image--right" srcset="/img/aap/feature-2-left@2x.png 2x" src="/img/aap/feature-2-left.png" loading="lazy" alt="Feature 2 Left">
              <img class="aap-image aap-image--bottom-left-large" srcset="/img/aap/feature-3-top@2x.png 2x" src="/img/aap/feature-3-top.png" loading="lazy" alt="Feature 3 Top">
            </div>
          </div>
          <div class="feat__item">
            <div class="feat__preview">
              <img srcset="/img/aap/feature-3@2x.jpg 2x" src="/img/aap/feature-3.jpg" loading="lazy" alt="Feature 3">
            </div>
            <div class="feat__inner">
              <div class="feat__label">Daily updates</div>
              <div class="feat__title">New products curated daily</div>
              <div class="feat__text">Fresh design assets, every day. Stay ahead with new, high-quality additions to keep your projects sharp and on-trend. It’s like having a daily source of creative inspiration!</div>
              <img class="aap-image aap-image--left" srcset="/img/aap/feature-3-right@2x.png 2x" src="/img/aap/feature-3-right.png" loading="lazy" alt="Feature 3 Right">
              <img class="aap-image aap-image--top-right-large" srcset="/img/aap/feature-2-bottom@2x.png 2x" src="/img/aap/feature-2-bottom.png" loading="lazy" alt="Feature 2 Bottom">
            </div>
          </div>
        </div>
      </div>` +
    // <div class="products">
    //   <div class="products__group">
    //     <div class="products__list">
    //       <a href="/brandkit/products/resume--portfolio-kit" target="_blank">
    //         <img srcset="/img/products/product-1@2x.jpg 2x" src="/img/products/product-1.jpg" loading="lazy" alt="Product 1">
    //       </a>
    //       <a href="/fisga-studio/products/akio-framer-portfolio-template" target="_blank">
    //         <img srcset="/img/products/product-2@2x.jpg 2x" src="/img/products/product-2.jpg" loading="lazy" alt="Product 2">
    //       </a>
    //       <a href="/shydesign/products/fashi-ux--wireframe-kit" target="_blank">
    //         <img srcset="/img/products/product-3@2x.jpg 2x" src="/img/products/product-3.jpg" loading="lazy" alt="Product 3">
    //       </a>
    //       <a href="/templify/products/chromatic-chill-volume-two--texture-background-pack" target="_blank">
    //         <img srcset="/img/products/product-4@2x.jpg 2x" src="/img/products/product-4.jpg" loading="lazy" alt="Product 4">
    //       </a>
    //       <a href="/limitype/products/wosker" target="_blank">
    //         <img srcset="/img/products/product-5@2x.jpg 2x" src="/img/products/product-5.jpg" loading="lazy" alt="Product 5">
    //       </a>
    //       <a href="/brandkit/products/brand-guidelines-kit" target="_blank">
    //         <img srcset="/img/products/product-6@2x.jpg 2x" src="/img/products/product-6.jpg" loading="lazy" alt="Product 6">
    //       </a>
    //       <a href="/impro-studio/products/resix---clean-style-branding-mockup-bundle" target="_blank">
    //         <img srcset="/img/products/product-7@2x.jpg 2x" src="/img/products/product-7.jpg" loading="lazy" alt="Product 7">
    //       </a>
    //       <a href="/madebysafwan-ab575a/products/freelancer-dilemma-3d-illustration" target="_blank">
    //         <img srcset="/img/products/product-8@2x.jpg 2x" src="/img/products/product-8.jpg" loading="lazy" alt="Product 8">
    //       </a>
    //       <a href="/mrayan-design/products/hands-gestures---drawn-icons" target="_blank">
    //         <img srcset="/img/products/product-9@2x.jpg 2x" src="/img/products/product-9.jpg" loading="lazy" alt="Product 9">
    //       </a>
    //       <a href="/ui8/products/brainwave-ai-landing-page-kit" target="_blank">
    //         <img srcset="/img/products/product-10@2x.jpg 2x" src="/img/products/product-10.jpg" loading="lazy" alt="Product 10">
    //       </a>
    //     </div>
    //   </div>
    //   <div class="products__group">
    //     <div class="products__list">
    //       <a href="/ui8/products/neutrade--crypto-ai-ui-kit" target="_blank">
    //         <img srcset="/img/products/product-11@2x.jpg 2x" src="/img/products/product-11.jpg" loading="lazy" alt="Product 11">
    //       </a>
    //       <a href="/andrea-montini/products/hirevision-framer-template" target="_blank">
    //         <img srcset="/img/products/product-12@2x.jpg 2x" src="/img/products/product-12.jpg" loading="lazy" alt="Product 12">
    //       </a>
    //       <a href="/ui8/products/stack-ultimate-ui-design-system" target="_blank">
    //         <img srcset="/img/products/product-13@2x.jpg 2x" src="/img/products/product-13.jpg" loading="lazy" alt="Product 13">
    //       </a>
    //       <a href="/growwwkit/products/familiar-bunch---illustration-pack" target="_blank">
    //         <img srcset="/img/products/product-14@2x.jpg 2x" src="/img/products/product-14.jpg" loading="lazy" alt="Product 14">
    //       </a>
    //       <a href="/creative-loot-a470d1/products/scoop-display-font" target="_blank">
    //         <img srcset="/img/products/product-15@2x.jpg 2x" src="/img/products/product-15.jpg" loading="lazy" alt="Product 15">
    //       </a>
    //       <a href="/asylab/products/brand-guidelines-template---radesk" target="_blank">
    //         <img srcset="/img/products/product-16@2x.jpg 2x" src="/img/products/product-16.jpg" loading="lazy" alt="Product 16">
    //       </a>
    //       <a href="/becreativetemplates/products/laptop-office-desk-mockup" target="_blank">
    //         <img srcset="/img/products/product-17@2x.jpg 2x" src="/img/products/product-17.jpg" loading="lazy" alt="Product 17">
    //       </a>
    //       <a href="/illustraly/products/facely-v2---metapeople-3d-avatar" target="_blank">
    //         <img srcset="/img/products/product-18@2x.jpg 2x" src="/img/products/product-18.jpg" loading="lazy" alt="Product 18">
    //       </a>
    //       <a href="/darkwing/products/vivid-emojis-icons-pack" target="_blank">
    //         <img srcset="/img/products/product-19@2x.jpg 2x" src="/img/products/product-19.jpg" loading="lazy" alt="Product 19">
    //       </a>
    //       <a href="/brandkit/products/hero-header-kit" target="_blank">
    //         <img srcset="/img/products/product-20@2x.jpg 2x" src="/img/products/product-20.jpg" loading="lazy" alt="Product 20">
    //       </a>
    //     </div>
    //   </div>
    //   <div class="products__group">
    //     <div class="products__list">
    //       <a href="/jenish-v/products/teasy-todo-tasklist--calendar-app-ui-kit" target="_blank">
    //         <img srcset="/img/products/product-21@2x.jpg 2x" src="/img/products/product-21.jpg" loading="lazy" alt="Product 21">
    //       </a>
    //       <a href="/ui8/products/brainwave-ai-ui-design-kit" target="_blank">
    //         <img srcset="/img/products/product-22@2x.jpg 2x" src="/img/products/product-22.jpg" loading="lazy" alt="Product 22">
    //       </a>
    //       <a href="/filllo-design-agency/products/filllo-web-wireframe-ui-kit" target="_blank">
    //         <img srcset="/img/products/product-23@2x.jpg 2x" src="/img/products/product-23.jpg" loading="lazy" alt="Product 23">
    //       </a>
    //       <a href="/bakeet/products/do-the-shopping" target="_blank">
    //         <img srcset="/img/products/product-24@2x.jpg 2x" src="/img/products/product-24.jpg" loading="lazy" alt="Product 24">
    //       </a>
    //       <a href="/dharmas-studio/products/kirgina---ui-condensed-font" target="_blank">
    //         <img srcset="/img/products/product-25@2x.jpg 2x" src="/img/products/product-25.jpg" loading="lazy" alt="Product 25">
    //       </a>
    //       <a href="/eyedrop/products/useful-or-useless-presentation-deck" target="_blank">
    //         <img srcset="/img/products/product-26@2x.jpg 2x" src="/img/products/product-26.jpg" loading="lazy" alt="Product 26">
    //       </a>
    //       <a href="/creatoom-880b5b/products/iphone-14-pro-mockup-on-stone-panel-v4-isometric" target="_blank">
    //         <img srcset="/img/products/product-27@2x.jpg 2x" src="/img/products/product-27.jpg" loading="lazy" alt="Product 27">
    //       </a>
    //       <a href="/wannathis/products/3d-emoji" target="_blank">
    //         <img srcset="/img/products/product-28@2x.jpg 2x" src="/img/products/product-28.jpg" loading="lazy" alt="Product 28">
    //       </a>
    //       <a href="/interfase-design-bec5c7/products/break-symbols" target="_blank">
    //         <img srcset="/img/products/product-29@2x.jpg 2x" src="/img/products/product-29.jpg" loading="lazy" alt="Product 29">
    //       </a>
    //       <a href="/powerviz/products/algenius--ai-services-website" target="_blank">
    //         <img srcset="/img/products/product-30@2x.jpg 2x" src="/img/products/product-30.jpg" loading="lazy" alt="Product 30">
    //       </a>
    //     </div>
    //   </div>
    // </div>
    `<div class="aap__container container">
        <div class="aap__row">
          <div class="aap__col">
            <div class="aap__label">FAQ</div>
            <h2 class="aap__subtitle">Everything you need to know</h2>
            <ul class="aap__faq">
              <li class="aap__accordion">
                <div class="aap__question">What is the All-Access Pass?</div>
                <div class="aap__answer">The All-Access pass is a subscription based membership offered in different tiers, giving you access to download any product on the platform as well as all future releases.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">How often do you release new products?</div>
                <div class="aap__answer">We curate new products on a daily basis. The amount of approved daily releases will vary as our primary focus is quality over quantity.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">How can I cancel my subscription?</div>
                <div class="aap__answer">You can cancel your subscription at any time from your purchases page and you may continue downloading products until your subscription expires.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">Do I have access to all products?</div>
                <div class="aap__answer">Yes, you can download any product offered in the platform for as long as your subscription is active.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">Can I redownload products?</div>
                <div class="aap__answer">You can redownload products as long as you have an active subscription. Downloading the same product within 24 hours will not consume additional download credits.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">What happens when I reach my daily download limit?</div>
                <div class="aap__answer">You must wait 24 hours for your downloads to recharge after you’ve exceeded your daily allocated limit. However, upgrading to a higher tier will instantly reset your download credits.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">How does licensing work after the subscription is canceled or expired?</div>
                <div class="aap__answer">You have permanent license to use any downloaded products even after your subscription is canceled or expired. However, you must have an active subscription if you wish to redownload products.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">Can multiple users download with the All-Access pass?</div>
                <div class="aap__answer">The All-Access pass is meant to be for a single user. Account sharing is monitored and strictly prohibited. If you have a team and have multiple members needing access please contact support for assistance.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">Do you offer plans for teams?</div>
                <div class="aap__answer">We can manually create All-Access Pass accounts for teams of up to 20 members. If you have a team, please contact support for assistance.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">Do you offer any discounts?</div>
                <div class="aap__answer">We offer a 15% renewal discount on the Basic, Pro and Elite All-Access Pass tiers.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">How to I enter my VAT/TAX information?</div>
                <div class="aap__answer">You can customize and download your invoices with the appropriate tax information immediately after purchase.</div>
              </li>
              <li class="aap__accordion">
                <div class="aap__question">What is your refund policy?</div>
                <div class="aap__answer">All transactions are final and we do not offer refunds on All-Access Pass purchases.</div>
              </li>
            </ul>
            <div class="aap__hint">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M9.09 9a3 3 0 0 1 3.337-1.961A3 3 0 0 1 14.92 10c0 2-3 3-3 3m.08 4h.01M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z" stroke="#adb7be" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
              <p>Couldn’t find what you were looking for? <a onclick="Intercom('show')">Contact us</a></p>
            </div>
            <img class="aap-image aap-image--top-left-medium" srcset="/img/aap/feature-3-bottom@2x.png 2x" src="/img/aap/feature-3-bottom.png" loading="lazy" alt="Feature 3 Bottom">
          </div>
          <div class="aap__col">
            <div class="libs">
              <ul class="libs__list">
                <li class="libs__item">
                  <a href="/category/ui-kits" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-1-1" cx="28" cy="28" r="28"></circle></defs><g fill-rule="evenodd"><mask id="lib-1-2" fill="#fff"><use xlink:href="#icon-1-1"></use></mask><g fill-rule="nonzero"><use xlink:href="#icon-1-1" fill="#2d68ff"></use><path fill="#1b48ba" mask="url(#icon-1-2)" d="M0 0h28v56H0z"></path><path fill="#dce3f4" d="M20 46a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3H20z" mask="url(#icon-1-2)"></path><path fill="#6b84c5" d="M20 46a3 3 0 0 1-3-3v-5h22v5a3 3 0 0 1-3 3H20z" mask="url(#icon-1-2)"></path><circle cx="28" cy="42" r="2" fill="#171c24" mask="url(#icon-1-2)"></circle><path fill="#1b48ba" d="M26.354 19.08a3 3 0 0 1 3.292 0l3 1.969A3 3 0 0 1 34 23.557v2.886a3 3 0 0 1-1.354 2.508l-3 1.969a3 3 0 0 1-3.292 0l-3-1.969A3 3 0 0 1 22 26.443v-2.886a3 3 0 0 1 1.354-2.508l3-1.969z" mask="url(#icon-1-2)"></path></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["ui-kits"] | ui8FormatNumber }}</div>
                    <div class="libs__title">UI Kits</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/illustrations" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-4-1" cx="28" cy="28" r="28"></circle></defs><g fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="lib-4-2" fill="#fff"><use xlink:href="#icon-4-1"></use></mask><g fill-rule="nonzero"><path fill="#1b48ba" mask="url(#icon-4-2)" d="M7.7 7.7L28-12.6 48.3 7.7 28 28z"></path><path fill="#6b84c5" mask="url(#icon-4-2)" d="M23 57h10V43H23z"></path><path fill="#1b48ba" mask="url(#icon-4-2)" d="M33 57h10V43H33z"></path><g fill="#dce3f4"><path mask="url(#icon-4-2)" d="M13 57h10V43H13z"></path><path mask="url(#icon-4-2)" d="M13 43h30L28 28z"></path></g><path fill="#171c24" mask="url(#icon-4-2)" d="M19.995 36h16.014L28 28z"></path></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["illustrations"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Illustration Sets</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/icons" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-9-1" cx="28" cy="28" r="28"></circle></defs><g fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="lib-9-2" fill="#fff"><use xlink:href="#icon-9-1"></use></mask><path fill="#1b48ba" fill-rule="nonzero" mask="url(#icon-9-2)" d="M28 42l21 21-21 21L7 63z"></path><path fill="#171c24" d="M30.431 15.878l.032-.032a8.27 8.27 0 0 1 11.691 0 8.27 8.27 0 0 1 0 11.691l-2.421 2.485L28 18.311l2.431-2.433z"></path><path fill="#6b84c5" d="M25.569 15.878l-.032-.032a8.27 8.27 0 0 0-11.691 0 8.27 8.27 0 0 0 0 11.691l2.465 2.485 11.732-11.711-2.473-2.433z"></path><path fill="#dce3f4" fill-rule="nonzero" d="M16.311 30.002l11.691-11.691 11.732 11.732-11.691 11.691z"></path></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["icons"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Icon Sets</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/mockups" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><circle cx="28" cy="28" r="28" fill="#2d68ff"></circle><path fill="#171c24" d="M28 7l18.187 10.5v21L29.5 48.134a3 3 0 0 1-3 0L9.813 38.5v-21L28 7z"></path><path fill="#1b48ba" d="M28 28l18.186 10.5L29.5 48.134a3 3 0 0 1-3 0L9.813 38.5 28 28z"></path><path fill="#6b84c5" d="M28 17.5L46.186 28 29.5 37.634a3 3 0 0 1-3 0L9.813 28 28 17.5z"></path><path fill="#dce3f4" d="M28 7l18.186 10.5L29.5 27.134a3 3 0 0 1-3 0L9.813 17.5 28 7z"></path></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["mockups"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Mockups</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/wireframe-kits" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><rect id="lib-3-1" width="56" height="56" rx="28"></rect></defs><g fill-rule="evenodd"><mask id="lib-3-2" fill="#fff"><use xlink:href="#icon-3-1"></use></mask><g fill-rule="nonzero"><use xlink:href="#icon-3-1" fill="#2d68ff"></use><path fill="#1b48ba" mask="url(#icon-3-2)" d="M0 0h28v56H0z"></path><path fill="#dce3f4" d="M15 44a3 3 0 0 1-3-3V15a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3H15z" mask="url(#icon-3-2)"></path><path fill="#1b48ba" d="M21 29a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3H21z" mask="url(#icon-3-2)"></path><path fill="#6b84c4" d="M20 37a2 2 0 1 1 0-4h16a2 2 0 1 1 0 4H20z" mask="url(#icon-3-2)"></path><path mask="url(#icon-3-2)" d="M28 6l4 6h-8zm22 22l-6 4v-8zM28 50l-4-6h8zM6 28l6-4v8z" fill="#171c24"></path></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["wireframe-kits"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Wireframe Kits</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/fonts" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-5-1" cx="28" cy="28" r="28"></circle><path id="lib-5-2" d="M18 28c5.523 0 10-4.477 10-10S23.523 8 18 8 8 12.477 8 18s4.477 10 10 10zm0 8c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z"></path></defs><g fill="none" fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="C" fill="#fff"><use xlink:href="#icon-5-1"></use></mask><g fill-rule="nonzero"><path fill="#1b48ba" mask="url(#C)" d="M0 0h28v56H0z"></path><path fill="#171c24" d="M38 28h8v18h-8z"></path></g><path fill="#dce3f4" d="M28 38c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm0 8c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z"></path><g transform="translate(10 10)"><mask id="D" fill="#fff"><use xlink:href="#icon-5-2"></use></mask><g fill-rule="nonzero" mask="url(#D)"><g transform="translate(18)"><path fill="#6b84c5" d="M0 0h18v36H0z"></path><path fill="#171c24" d="M0 18h18v18H0z"></path></g></g></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["fonts"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Font Sets</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/coded-templates" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-2-1" cx="28" cy="28" r="28"></circle></defs><g fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="lib-2-2" fill="#fff"><use xlink:href="#icon-2-1"></use></mask><g fill-rule="nonzero"><path fill="#1b48ba" mask="url(#icon-2-2)" d="M0 0h28v56H0z"></path><path d="M3 28l17-17v10l-7 7zm50 0L36 11v10l7 7z" fill="#dce3f4"></path><path d="M36 45l17-17H43l-7 7zm-16 0L3 28h10l7 7z" fill="#6b84c5"></path><path fill="#171c24" d="M21 28l7-7 7 7-7 7z"></path></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["coded-templates"] || 0 | ui8FormatNumber }}</div>
                    <div class="libs__title">Coded Templates</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/3d-assets" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-8-1" cx="28" cy="28" r="28"></circle></defs><g fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="lib-8-2" fill="#fff"><use xlink:href="#icon-8-1"></use></mask><g fill-rule="nonzero"><path fill="#1b48ba" mask="url(#icon-8-2)" d="M46.5 59.75L28 49V28l36 20.75v11z"></path><path fill="#dce3f4" d="M28 7l18.187 10.5v21L28 49 9.813 38.5v-21z"></path><path fill="#171c24" d="M46.187 17.5v21L28 49V28z"></path><path fill="#6b84c5" d="M9.813 17.5L28 28v21L9.813 38.5z"></path></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["3d-assets"] | ui8FormatNumber }}</div>
                    <div class="libs__title">3D Assets</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/presentation" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-6-1" cx="28" cy="28" r="28"></circle><path id="lib-6-2" d="M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18zm0-11a7 7 0 1 0 0-14 7 7 0 1 0 0 14z"></path></defs><g fill="none" fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="lib-6-3" fill="#fff"><use xlink:href="#icon-6-1"></use></mask><path fill="#1b48ba" fill-rule="nonzero" mask="url(#icon-6-3)" d="M0 0h28v56H0z"></path><path fill="#dce3f4" d="M28 46c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18zm0-11a7 7 0 1 0 0-14 7 7 0 1 0 0 14z"></path><g transform="translate(10 10)"><mask id="lib-6-4" fill="#fff"><use xlink:href="#icon-6-2"></use></mask><g fill-rule="nonzero" mask="url(#icon-6-4)"><g transform="translate(-10 -10)"><path fill="#6b84c5" d="M28 28h28v28H28z"></path><path fill="#1b48ba" d="M28 0h28v28H28z"></path><path fill="#6b84c5" d="M0 0h28v28H0z"></path></g></g></g><path fill="#171c24" d="M49 28c0-11.598-9.402-21-21-21v21h21z"></path><path fill="#2d68ff" d="M35 28a7 7 0 1 1-14 0 7 7 0 1 1 14 0z"></path></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["presentation"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Presentation Kits</div>
                  </a>
                </li>
                <li class="libs__item">
                  <a href="/category/themes" class="libs__link">
                    <div class="libs__icon">
                      <svg viewBox="0 0 56 56"><defs><circle id="lib-10-1" cx="28" cy="28" r="28"></circle><path id="lib-10-2" d="M31.5 18a3.5 3.5 0 1 1-7 0 3.5 3.5 0 1 1 7 0z"></path></defs><g fill-rule="evenodd"><circle cx="28" cy="28" r="28" fill="#2d68ff" fill-rule="nonzero"></circle><mask id="C" fill="#fff"><use xlink:href="#icon-10-1"></use></mask><path fill="#1b48ba" fill-rule="nonzero" d="M31.5 34.33c0-1.563 1.317-2.83 2.941-2.83H56.5v25h-25V34.33z" mask="url(#C)"></path><path fill="#dce3f4" d="M45.66 31.5A18.09 18.09 0 0 0 46 28c0-9.941-8.059-18-18-18s-18 8.059-18 18 8.059 18 18 18a18.09 18.09 0 0 0 3.5-.34V34.5a3 3 0 0 1 3-3h11.16z"></path><g fill-rule="nonzero"><use xlink:href="#icon-10-2" fill="#6b84c5"></use><use xlink:href="#icon-10-2" x="-10" y="6.5" fill="#2d68ff"></use><use xlink:href="#icon-10-2" x="-7.5" y="17.5" fill="#171c24"></use></g></g></svg>
                    </div>
                    <div class="libs__counter">{{ $ctrl.data.categoryCounts["themes"] | ui8FormatNumber }}</div>
                    <div class="libs__title">Themes & Templates</div>
                  </a>
                </li>
              </ul>
              <ul class="libs__list libs__list--overlay">
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
                <li class="libs__item"></li>
              </ul>
            </div>
          </div>
        </div>` +
    // <div class="aap__testimonials">
    //   <div class="aap__label">Wall of love</div>
    //   <h2 class="aap__subtitle">Words from our happy creators</h2>
    //   <ul class="testimonials">
    //     <li>
    //       <blockquote>“I absolutely adore this website. It's easy to navigate, and the design assets are top-notch, making it my go-to source for all my projects.”</blockquote>
    //       <img src="img/testimonials/user-1.jpg" loading="lazy" alt="User 1">
    //       <h4>Viktor</h4>
    //     </li>
    //     <li>
    //       <blockquote>“I like that that they actually have a huge library of assets which is great for any designers and it's very easy to sell something.”</blockquote>
    //       <img src="img/testimonials/user-2.jpg" loading="lazy" alt="User 2">
    //       <h4>Vladimir Raksha</h4>
    //     </li>
    //     <li>
    //       <blockquote>“Great platform for buying and getting UI kits and templates. Customer support is especially great. I had an amazing support experience.”</blockquote>
    //       <img src="img/testimonials/user-3.jpg" loading="lazy" alt="User 3">
    //       <h4>Abhishek Gahlot</h4>
    //     </li>
    //     <li>
    //       <blockquote>“UI8 is providing a very useful premium design resources. Thank You.”</blockquote>
    //       <img src="img/testimonials/user-4.jpg" loading="lazy" alt="User 4">
    //       <h4>Pavan Kumar</h4>
    //     </li>
    //     <li>
    //       <blockquote>“Love my access to a wide variety of design resources all in one place, the quality of the assets is exceptional, and the prices are unbeatable.”</blockquote>
    //       <img src="img/testimonials/user-5.jpg" loading="lazy" alt="User 5">
    //       <h4>Margaret B.</h4>
    //     </li>
    //     <li>
    //       <blockquote>“I'm absolutely amazed at how support works. I've faced an issue and to my surprise, I got a solution from them in 30 minutes. It's a record time among my support tickets. Just whoa...”</blockquote>
    //       <img src="img/testimonials/user-6.jpg" loading="lazy" alt="User 6">
    //       <h4>David Grigoryan</h4>
    //     </li>
    //     <li>
    //       <blockquote>“The one-stop shop for all my design needs. I couldn’t be happier with my experience!”</blockquote>
    //       <img src="img/testimonials/user-7.jpg" loading="lazy" alt="User 7">
    //       <h4>Maria R.</h4>
    //     </li>
    //     <li>
    //       <blockquote>“I've been a customer for nearly 10 years and an All-Access Pass subscriber since 2017. The customer support team is incredibly responsive and helpful. I can't highly recommend UI8 enough!”</blockquote>
    //       <img src="img/testimonials/user-8.jpg" loading="lazy" alt="User 8">
    //       <h4>Jason</h4>
    //     </li>
    //     <li>
    //       <blockquote>“Awesome service. I found it quick and easy to purchase great illustrations at a reasonable price. Well done!”</blockquote>
    //       <img src="img/testimonials/user-9.jpg" loading="lazy" alt="User 9">
    //       <h4>Andrew Hill</h4>
    //     </li>
    //     <li>
    //       <blockquote>“I'm a web designer and UI8 has been my go-to for Figma mockups, graphics for my designs, and design inspirations. The bottom line is that the creators in this marketplace are good and of high quality.”</blockquote>
    //       <img src="img/testimonials/user-10.jpg" loading="lazy" alt="User 10">
    //       <h4>Jeffrey Dalrymple</h4>
    //     </li>
    //     <li>
    //       <blockquote>“I've been using ui8 for a couple of years now and the quality of assets is one of the best you can find online.”</blockquote>
    //       <img src="img/testimonials/user-11.jpg" loading="lazy" alt="User 11">
    //       <h4>Adrian Kuleszo</h4>
    //     </li>
    //     <li>
    //       <blockquote>“UI8 is a top-notch design resource with diverse, high-quality assets. User-friendly and budget-friendly, it's a game-changer for both seasoned designers and beginners.”</blockquote>
    //       <img src="img/testimonials/user-12.jpg" loading="lazy" alt="User 12">
    //       <h4>Chenhong Daio</h4>
    //     </li>
    //     <li>
    //       <blockquote>“Excellent customer service and great product.”</blockquote>
    //       <img src="img/testimonials/user-13.jpg" loading="lazy" alt="User 13">
    //       <h4>Adam Gordon</h4>
    //     </li>
    //   </ul>
    // </div>
    `<div class="companies">
          <h3 class="companies__title">Chosen by leading creatives globally</h3>
          <div class="companies__text">UI8 empowers over 700,000 designers from all over the world.</div>
          <div class="companies__carousel">
            <div class="companies__list">
              <picture>
                <img src="/img/companies/adobe.svg" alt="adobe">
              </picture>
              <picture>
                <img src="/img/companies/airbnb.svg" alt="airbnb">
              </picture>
              <picture>
                <img src="/img/companies/amazon.svg" alt="amazon">
              </picture>
              <picture>
                <img src="/img/companies/google.svg" alt="google">
              </picture>
              <picture>
                <img src="/img/companies/microsoft.svg" alt="microsoft">
              </picture>
              <picture>
                <img src="/img/companies/netflix.svg" alt="netflix">
              </picture>
              <picture>
                <img src="/img/companies/paypal.svg" alt="paypal">
              </picture>
              <picture>
                <img src="/img/companies/shopify.svg" alt="shopify">
              </picture>
              <picture>
                <img src="/img/companies/spotify.svg" alt="spotify">
              </picture>
              <picture>
                <img src="/img/companies/stripe.svg" alt="stripe">
              </picture>
              <picture>
                <img src="/img/companies/webflow.svg" alt="webflow">
              </picture>
            </div>
          </div>
          <img class="aap-image aap-image--bottom-left-xxl" srcset="/img/aap/feature-1-top@2x.png 2x" src="/img/aap/feature-1-top.png" loading="lazy" alt="Feature 1 Top">
        </div>
        <div class="boost">
          <img class="boost__cover" src="/img/aap/hero@2x.jpg" loading="lazy" alt="Boost your productivity">
          <div class="boost__inner">
            <h2 class="boost__title">Unlock everything</h2>
            <p class="boost__text">Get instant access, daily updates, and stream your workflow.</p>
            <button class="boost__btn" ui8-toggle-class="pass-pricing-modal">Get All-Access</button>
          </div>
        </div>
      </div>
    </div>`,

  controller: function ($location) {
    // console.log("pass-details");
    var ctrl = this;
    // console.log(ctrl.data);
    var switchers;

    ctrl.$destroy = function () {
      if (switchers) switchers.off();
      $(".aap__question").off();
    };

    ctrl.$onInit = function () {
      // console.log("pass-details init");
      // console.log(ctrl.data);
      window.scrollTo(0, 0);
      if (!ctrl.data.promo && window.UI8.promo) {
        // console.log("Piking up promo from UI8");
        ctrl.data.promo = window.UI8.promo;
      }
      if (!ctrl.data.isRenewal && window.UI8.isRenewal) {
        ctrl.data.isRenewal = window.UI8.isRenewal;
      }
      if (!ctrl.data.lifetime && window.UI8.lifetime) {
        ctrl.data.lifetime = window.UI8.lifetime;
      }
      // TODO: what problem that we're doing this? (Artur)
      if (!ctrl.data.subscriptions && window.UI8.subscriptions) {
        ctrl.data.subscriptions = window.UI8.subscriptions;
      }

      if ($location.search().order) {
        $("html, body").animate(
          {
            scrollTop: $(".pricing-heading").offset().top - 180,
          },
          "fast"
        );
      }

      // faq
      const accordionQuestion = $(".aap__question"),
        accordionAnswer = $(".aap__answer");

      accordionQuestion
        .on("click", function () {
          const _this = $(this);
          if (_this.hasClass("active")) {
            _this.removeClass("active");
            _this.next().slideUp();
          } else {
            accordionQuestion.removeClass("active");
            accordionAnswer.slideUp();
            _this.addClass("active");
            _this.next().slideDown();
          }
        })
        .first()
        .trigger("click");

      // mouse move
      const libs = document.querySelector(".libs");

      const applyOverlayMask = (e) => {
        const x = e.pageX - libs.offsetLeft;
        const y = e.pageY - libs.offsetTop;

        libs.style = `--opacity: 1; --x: ${x}px; --y:${y}px;`;
      };

      document.body.addEventListener("pointermove", applyOverlayMask);

      // clone for circular animations
      const clonedCompaniesList = $(".companies__list").clone();
      $(".companies__carousel").append(clonedCompaniesList);

      $(".products__group").each(function () {
        const clonedProductsList = $(this).find(".products__list").clone();
        $(this).append(clonedProductsList);
      });
    };
  },

  bindings: {
    data: "<",
  },
});
