angular.module("UI8.components").component("category", {
  template:
    '<div class="page">' +
    '  <div class="page__background">' +
    '     <img src="https://images.ui8.net/redesign/{{ $ctrl.backgroundImage }}.jpg" alt="Hero" />' +
    "  </div>" +
    '  <div class="page__container container">' +
    '    <div class="page__hero">' +
    '      <h1 class="page__title">{{ ::$ctrl.categoryName}}{{ ::$ctrl.data.category.name === "Sketch" ? " Resources" : "" }}</h1>' +
    '      <div class="page__description">{{ ::$ctrl.subtitle }}</div>' +
    '      <a href="https://icons8.com/lunacy?ref=ui8" target="_blank" class="page__link" ng-if="$ctrl.data.lunacy">Learn more about Lunacy<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path d="M14 6V2m0 0h-4m4 0L8 8M6.667 2H5.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C2 3.52 2 4.08 2 5.2v5.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C3.52 14 4.08 14 5.2 14h5.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C14 12.48 14 11.92 14 10.8V9.333" stroke="#2d68ff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg></a>' +
    "    </div>" +
    "  </div>" +
    '  <div class="page__trigger"></div>' +
    '  <div class="page__filters page__filters--sticky">' +
    '    <div class="page__container container">' +
    '      <div class="filters">' +
    "        <category-filter category-name='::$ctrl.categoryName' current-sale='::$ctrl.data.current_sale' price-filter='::$ctrl.priceFilter' show-tags='::$ctrl.showTagFilter' filter-tags='$ctrl.data.filterTags' update-tag='$ctrl.updateTag(value)' show-apps='::$ctrl.showAppFilter' filter-apps='$ctrl.showApps' update-software='$ctrl.updateSoftware(value)' show-sort='::$ctrl.showSort' update-sort='$ctrl.updateSort(value)' update-featured='$ctrl.updateFeatured(value)' update-exclusive='$ctrl.updateExclusive(value)' has-featured='$ctrl.hasFeatured' has-exclusives='$ctrl.hasExclusives'></category-filter>" +
    "      </div>" +
    "    </div>" +
    "  </div>" +
    '  <div class="page__container container">' +
    '    <div class="page__catalog catalog">' +
    '      <product-card ng-repeat="product in $ctrl.data.products track by (product._id + $index)" product="::product" user="::$ctrl.user" link-query="\'?browse=\' + $ctrl.data.category._id"></product-card>' +
    "    </div>" +
    '    <div class="page__foot">' +
    '      <button class="btn ng-cloak" ng-if="!$ctrl.loading && ($ctrl.data.meta.page < $ctrl.data.meta.pages)" ng-click="$ctrl.next()">View more</button>' +
    '      <div class="loader loader--small" ng-if="$ctrl.loading"></div>' +
    "    </div>" +
    "  </div>" +
    "</div>",

  controller: function ($scope, $timeout, CategoryService, HistoryService) {
    var ctrl = this;
    var isSaleOrTrending = false;

    ctrl.attention = false;
    ctrl.attentionText =
      "End Of Year Sale - Save 40% on all products until the end of the month during checkout";

    ctrl.$onInit = function () {
      // sticky filters
      const $header = $(".header"),
        $trigger = $(".page__trigger"),
        $filters = $(".page__filters");

      $(window).scroll(function () {
        const posScroll = $(window).scrollTop();

        if (posScroll + $header.outerHeight() >= $trigger.offset().top) {
          $filters.addClass("scrolled");
        } else {
          $filters.removeClass("scrolled");
        }
      });

      // category
      if (
        ctrl.data.category.slug === "sale" ||
        ctrl.data.category.slug === "trending"
      ) {
        isSaleOrTrending = true;
      }

      document.title =
        (ctrl.data.lunacy ? "Lunacy" : ctrl.data.category.name) + " — UI8";

      ctrl.backgroundImage = "hero-home";
      switch (ctrl.data.category.slug) {
        case "coded-templates":
          ctrl.backgroundImage = "hero-coded-templates";
          break;
        case "wireframe-kits":
          ctrl.backgroundImage = "hero-wireframe-kits";
          break;
        case "illustrations":
          ctrl.backgroundImage = "hero-illustrations";
          break;
        case "fonts":
          ctrl.backgroundImage = "hero-fonts";
          break;
        case "presentation":
          ctrl.backgroundImage = "hero-presentation";
          break;
        case "mockups":
          ctrl.backgroundImage = "hero-mockups";
          break;
        case "3d-assets":
          ctrl.backgroundImage = "hero-3d-assets";
          break;
        case "icons":
          ctrl.backgroundImage = "hero-icons";
          break;
        case "themes":
          ctrl.backgroundImage = "hero-themes";
          break;
        case "freebies":
          ctrl.backgroundImage = "hero-freebies";
          break;
        default:
          ctrl.backgroundImage = "hero-home";
          break;
      }

      ctrl.showSort = !isSaleOrTrending;
      ctrl.priceFilter = ctrl.data.category.slug !== "freebies";

      var sort = getPageSort();
      ctrl.sort = sort ? sort : ctrl.data.current_sale ? "sale" : "recent";

      ctrl.softwareFilter = "Any Platform";
      ctrl.featured = ctrl.data.query.featured || false;
      ctrl.exclusive = ctrl.data.query.exclusive || false;
      ctrl.page = getPageNumber();
      ctrl.top = getTop();
      ctrl.categoryName = ctrl.data.lunacy ? "Lunacy" : ctrl.data.category.name;
      ctrl.subtitle = ctrl.data.lunacy
        ? `${ctrl.data.category.count} premium Lunacy resources for UX/UI projects`
        : ctrl.data.settings.category_subtitle[ctrl.data.category.slug]
        ? ctrl.data.settings.category_subtitle[ctrl.data.category.slug].replace(
            "[count]",
            ctrl.data.category.count
          )
        : ctrl.data.category.subtitle;
      ctrl.showAppFilter =
        ["fonts", "sketch", "lunacy", "figma"].indexOf(
          ctrl.data.category.slug
        ) < 0;
      ctrl.showTagFilter = true;
      // ctrl.showTagFilter =
      //   ["presentation", "freebies", "all"].indexOf(ctrl.data.category.slug) <
      //   0;
      ctrl.showApps = ctrl.data.compatibleApps;

      $(document).ready(function () {
        setTimeout(function () {
          window.scroll(0, ctrl.top);
        }, 0);
      });

      angular
        .element(document.querySelector(".page-loader-container"))
        .addClass("ng-hide");
      angular
        .element(document.querySelector(".body-container"))
        .removeClass("ng-hide");

      function isGoingToProduct(oldPath, oldUrl) {
        return oldPath.startsWith("/category") && !oldUrl.includes("?search=1");
      }

      HistoryService.rememberScrollPosition(
        isGoingToProduct,
        ctrl.data.category.slug
      );
    };

    ctrl.updateSort = function (sortBy) {
      setPageSort(sortBy);
      ctrl.sort = sortBy;
      return rebuild();
    };

    ctrl.updateSoftware = function (app) {
      if (ctrl.softwareFilter === app) return;
      ctrl.softwareFilter = app;
      return rebuild();
    };

    ctrl.updateTag = function (tagId) {
      if (isSaleOrTrending) {
        if (ctrl.secondaryCategory === tagId) return;
        ctrl.secondaryCategory = tagId;
      } else {
        if (ctrl.tagFilter === tagId) return;
        ctrl.tagFilter = tagId;
      }

      if (ctrl.top > 0 && ctrl.page > 1) {
        return rebuild(ctrl.page);
      }
      return rebuild();
    };

    ctrl.updateFeatured = function (featured) {
      if (ctrl.featured === featured) return;
      ctrl.featured = featured;
      $timeout(function () {
        return rebuild();
      }, 200);
    };

    ctrl.updateExclusive = function (exclusive) {
      if (ctrl.exclusive === exclusive) return;
      ctrl.exclusive = exclusive;
      $timeout(function () {
        return rebuild();
      }, 200);
    };

    ctrl.next = function () {
      ctrl.paginating = true;
      return getPage(ctrl.page + 1);
    };

    function rebuild(pages = 1) {
      ctrl.rebuilding = true;
      ctrl.loading = false;
      if (pages === 1) {
        return getPage(1);
      }
      return getPage(1, pages);
    }

    function getPage(page, pages = 0) {
      if (ctrl.loading) return;

      ctrl.loading = true;
      ctrl.page = page;

      var options = {
        sort: ctrl.sort,
        page: ctrl.page,
      };

      if (ctrl.sort === "salefirst") {
        options.sort = "recent";
        options.sale = true;
      }

      if (ctrl.featured) options.featured = !!ctrl.featured;
      if (ctrl.exclusive) options.exclusive = !!ctrl.exclusive;
      if (!/Any/.test(ctrl.softwareFilter))
        options.compatibility = ctrl.softwareFilter;
      if (ctrl.tagFilter) options.tag = ctrl.tagFilter;
      if (ctrl.secondaryCategory) options.category = ctrl.secondaryCategory;

      CategoryService.findBySlug(ctrl.data.category.slug, options)
        .then(function (response) {
          $scope.$evalAsync(function () {
            if (page === 1) {
              if (!response.products || !response.products.length) {
                var filterResetEvent = new Event("reset-category-filters");
                document.body.dispatchEvent(filterResetEvent);
              }
              ctrl.data.products = response.products;
            } else {
              ctrl.data.products = ctrl.data.products.concat(response.products);
            }

            ctrl.showApps = response.compatibleApps;
            ctrl.hasExclusives = response.hasExclusives;
            ctrl.hasFeatured = response.hasFeatured;

            ctrl.data.meta.page = response.meta.page;
            ctrl.data.meta.pages = response.meta.pages;

            setPageNumber(ctrl.page);

            ctrl.loading = false;
            ctrl.rebuilding = false;
            ctrl.paginating = false;

            if ($("body").scrollTop() > 225) {
              $("html, body").stop().animate({ scrollTop: 185 }, 500, "swing");
            }

            if (page < pages) {
              getPage(++page, pages);
            }
          });
        })
        .catch(function () {
          ctrl.loading = false;
          ctrl.rebuilding = false;
          ctrl.paginating = false;
        });
    }

    function getTop() {
      var top = sessionStorage.getItem("top:" + ctrl.data.category.slug) || 0;
      sessionStorage.removeItem("top:" + ctrl.data.category.slug);
      return parseInt(top, 10);
    }

    function setPageNumber(page) {
      sessionStorage.setItem("page:" + ctrl.data.category.slug, page);
    }

    function getPageNumber() {
      var page = sessionStorage.getItem("page:" + ctrl.data.category.slug) || 1;
      sessionStorage.removeItem("page:" + ctrl.data.category.slug);
      return +page;
    }

    function setPageSort(sortBy) {
      sessionStorage.setItem("sort:" + ctrl.data.category.slug, sortBy);
    }

    function getPageSort() {
      return sessionStorage.getItem("sort:" + ctrl.data.category.slug);
    }
  },

  bindings: {
    data: "<",
    user: "<",
  },
});
