angular.module("UI8.components").component("accountForgot", {
  template: `<div class="login" style="background-image: url(/img/login.jpg);">
      <div class="login__container container">
        <div class="login__inner">
          <a href="/" class="login__logo">
            <svg width="80" height="80">
              <rect width="80" height="80" fill="#181818" fill-rule="nonzero" rx="40"></rect>
              <path fill="#2d68ff" d="M57.5 36.9979c4.1421 0 7.5-3.3575 7.5-7.4991s-3.3579-7.499-7.5-7.499-7.5 3.3574-7.5 7.499 3.3579 7.4991 7.5 7.4991z"></path><path fill="#f8f8f8" d="M58 40.9528l-1 .0448c-6.0751 0-11-4.9242-11-10.9986 0-2.0074.5378-3.8891 1.4772-5.5091l-4.3235-2.3517c-1.9664-1.0697-4.341-1.0697-6.3074 0l-11.4 6.201C23.3222 29.4946 22 31.719 22 34.1369v11.7359c0 2.418 1.3222 4.6424 3.4463 5.7977l11.4 6.2011c1.9664 1.0696 4.341 1.0696 6.3074 0l11.4-6.2011C56.6778 50.5152 58 48.2908 58 45.8728v-4.92z"></path>
            </svg>
          </a>
          <h1 class="login__title">Reset your password</h1>
          <div class="login__form">
            <div class="field">
              <div class="field__label">Email</div>
              <input class="field__input" ng-class="{ error: $ctrl.forgotEmailError }" placeholder="designer@example.com" ng-keydown="$ctrl.forgotEmailError = false" type="email" name="email" ng-model="$ctrl.account.email" ng-pattern="/^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$/i" required>
            </div>
            <button class="btn btn--submit" ng-if="!$ctrl.loading" ng-click="$ctrl.submitForgot()">Reset</button>
            <div class="loader" ng-if="$ctrl.loading"></div>
          </div>
          <div class="login__hint">Have your password? <a href="/account/login">Log in</a></div>
        </div>
      </div>
      <div class="login__copyright">© 2024, Robot Global FZCO / UI8</div>
    </div>`,

  controller: function ($window, $location, $http, FlashService) {
    var ctrl = this;

    function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    function handleError(error) {
      FlashService.set("error", error);
    }

    function handleEnter(e) {
      if (e.key === "Enter") {
        ctrl.submitForgot();
      }
    }

    ctrl.$onInit = function () {
      ctrl.next = $location.path();
      ctrl.account = ctrl.account || {};

      $(document.body).on("keydown", handleEnter);
    };

    ctrl.$onDestroy = function () {
      $(document.body).off("keydown");
    };

    ctrl.submitForgot = function () {
      if (!ctrl.account.email || !validateEmail(ctrl.account.email))
        return (ctrl.forgotEmailError = true);

      ctrl.loading = true;

      $http
        .post("/account/forgot?json=1", {
          email: ctrl.account.email,
        })
        .then(
          function (response) {
            ctrl.loading = false;
            if (response.data.error) return handleError(response.data.error);
            FlashService.set("message", "Check your inbox!");
            // setTimeout(function () {
            //   $window.location.replace("/");
            // }, 2000);
          },
          function (error) {
            ctrl.loading = false;
            handleError(
              error.data.error ||
                error.error ||
                "Unable to login. Please try again."
            );
          }
        );
    };
  },
});
