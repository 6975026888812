angular.module("UI8.components").component("passPricing", {
  template:
    `<div class="pass">
      <div class="btn pass__starter" ng-if="$ctrl.userIsAdmin" ng-class="{active: $ctrl.currentPass($ctrl.starterPass._id), disabled: $ctrl.userHasLifetime || $ctrl.lowLevelPass($ctrl.starterPass._id)}" ng-click="$ctrl.addToCart($ctrl.starterPass._id)">
        Starter Pass - {{ $ctrl.starterPrice | ui8Currency }} - Paid monthly
      </div>
      <div class="btn pass__pro" ng-if="$ctrl.userIsAdmin" ng-class="{active: $ctrl.currentPass($ctrl.proPass._id), disabled: $ctrl.userHasLifetime || $ctrl.lowLevelPass($ctrl.proPass._id)}" ng-click="$ctrl.addToCart($ctrl.proPass._id)">
        Pro Pass - {{ $ctrl.proPrice | ui8Currency }} - Paid every 6 months
      </div>
    </div>
    <div class="pass pass--actual">
      <div class="pass__list">` +
    // basic
    `
        <div class="pass__item pass__item--first" ng-class="{active: $ctrl.currentPass($ctrl.basicPass._id), disabled: $ctrl.userHasLifetime || $ctrl.lowLevelPass($ctrl.basicPass._id)}" ng-click="$ctrl.addToCart($ctrl.basicPass._id)">
          <div class="pass__type">Basic</div>
          <div class="pass__price">
            {{ $ctrl.basicPrice | ui8Currency }}
            <span ng-if="$ctrl.renewalDiscount && $ctrl.basicDiscount !== 0">{{ $ctrl.basicRegularPrice | ui8Currency }}</span>
          </div>
          <div class="pass__labels">
            <div class="pass__label">Paid quarterly</div>
            <div class="pass__label" ng-if="$ctrl.renewalDiscount && $ctrl.basicDiscount !== 0">{{ $ctrl.basicDiscount }}% discount applied</div>
          </div>
          <ul class="pass__details">
            <li>{{ $ctrl.basicPass.daily_download_allowance }} downloads per day</li>
            <li>3-month access</li>
            <li>Access to all products</li>
            <li>15% off subscription renewal</li>
          </ul>
          <div class="pass__btn" ng-if="!$ctrl.currentPass($ctrl.basicPass._id)">Get Basic</div>
          <div class="pass__btn" ng-if="$ctrl.currentPass($ctrl.basicPass._id)">
            <svg><path d="M12 1.5a6 6 0 0 1 5.996 5.775L18 7.5l.001 1.71A7.97 7.97 0 0 1 20 14.5a8 8 0 1 1-16 0 7.97 7.97 0 0 1 2-5.291V7.5a6 6 0 0 1 6-6zm0 7a6 6 0 1 0 0 12 6 6 0 1 0 0-12zm0 4a1 1 0 0 1 .993.883L13 13.5v2a1 1 0 0 1-1.993.117L11 15.5v-2a1 1 0 0 1 1-1zm0-9a4 4 0 0 0-3.995 3.8L8 7.5v.07c1.177-.681 2.543-1.07 4-1.07s2.824.39 4.001 1.071L16 7.5a4 4 0 0 0-4-4z"/></svg>
            Current plan
          </div>
          <img class="aap-image aap-image--top-right" srcset="/img/aap/hero-bottom-left@2x.png" src="/img/aap/hero-bottom-left.png" alt="Hero Bottom Left">
        </div>` +
    // elite
    `
        <div class="pass__item pass__item--second" ng-class="{active: $ctrl.currentPass($ctrl.elitePass._id), disabled: $ctrl.userHasLifetime}" ng-click="$ctrl.addToCart($ctrl.elitePass._id)">
          <div class="pass__type">Elite</div>
          <div class="pass__price">
            {{ $ctrl.elitePrice | ui8Currency }}
            <span ng-if="$ctrl.renewalDiscount && $ctrl.eliteDiscount !== 0">{{ $ctrl.eliteRegularPrice | ui8Currency }}</span>
          </div>
          <div class="pass__labels">
            <div class="pass__label">Paid yearly</div>
            <div class="pass__label" ng-if="$ctrl.renewalDiscount && $ctrl.eliteDiscount !== 0">{{ $ctrl.eliteDiscount }}% discount applied</div>
          </div>
          <ul class="pass__details">
            <li>{{ $ctrl.elitePass.daily_download_allowance }} downloads per day</li>
            <li>1-year access</li>
            <li>Access to all products</li>
            <li>20% off subscription renewal</li>
          </ul>
          <div class="pass__btn" ng-if="!$ctrl.currentPass($ctrl.elitePass._id)">Get Elite</div>
          <div class="pass__btn" ng-if="$ctrl.currentPass($ctrl.elitePass._id)">
            <svg><path d="M12 1.5a6 6 0 0 1 5.996 5.775L18 7.5l.001 1.71A7.97 7.97 0 0 1 20 14.5a8 8 0 1 1-16 0 7.97 7.97 0 0 1 2-5.291V7.5a6 6 0 0 1 6-6zm0 7a6 6 0 1 0 0 12 6 6 0 1 0 0-12zm0 4a1 1 0 0 1 .993.883L13 13.5v2a1 1 0 0 1-1.993.117L11 15.5v-2a1 1 0 0 1 1-1zm0-9a4 4 0 0 0-3.995 3.8L8 7.5v.07c1.177-.681 2.543-1.07 4-1.07s2.824.39 4.001 1.071L16 7.5a4 4 0 0 0-4-4z"/></svg>
            Current plan
          </div>
          <img class="aap-image aap-image--top" srcset="/img/aap/hero-bottom-center@2x.png" src="/img/aap/hero-bottom-center.png" alt="Hero Bottom Center">
        </div>` +
    // lifetime
    `
        <div class="pass__item pass__item--third" ng-class="{active: $ctrl.userHasLifetime}" ng-click="$ctrl.addToCart($ctrl.lifetime._id, true)">
          <div class="pass__type">Lifetime</div>
          <div ng-if="$ctrl.promo" class="pass__price">
            {{ $ctrl.lifetimePrice | ui8Currency }}
            <span ng-if="$ctrl.renewalDiscount && $ctrl.lifetimeDiscount !== 0">{{ $ctrl.lifetimeRegularPrice | ui8Currency }}</span>
          </div>
          <div ng-if="!$ctrl.promo" class="pass__price">
            754
            <span>888</span>
          </div>
          <div ng-if="$ctrl.promo" class="pass__labels">
            <div class="pass__label">One-time purchase</div>
            <div class="pass__label" ng-if="$ctrl.renewalDiscount && $ctrl.lifetimeDiscount !== 0">{{ $ctrl.lifetimeDiscount }}% discount applied</div>
          </div>
          <div ng-if="!$ctrl.promo" class="pass__labels">
            <div class="pass__label">One-time purchase</div>
            <div class="pass__label">15% discount applied</div>
          </div>
          <ul class="pass__details">
            <li>{{ $ctrl.lifetime.daily_download_allowance }} downloads per day</li>
            <li>Lifetime access</li>
            <li>Access to all products</li>
            <li>One-time purchase</li>
          </ul>
          <div class="pass__btn" ng-if="!$ctrl.currentPass($ctrl.lifetime._id)">Get Lifetime</div>
          <div class="pass__btn" ng-if="$ctrl.currentPass($ctrl.lifetime._id)">
            <svg><path d="M12 1.5a6 6 0 0 1 5.996 5.775L18 7.5l.001 1.71A7.97 7.97 0 0 1 20 14.5a8 8 0 1 1-16 0 7.97 7.97 0 0 1 2-5.291V7.5a6 6 0 0 1 6-6zm0 7a6 6 0 1 0 0 12 6 6 0 1 0 0-12zm0 4a1 1 0 0 1 .993.883L13 13.5v2a1 1 0 0 1-1.993.117L11 15.5v-2a1 1 0 0 1 1-1zm0-9a4 4 0 0 0-3.995 3.8L8 7.5v.07c1.177-.681 2.543-1.07 4-1.07s2.824.39 4.001 1.071L16 7.5a4 4 0 0 0-4-4z"/></svg>
            Current plan
          </div>
          <img class="aap-image aap-image--top" srcset="/img/aap/hero-bottom-right@2x.png" src="/img/aap/hero-bottom-right.png" alt="Hero Bottom Right">
        </div>
      </div>
    </div > `,

  controller: function (CartService) {
    var ctrl = this;

    function basePrice(product, discount, isRenewal) {
      var price;
      if (product.seasonal_promo_enabled) {
        price = product.seasonal_promo_price;
      } else if (product.on_sale) {
        price = product.sale_price;
      } else {
        price = product.price;
      }

      if (discount && isRenewal) {
        // Elite pass has permanent 20% discount.
        // Renewal discount has to be applied together with permanent discount
        // Campaign promotion discount should be applied only if bigger than permanent one
        var discountedPrice = price - price * discount;
        if (discountedPrice < price) {
          price = discountedPrice;
        }
      }

      // console.log(Math.floor(price / 100) * 100);

      return Math.floor(price / 100) * 100;
    }

    function discountPercent(regularPrice, currentPrice) {
      return Math.floor(((regularPrice - currentPrice) / regularPrice) * 100);
    }

    function subscriptionTerm(sub) {
      return sub.subscription_months + " months";
    }

    ctrl.$onInit = function () {
      ctrl.userIsAdmin = window.UI8.userIsAdmin;
      ctrl.userHasLifetime = window.UI8.userHasLifetime;

      ctrl.subTerm = subscriptionTerm;
      ctrl.permanentDiscount = !ctrl.promo || (ctrl.promo && !ctrl.isRenewal);

      ctrl.starterPass = ctrl.subscriptions[0];
      ctrl.basicPass = ctrl.subscriptions[1];
      ctrl.proPass = ctrl.subscriptions[2];
      ctrl.elitePass = ctrl.subscriptions[3];

      ctrl.lifetimePass = ctrl.lifetime;

      calculatePrices();

      document.body.addEventListener("promo-updated", promoListener);
    };

    ctrl.$onDestroy = function () {
      document.body.removeEventListener("promo-updated", promoListener);
    };

    ctrl.currentPass = function (passId) {
      return (
        window.UI8.userActivePass && window.UI8.userActivePassId === passId
      );
    };

    ctrl.lowLevelPass = function (passId) {
      const currentIndex = ctrl.subscriptions.findIndex(
        (s) => s._id === passId
      );
      const activeIndex = ctrl.subscriptions.findIndex(
        (s) => s._id === window.UI8.userActivePassId
      );

      return window.UI8.userActivePass && currentIndex < activeIndex;
    };

    ctrl.addToCart = function (productId, isLifetime) {
      if (ctrl.userHasLifetime) {
        console.log("User has lifetime pass!");
        return;
      }

      if (
        !isLifetime &&
        (ctrl.currentPass(productId) || ctrl.lowLevelPass(productId))
      ) {
        console.log("User has current or low level pass!");
        return;
      }

      CartService.addPassToCart(productId, 1).then(function () {
        setTimeout(function () {
          window.scrollLock.disablePageScroll();

          var groupCart = $(".header__cell--cart"),
            groupNext = groupCart.find(".payup__sidebar .payup__btn"),
            groupLayout = groupCart.find(".payup__layout"),
            groupBack = groupCart.find(".payup__back");

          groupNext.on("click", function () {
            groupLayout.addClass("step-checkout");
          });

          groupBack.on("click", function () {
            groupLayout.removeClass("step-checkout");
          });
        }, 10);
      });

      if (ctrl.closeModal) $("body").removeClass("pass-pricing-modal");
    };

    function calculatePrices() {
      // console.log("pass pricing - calculate pricing");
      // console.log(`UI8.renewPass ${ window.UI8.renewPass } `);
      // console.log(ctrl.promo);
      // console.log(ctrl.isRenewal);
      ctrl.renewalDiscount = ctrl.promo ? ctrl.promo.discount / 100 : false;
      ctrl.renewalEliteDiscount = ctrl.promo ? 20 / 100 : false; // fixed discount 20% for Elite

      ctrl.starterPrice = basePrice(
        ctrl.starterPass,
        ctrl.renewalDiscount,
        false,
        ctrl.isRenewal
      );

      ctrl.basicPrice = basePrice(
        ctrl.basicPass,
        ctrl.renewalDiscount,
        true,
        ctrl.isRenewal
      );

      ctrl.proPrice = basePrice(
        ctrl.proPass,
        ctrl.renewalDiscount,
        true,
        ctrl.isRenewal
      );

      ctrl.elitePrice = basePrice(
        ctrl.elitePass,
        ctrl.renewalEliteDiscount,
        true,
        ctrl.isRenewal
      );

      ctrl.lifetimePrice = basePrice(
        ctrl.lifetimePass,
        ctrl.renewalDiscount,
        true,
        ctrl.isRenewal
      );

      // Elite pass has permanent 20% discount.
      // Renewal discount has to be applied together with permanent discount
      // Campaign promotion discount should be applied only if bigger than permanent one
      ctrl.basicRegularPrice = ctrl.basicPass.price;
      ctrl.proRegularPrice = ctrl.proPass.price;
      ctrl.eliteRegularPrice = ctrl.elitePass.price;

      ctrl.lifetimeRegularPrice = ctrl.lifetime.price;
      // ctrl.eliteRegularPrice = (ctrl.renewalDiscount && ctrl.isRenewal)
      //   ? ctrl.elitePass.sale_price
      //   : ctrl.elitePass.price;

      ctrl.basicDiscount = discountPercent(
        ctrl.basicRegularPrice,
        ctrl.basicPrice
      );

      ctrl.proDiscount = discountPercent(ctrl.proRegularPrice, ctrl.proPrice);

      ctrl.eliteDiscount = discountPercent(
        ctrl.eliteRegularPrice,
        ctrl.elitePrice
      );

      ctrl.lifetimeDiscount = discountPercent(
        ctrl.lifetimeRegularPrice,
        ctrl.lifetimePrice
      );
    }

    function promoListener() {
      ctrl.promo = UI8.promo;
      ctrl.isRenewal = UI8.isRenewal;
      // console.log("promo listener");
      // console.log(ctrl.promo);
      // console.log(ctrl.isRenewal);
      ctrl.permanentDiscount = ctrl.promo && !ctrl.isRenewal;
      calculatePrices();
    }
  },

  bindings: {
    subscriptions: "<",
    lifetime: "<",
    promo: "<?",
    closeModal: "<?",
  },
});
